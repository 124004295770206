import React, { useEffect, useState } from "react";
import CashTable from "./CashTable";
import InsuranceTable from "./InsuranceTable";
import { useLocation } from "react-router-dom";

function InvoiceMain() {
  const [activeTab, setActiveTab] = useState("cash");
  const location = useLocation();

  const tabList = [
    {
      label: "Cash Invoice",
      comapre: "cash",
      command: () => setActiveTab("cash"),
    },
    {
      label: "Insurance Invoice",
      comapre: "insurance",
      command: () => setActiveTab("insurance"),
    },
  ];

  useEffect(() => {
    if (location?.state?.type === "credit") {
      setActiveTab("insurance");
    } else {
      setActiveTab("cash");
    }
  }, [location]);
  return (
    <>
      <div>
        <div className="mb-2">
          <ul className="grid grid-flow-col text-center text-gray-500 bg-gray-100 rounded p-1 shadow-inner">
            {tabList.map((tab) => (
              <li key={tab.comapre}>
                <a
                  className={`flex justify-center py-2 cursor-pointer hover:text-primary ${
                    activeTab === tab?.comapre
                      ? "gradient-background rounded shadow text-white"
                      : ""
                  }`}
                  onClick={() => tab?.command()}
                >
                  {tab.label}
                </a>
              </li>
            ))}
          </ul>
        </div>
        <div>
          {activeTab === "cash" && <CashTable />}
          {activeTab === "insurance" && <InsuranceTable />}
        </div>
      </div>
    </>
  );
}

export default InvoiceMain;
