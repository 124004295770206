import { useState } from "react";
import ToggleSwitch from "../../../components/shared/ToggleSwitch";
import NewMedicineCategoryModal from "../../../components/shared/NewMedicineCategoryModal";

function MedicineCategories2() {
  const [categories, setCategories] = useState([
    {
      name: "Kamran Mehar",
    },
    {
      name: "Abdul Rehman",
    },
    {
      name: "Abdul Wahid",
    },
    {
      name: "Essa Mehar",
    },
    {
      name: "Amina",
    },
    {
      name: "Hina Aqeel",
    },
    {
      name: "Nouman",
    },
    {
      name: "Osama Zaheer",
    },
    {
      name: "Kamran Mehar",
    },
    {
      name: "Abdul Rehman",
    },
    {
      name: "Abdul Wahid",
    },
    {
      name: "Essa Mehar",
    },
    {
      name: "Amina",
    },
    {
      name: "Hina Aqeel",
    },
    {
      name: "Nouman",
    },
  ]);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  return (
    <div className="App">
      <div className="container py-2 mx-auto md:py-8">
        <div className="custom-table-container">
          <table className="custom-table">
            <thead id="table-head">
              <tr>
                <th>
                  <div className="text-left ml-12">CATEGORY NAME</div>
                </th>
                <th>IS ACTIVE</th>
                <th className="mr-2">ACTION</th>
              </tr>
            </thead>
            <tbody>
              {categories.map((category, index) => {
                return (
                  <tr
                    key={index}
                    className="text-center"
                    style={{ border: "2px solid black" }}
                  >
                    <td data-cell="name" className="text-left">
                      <div className="name-cell flex flex-col text-left items-start ml-12 ">
                        <div>{category.name}</div>
                      </div>
                    </td>
                    <td data-cell="status">
                      <label className="switch flex justify-center">
                        <ToggleSwitch />
                      </label>
                    </td>
                    <td data-cell="action">
                      <div className="actions">
                        <button onClick={openModal} className="edit-btn">
                          <img src={"/icons/edit.png"} alt="Edit" />
                        </button>
                        <button className="delete-btn">
                          <img
                            src={"/icons/delete.png"}
                            className="ms-1"
                            alt="Delete"
                          />
                        </button>
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
      {/* Modal Component */}
      <NewMedicineCategoryModal isOpen={isModalOpen} onClose={closeModal} />
    </div>
  );
}

export default MedicineCategories2;
