import { Bar, Doughnut } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
} from "chart.js";
import { FaVial, FaHourglassHalf, FaTint, FaUsers } from "react-icons/fa";

import PatientsLineChart from "./Charts/PatientsLineChart";
import LabAvgCostsBarChart from "./Charts/labBarChart";
import LabHorizontalBarChart from "./Charts/labHorizontalBarChart";
import LabTestsLineChart from "./Charts/LabTestLineChart";

const cardsData = [
  {
    title: "Total Tests Conducted",
    count: 1200, // Example count
    icon: <FaVial />, // You can choose a more relevant icon here
    color: "bg-blue-400", // Light Blue
    action: "View detailed test reports",
  },
  {
    title: "Pending Test Results",
    count: 75, // Example count
    icon: <FaHourglassHalf />, // Pending icon
    color: "bg-yellow-400", // Yellow or Orange
    action: "Review pending test results",
  },
  {
    title: "Blood Bank Inventory",
    count: "A+: 15, B-: 10", // Example count
    icon: <FaTint />, // Blood drop icon
    color: "bg-red-400", // Red
    action: "Manage blood stock and view details",
  },
  {
    title: "Active Donors",
    count: 500, // Example count
    icon: <FaUsers />, // People icon
    color: "bg-green-400", // Green
    action: "View donor details or register new donors",
  },
];

// Register required Chart.js components
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement
);

const LabSatisfaction = () => {
  const data = {
    labels: [
      "Sample Accuracy",
      "Testing Time",
      "Cost Effectiveness",
      "Patient Feedback",
    ],
    datasets: [
      {
        data: [30, 35, 25, 10], // Adjusted data for lab metrics
        backgroundColor: ["#6C5B7B", "#C06C84", "#F67280", "#F8B400"], // Updated color scheme
        hoverBackgroundColor: ["#6C5B7B", "#C06C84", "#F67280", "#F8B400"],
        borderWidth: 1,
      },
    ],
  };

  const options = {
    cutout: "70%", // Adjust the cutout size for the doughnut
    responsive: true,
    plugins: {
      tooltip: {
        callbacks: {
          label: function (tooltipItem) {
            return `${tooltipItem.label}: ${tooltipItem.raw}%`; // Display percentage values
          },
        },
      },
    },
  };

  return (
    <div className="bg-white p-4 rounded-lg shadow-md">
      <h3 className="text-xl font-semibold mb-4">Lab Test Satisfaction</h3>
      <div className="flex flex-row justify-between items-center">
        <div className="flex-1">
          <Doughnut data={data} options={options} />
        </div>
        <div className="flex flex-col justify-center ml-4">
          {cardsData.map((item, index) => (
            <div key={index} className="flex items-center mb-2">
              <div className={`p-2 rounded-full ${item.color} mr-2`}>
                {item.icon}
              </div>
              <div className="text-sm">
                {item.title}: {item.count}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

const BarChart = () => {
  const data = {
    labels: [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ],
    datasets: [
      {
        label: "Outpatients",
        backgroundColor: "rgba(75, 192, 192, 0.6)",
        borderColor: "rgba(75, 192, 192, 1)",
        borderWidth: 1,
        hoverBackgroundColor: "rgba(75, 192, 192, 0.8)",
        hoverBorderColor: "rgba(75, 192, 192, 1)",
        data: [650, 590, 800, 810, 560, 550, 400, 600, 650, 700, 800, 900],
      },
      {
        label: "Inpatients",
        backgroundColor: "rgba(153, 102, 255, 0.6)",
        borderColor: "rgba(153, 102, 255, 1)",
        borderWidth: 1,
        hoverBackgroundColor: "rgba(153, 102, 255, 0.8)",
        hoverBorderColor: "rgba(153, 102, 255, 1)",
        data: [280, 480, 400, 190, 860, 270, 900, 500, 650, 700, 750, 800],
      },
    ],
  };

  return (
    <div className="bg-white p-4 w-full rounded-lg shadow-md">
      <h3 className="text-xl font-semibold mb-4">Outpatients vs Inpatients</h3>
      <Bar data={data} />
    </div>
  );
};

const WaitingTime = () => {
  return <LabHorizontalBarChart />;
};

const TestsByDivision = () => {
  const divisions = [
    { name: "Hematology", completed: 400, pending: 90 },
    { name: "Biochemistry", completed: 350, pending: 120 },
    { name: "Microbiology", completed: 270, pending: 100 },
    { name: "Immunology", completed: 190, pending: 60 },
    { name: "Virology", completed: 220, pending: 70 },
  ];

  return (
    <div className="bg-white p-4 rounded-lg shadow-md">
      <h3 className="text-xl font-semibold mb-4">Tests by Division</h3>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
        {divisions.map((division) => (
          <div
            key={division.name}
            className="bg-gray-100 p-4 rounded-lg shadow hover:shadow-lg transition-shadow duration-300"
          >
            <h4 className="font-semibold text-lg">{division.name}</h4>
            <p className="text-gray-700">
              Completed Tests: <strong>{division.completed}</strong>
            </p>
            <p className="text-gray-700">
              Pending Tests: <strong>{division.pending}</strong>
            </p>
          </div>
        ))}
      </div>
    </div>
  );
};

const LabDashboard = () => {
  return (
    <div className="grid gap-8">
      {/* First Row */}
      <div className="grid grid-cols-1 md:grid-cols-4 gap-4">
        {cardsData.map((item, index) => (
          <div
            key={index}
            className={`${item.color} p-4 rounded-sm shadow-lg text-center`}
          >
            <div className="text-2xl font-semibold text-gray-900 mb-1 flex items-center justify-center">
              <span className="mr-2">{item.icon}</span>
              {item.title}
            </div>
            <div className="text-3xl font-bold text-gray-600">{item.count}</div>
          </div>
        ))}
      </div>

      {/* Second Row */}
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
        <div className="h-full">
          <LabTestsLineChart />
        </div>
        <div className="h-full">
          <LabSatisfaction />
        </div>
      </div>

      {/* Third Row */}
      <div>
        <LabAvgCostsBarChart />
      </div>

      {/* Fourth Row */}
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
        <div>
          <WaitingTime />
        </div>
        <div>
          <TestsByDivision />
        </div>
      </div>
    </div>
  );
};

export default LabDashboard;
