import React from "react";
import Modal from "react-modal";
import { Puff } from "react-loader-spinner";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    minWidth: "50%",
    border: "none",
    background: "none",
  },
};

const AppModal = ({ isOpen }) => {
  return (
    <Modal isOpen={isOpen} style={customStyles}>
      <div className="items-center justify-center flex">
        <div className="progress"></div>
      </div>
    </Modal>
  );
};

export default AppModal;
