import { useState } from "react";
import Table from "../../../components/shared/Table";
import DiagnosisCategoryModal from "../../../components/shared/DiagnosisCategoryModal";

const DiagnosisCategory2 = () => {
  const [categories, setCategories] = useState([
    { diagnosisCategory: "dd" },
    { diagnosisCategory: "test" },
    { diagnosisCategory: "Diagno" },
    { diagnosisCategory: "test dia" },
    { diagnosisCategory: "oi" },
    { diagnosisCategory: "lljo" },
    { diagnosisCategory: "test de palu" },
    { diagnosisCategory: "grosse" },
    { diagnosisCategory: "OPT" },
    { diagnosisCategory: "fever" },
    { diagnosisCategory: "OPT" },
    { diagnosisCategory: "test" },
    { diagnosisCategory: "Diagno" },
    { diagnosisCategory: "test dia" },
    { diagnosisCategory: "fever" },
  ]);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  const handleDelete = (index) => {
    setCategories(categories.filter((_, i) => i !== index));
  };

  // Define the columns for the table
  const columns = [
    {
      title: "DIAGNOSIS CATEGORY",
      dataKey: "diagnosisCategory",
    },
    {
      title: "ACTION",
      dataKey: "action",
      Cell: ({ row }) => (
        <div className="actions">
          <button onClick={openModal} className="edit-btn">
            <img src={"/icons/edit.png"} alt="Edit" />
          </button>
          <button
            className="delete-btn"
            onClick={() => handleDelete(row.index)}
          >
            <img src={"/icons/delete.png"} className="ms-3" alt="Delete" />
          </button>
        </div>
      ),
    },
  ];

  // Format the data for the table, adding index for action
  const formattedCategories = categories.map((item, index) => ({
    ...item,
    index, // Keep track of the index for delete functionality
    action: (
      <div className="actions">
        <button onClick={openModal} className="edit-btn">
          <img src={"/icons/edit.png"} alt="Edit" />
        </button>
        <button className="delete-btn" onClick={() => handleDelete(index)}>
          <img src={"/icons/delete.png"} className="ms-3" alt="Delete" />
        </button>
      </div>
    ),
  }));

  return (
    <div className="App">
      <div className="container py-2 mx-auto md:py-8">
        <div className="custom-table-container">
          <Table columns={columns} data={formattedCategories} />
        </div>
      </div>
      {/* Modal Component */}
      <DiagnosisCategoryModal isOpen={isModalOpen} onClose={closeModal} />
    </div>
  );
};

export default DiagnosisCategory2;
