import { useState } from "react";
import "../../../Style/style.css";

function PurchaseMedicine() {
  const [purchaseMedicines, setpurchaseMedicines] = useState([
    {
      purchaseNumber: "6YPARFIS",
      total: "Tk246.00",
      tax: "Tk0.00",
      paymentMode: "Cash",
      netAmount: "Tk246.00",
      paymentStatus: "Unpaid",
    },
    {
      purchaseNumber: "6YPARFIS",
      total: "Tk3,718.00",
      tax: "Tk0.00",
      paymentMode: "Cheque",
      netAmount: "Tk3,718.00",
      paymentStatus: "Unpaid",
    },
    {
      purchaseNumber: "6YPARFIS",
      total: "Tk277,877.00",
      tax: "Tk0.00",
      paymentMode: "Cash",
      netAmount: "Tk277,877.00",
      paymentStatus: "Paid",
    },
    {
      purchaseNumber: "6YPARFIS",
      total: "Tk2,330.00",
      tax: "Tk0.00",
      paymentMode: "Cash",
      netAmount: "Tk2,330.00",
      paymentStatus: "Unpaid",
    },
    {
      purchaseNumber: "6YPARFIS",
      total: "2,320.00",
      tax: "Tk0.00",
      paymentMode: "Cash",
      netAmount: "2,320.00",
      paymentStatus: "Paid",
    },
    {
      purchaseNumber: "6YPARFIS",
      total: "Tk1,499,995.00",
      tax: "Tk0.00",
      paymentMode: "Cash",
      netAmount: "Tk1,499,995.00",
      paymentStatus: "Paid",
    },
    {
      purchaseNumber: "6YPARFIS",
      total: "Tk12,688.00",
      tax: "Tk0.00",
      paymentMode: "Cheque",
      netAmount: "Tk12,688.00",
      paymentStatus: "Unpaid",
    },
    {
      purchaseNumber: "6YPARFIS",
      total: "Tk244.00",
      tax: "Tk0.00",
      paymentMode: "Cheque",
      netAmount: "Tk244.00",
      paymentStatus: "Unpaid",
    },
    {
      purchaseNumber: "6YPARFIS",
      total: "Tk500.00",
      tax: "Tk0.00",
      paymentMode: "Cheque",
      netAmount: "Tk500.00",
      paymentStatus: "Unpaid",
    },
    {
      purchaseNumber: "6YPARFIS",
      total: "Tk123.00",
      tax: "Tk0.00",
      paymentMode: "Cash",
      netAmount: "Tk123.00",
      paymentStatus: "Unpaid",
    },
    {
      purchaseNumber: "6YPARFIS",
      total: "Tk246.00",
      tax: "Tk0.00",
      paymentMode: "Cash",
      netAmount: "Tk246.00",
      paymentStatus: "Paid",
    },
    {
      purchaseNumber: "6YPARFIS",
      total: "Tk277,877.00",
      tax: "Tk0.00",
      paymentMode: "Cash",
      netAmount: "Tk277,877.00",
      paymentStatus: "Paid",
    },
    {
      purchaseNumber: "6YPARFIS",
      total: "Tk3,718.00",
      tax: "Tk0.00",
      paymentMode: "Cash",
      netAmount: "Tk3,718.00",
      paymentStatus: "Paid",
    },
    {
      purchaseNumber: "6YPARFIS",
      total: "Tk2,330.00",
      tax: "Tk0.00",
      paymentMode: "Cash",
      netAmount: "Tk2,330.00",
      paymentStatus: "Paid",
    },
    {
      purchaseNumber: "6YPARFIS",
      total: "Tk2,320.00",
      tax: "Tk0.00",
      paymentMode: "Cash",
      netAmount: "Tk2,320.00",
      paymentStatus: "Unpaid",
    },
    // Add more purchaseMedicine data as needed
  ]);

  return (
    <div className="App">
      <div className="container py-2 mx-auto md:py-8">
        <div className="custom-table-container">
          <table className="custom-table">
            <thead id="table-head">
              <tr>
                <th className="text-left pl-8">PURCHASE NUMBER</th>
                <th>TOTAL</th>
                <th>TAX</th>
                <th>PAYMENT MODE</th>
                <th>NET AMOUNT</th>
                <th>PAYMENT STATUS</th>
                <th>ACTION</th>
              </tr>
            </thead>
            <tbody>
              {purchaseMedicines.map((item, index) => {
                return (
                  <tr key={index} style={{ border: "2px solid black" }}>
                    <td className="" data-cell="purchase-number">
                      <div className="purchase-number">
                        <div>
                          <button className="table-button">
                            {item.purchaseNumber}
                          </button>
                        </div>
                      </div>
                    </td>
                    <td className="text-center" data-cell="total">
                      {item.total}
                    </td>
                    <td className="text-center" data-cell="tax">
                      {item.tax}
                    </td>
                    <td className="text-center" data-cell="payment-mode">
                      <button className="table-button">
                        {item.paymentMode}
                      </button>
                    </td>
                    <td className="text-center" data-cell="netAmount">
                      {item.netAmount}
                    </td>
                    <td className="text-center" data-cell="paymentStatus">
                      <button className="table-button">
                        {item.paymentStatus}
                      </button>
                    </td>
                    <td data-cell="action">
                      <div className="actions">
                        <button className="edit-btn">
                          <img src={"/icons/edit.png"} alt="Delete" />
                        </button>
                        <button className="delete-btn">
                          <img
                            src={"/icons/delete.png"}
                            className="ms-3"
                            alt="Delete"
                          />
                        </button>
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default PurchaseMedicine;
