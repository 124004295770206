import { Bar } from "react-chartjs-2";

const LabAvgCostsBarChart = () => {
  const data = {
    labels: [
      "Hematology",
      "Biochemistry",
      "Microbiology",
      "Immunology",
      "Virology",
      "Pathology",
      "Cytology",
      "Genetics",
      "Toxicology",
      "Parasitology",
    ],
    datasets: [
      {
        label: "Average Test Costs",
        data: [150, 220, 180, 200, 160, 240, 210, 190, 230, 175], // Costs for each lab division
        backgroundColor: [
          "#6C5B7B",
          "#C06C84",
          "#F67280",
          "#F8B400",
          "#355C7D",
          "#FF9F40",
          "#FF6384",
          "#36A2EB",
          "#FFCE56",
          "#4BC0C0",
        ],
        borderColor: [
          "#6C5B7B",
          "#C06C84",
          "#F67280",
          "#F8B400",
          "#355C7D",
          "#FF9F40",
          "#FF6384",
          "#36A2EB",
          "#FFCE56",
          "#4BC0C0",
        ],
        borderWidth: 1,
        barThickness: 12, // Thinner bars
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false, // Allows chart resizing
    scales: {
      y: {
        beginAtZero: true, // Y axis starts at 0
        ticks: {
          stepSize: 50, // Step size for the Y axis
        },
      },
    },
    plugins: {
      legend: {
        position: "top", // Legend position
      },
      tooltip: {
        enabled: true, // Enable tooltips for interactivity
      },
    },
    layout: {
      padding: {
        left: 10,
        right: 10,
        top: 20,
        bottom: 10,
      },
    },
  };

  return (
    <div
      className="bg-white p-4 rounded-lg shadow-md"
      style={{ height: "400px" }}
    >
      <h3 className="text-xl font-semibold ">Average Test Costs by Division</h3>
      <div style={{ height: "100%", width: "100%" }}>
        <Bar data={data} options={options} />
      </div>
    </div>
  );
};

export default LabAvgCostsBarChart;
