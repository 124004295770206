import { Button, Tooltip } from "@mui/material";
import React, { useEffect, useState } from "react";
import { FaPlus } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";
import {
  deleteInsurancePolicy,
  deletePayer,
  getPayersList,
} from "./components/ApiMethods";
import AppLoader from "../../components/AppLoader";

export default function HisPayers() {
  const [payersLIst, setPayersList] = useState(null);
  const [showLoader, setShowLoader] = useState(false);
  const tableHeaders = [
    { name: "Payer ID" },
    { name: "Name" },
    { name: "Type" },
    { name: "License ID" },
    { name: "Actions" },
  ];

  const navigate = useNavigate();
  useEffect(() => {
    getPayersList(setShowLoader, setPayersList);
  }, []);

  function handleEditClick(data) {
    navigate("/his/payers_form", {
      state: { updateData: data },
    });
  }

  function handleDelete(data) {
    const id = data?.payer_id;
    deletePayer(setShowLoader, id).then((res) => {
      if (res?.message === "Payer Deleted Successfully") {
        getPayersList(setShowLoader, setPayersList);
      }
    });
  }
  return (
    <div>
      <div className="physicians_page !bg-inherit">
        <div className="flex justify-between flex-col lg:flex-row gap-4 mb-3 px-3">
          <div className="">
            <Tooltip
              placement="right"
              title="Keep track of your insurance policies"
              className="my-auto !mt-2"
            >
              <span className="font-semibold text-black/70 text-lg">
                PAYERS
                <span className="italic text-lg ml-1">
                  ({payersLIst?.data?.length || 0}{" "}
                  <span className="text-xs font-bold italic">Entries</span>)
                </span>
              </span>
            </Tooltip>
          </div>

          <div className="flex gap-3 flex-col md:flex-row">
            <Button
              variant="contained"
              size="small"
              onClick={() => navigate("/his/payers_form")}
              style={{
                backgroundImage:
                  "linear-gradient(83deg, #f14f3e 0%, #fab768 100%)",
              }}
              startIcon={<FaPlus />}
            >
              Create Payer
            </Button>
          </div>
        </div>
      </div>
      <div className="super-git-table !p-0 !m-0">
        <table className="table-super-git !p-0">
          <thead className="!p-0">
            <tr className="!p-0">
              {tableHeaders.map((el, index) => (
                <th
                  className="!p-2 !text-sm !bg-[#373d45]"
                  key={"tableHeaders_" + index}
                >
                  {el.name}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {payersLIst?.data?.map((el, index) => (
              <tr key={"invoiceData_" + index} className="bg-white border-b">
                <td className="!p-1 !text-sm">
                  {el?.payer_id}
                </td>

                <td className="!p-1 !text-sm capitalize text-start">{el?.name}</td>
                <td className="!p-1 !text-sm">{el?.type}</td>
                <td className="!p-1 !text-sm">
                  {el?.license_id}
                </td>

                <td className="!p-1 flex flex-row items-center justify-center gap-1 text-sm text-gray-700">
                  <span
                    className="cursor-pointer text-blue-600"
                    onClick={() => handleEditClick(el)}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <path
                        d="M3 17.25V21h3.75L17.81 9.94l-3.75-3.75L3 17.25zM20.71 7.04c.39-.39.39-1.02 0-1.41l-2.34-2.34c-.39-.39-1.02-.39-1.41 0l-1.83 1.83 3.75 3.75 1.83-1.83z"
                        fill="#4D4D53"
                      />
                    </svg>
                  </span>

                  <span
                    className="cursor-pointer text-blue-600"
                    onClick={() => handleDelete(el)}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <path
                        d="M6 19C6 20.1 6.9 21 8 21H16C17.1 21 18 20.1 18 19V7H6V19ZM19 4H15.5L14.71 3.29C14.53 3.11 14.28 3 14 3H10C9.72 3 9.47 3.11 9.29 3.29L8.5 4H5V6H19V4Z"
                        fill="#4D4D53"
                      />
                    </svg>
                  </span>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <AppLoader setShowLoader={setShowLoader} showLoader={showLoader} />
    </div>
  );
}
