import React, { useState } from "react";
import {
  Container,
  Box,
  List,
  ListItem,
  ListItemText,
  Typography,
  Grid,
  Paper,
  Divider,
  IconButton,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DeleteIcon from "@mui/icons-material/Delete";

// Mock data for reports and sub-reports
const initialReports = [
  {
    id: 1,
    name: "Blood Bank Report",
    arabicName: "تقرير بنك الدم",
    details: [
      { subId: 1, name: "Result", value: "0" },
      { subId: 2, name: "Comments", value: "1" },
    ],
  },
  {
    id: 2,
    name: "Laboratory Report",
    arabicName: "تقرير المختبر",
    details: [
      { subId: 1, name: "Result", value: "-114.2" },
      { subId: 2, name: "Comments", value: "-114.3" },
    ],
  },
  {
    id: 3,
    name: "Osmolarity",
    arabicName: "التناضح",
    details: [{ subId: 1, name: "Result", value: "Normal" }],
  },
];

export default function LabForm() {
  const [reports, setReports] = useState(initialReports);
  const [selectedReport, setSelectedReport] = useState(null);
  const [selectedDetail, setSelectedDetail] = useState(null);
  const [openReportDialog, setOpenReportDialog] = useState(false);
  const [openDetailDialog, setOpenDetailDialog] = useState(false);
  const [newReport, setNewReport] = useState({
    name: "",
    arabicName: "",
    details: [],
  });
  const [detailData, setDetailData] = useState({
    subId: null,
    name: "",
    value: "",
  });

  const handleReportClick = (report) => {
    setSelectedReport(report);
    setSelectedDetail(null);
  };

  const handleDetailClick = (detail) => {
    setSelectedDetail(detail);
    setDetailData(detail); // Set the detail data for editing
    setOpenDetailDialog(true); // Open the detail dialog
  };

  const handleAddReport = () => {
    setOpenReportDialog(true);
  };

  const handleSaveReport = () => {
    const newId = reports.length + 1;
    const reportToAdd = { ...newReport, id: newId };
    setReports([...reports, reportToAdd]);
    setOpenReportDialog(false);
    setNewReport({ name: "", arabicName: "", details: [] });
  };

  const handleCancelAdd = () => {
    setOpenReportDialog(false);
    setNewReport({ name: "", arabicName: "", details: [] });
  };

  const handleSaveDetail = () => {
    if (detailData.subId) {
      // Update existing detail
      const updatedReports = reports.map((report) => {
        if (report.id === selectedReport.id) {
          return {
            ...report,
            details: report.details.map((d) =>
              d.subId === detailData.subId ? detailData : d
            ),
          };
        }
        return report;
      });
      setReports(updatedReports);
    } else {
      // Add new detail
      const newSubId = selectedReport.details.length + 1;
      const newDetail = { ...detailData, subId: newSubId };
      const updatedReports = reports.map((report) => {
        if (report.id === selectedReport.id) {
          return {
            ...report,
            details: [...report.details, newDetail],
          };
        }
        return report;
      });
      setReports(updatedReports);
    }

    setOpenDetailDialog(false);
    setDetailData({ subId: null, name: "", value: "" });
  };

  const handleCancelDetail = () => {
    setOpenDetailDialog(false);
    setDetailData({ subId: null, name: "", value: "" });
  };

  return (
    <Container maxWidth={100}>
      <Paper
        elevation={3}
        sx={{
          padding: 2,
          marginBottom: 2,
          backgroundColor: "#fff",
          height: "80vh",
          color: "#000",
        }}
      >
        <Box sx={{ display: "flex", height: "100%" }}>
          {/* Sidebar for report list */}
          <Box
            sx={{
              width: "250px",
              backgroundColor: "#333",
              padding: 1,
              borderRight: "1px solid #ddd",
              color: "#fff",
              overflowY: "auto",
              position: "relative",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                paddingRight: 2,
              }}
            >
              <Typography variant="h6" gutterBottom sx={{ color: "#fff" }}>
                Reports
              </Typography>

              <IconButton onClick={handleAddReport} sx={{ color: "#ff5722" }}>
                <AddCircleOutlineIcon fontSize="large" />
              </IconButton>
            </Box>
            <Divider sx={{ backgroundColor: "#ff5722" }} />
            <List>
              {reports.map((report) => (
                <ListItem
                  button
                  key={report.id}
                  onClick={() => handleReportClick(report)}
                  selected={selectedReport?.id === report.id}
                  sx={{
                    "&.Mui-selected": {
                      backgroundColor: "#ff5722",
                      color: "#fff",
                    },
                    "&.Mui-selected:hover": { backgroundColor: "#ff7043" },
                  }}
                >
                  <ListItemText
                    primary={report.name}
                    secondary={report.arabicName}
                    sx={{ color: "#fff" }}
                  />
                </ListItem>
              ))}
            </List>
          </Box>

          {/* Middle container for selected report details */}
          {selectedReport && (
            <Box
              sx={{
                width: "300px",
                padding: 2,
                backgroundColor: "#fff",
                borderRight: "1px solid #ddd",
                overflowY: "auto",
              }}
            >
              <Paper elevation={2} sx={{ padding: 2 }}>
                <Typography variant="h6">{selectedReport.name}</Typography>
                <Typography
                  variant="body2"
                  color="textSecondary"
                  sx={{ color: "#9e9e9e" }}
                >
                  {selectedReport.arabicName}
                </Typography>

                {/* Sub-report Details */}
                <List sx={{ marginTop: 2 }}>
                  {selectedReport.details.map((detail) => (
                    <ListItem
                      button
                      key={detail.subId}
                      onClick={() => handleDetailClick(detail)}
                      selected={selectedDetail?.subId === detail.subId}
                      sx={{
                        "&.Mui-selected": {
                          backgroundColor: "#ff5722",
                          color: "#fff",
                        },
                        "&.Mui-selected:hover": { backgroundColor: "#ff7043" },
                      }}
                    >
                      <ListItemText
                        primary={detail.name}
                        secondary={detail.value}
                      />
                      <IconButton
                        sx={{
                          color:
                            selectedDetail?.subId === detail.subId
                              ? "#ff5722"
                              : "#000",
                        }}
                        onClick={() => handleDetailClick(detail)} // Edit on click
                      >
                        <VisibilityIcon />
                      </IconButton>
                      <IconButton
                        sx={{
                          color:
                            selectedDetail?.subId === detail.subId
                              ? "#ff5722"
                              : "#000",
                        }}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </ListItem>
                  ))}
                </List>
              </Paper>
            </Box>
          )}

          {/* Right container for sub-report detailed view */}
          {selectedDetail && (
            <Box
              sx={{
                flex: 1,
                padding: 2,
                backgroundColor: "#fff",
                overflowY: "auto",
              }}
            >
              <Paper
                elevation={3}
                sx={{
                  padding: 2,
                  border: "2px solid #ff5722",
                  borderRadius: "10px",
                }}
              >
                <Typography variant="h6">Detail View</Typography>
                <Divider sx={{ marginY: 2 }} />

                {/* Grid for displaying sub-detail information */}
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={4}>
                    <Paper sx={{ padding: 2, boxShadow: 4 }}>
                      <Typography variant="body2">Detail ID:</Typography>
                      <Typography>{selectedDetail.subId}</Typography>
                    </Paper>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <Paper sx={{ padding: 2, boxShadow: 4 }}>
                      <Typography variant="body2">Detail Name:</Typography>
                      <Typography>{selectedDetail.name}</Typography>
                    </Paper>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <Paper sx={{ padding: 2, boxShadow: 4 }}>
                      <Typography variant="body2">Detail Value:</Typography>
                      <Typography>{selectedDetail.value}</Typography>
                    </Paper>
                  </Grid>
                </Grid>
              </Paper>
            </Box>
          )}
        </Box>
      </Paper>

      {/* Dialog for adding new report */}
      <Dialog open={openReportDialog} onClose={handleCancelAdd}>
        <DialogTitle>Add New Report</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Report Name"
            type="text"
            fullWidth
            variant="outlined"
            value={newReport.name}
            onChange={(e) =>
              setNewReport({ ...newReport, name: e.target.value })
            }
          />
          <TextField
            margin="dense"
            label="Arabic Name"
            type="text"
            fullWidth
            variant="outlined"
            value={newReport.arabicName}
            onChange={(e) =>
              setNewReport({ ...newReport, arabicName: e.target.value })
            }
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancelAdd}>Cancel</Button>
          <Button onClick={handleSaveReport}>Save</Button>
        </DialogActions>
      </Dialog>

      {/* Dialog for adding/editing detail */}
      <Dialog open={openDetailDialog} onClose={handleCancelDetail}>
        <DialogTitle>
          {detailData.subId ? "Edit Detail" : "Add Detail"}
        </DialogTitle>
        <DialogContent>
          <TextField
            margin="dense"
            label="Detail Name"
            type="text"
            fullWidth
            variant="outlined"
            value={detailData.name}
            onChange={(e) =>
              setDetailData({ ...detailData, name: e.target.value })
            }
          />
          <TextField
            margin="dense"
            label="Detail Value"
            type="text"
            fullWidth
            variant="outlined"
            value={detailData.value}
            onChange={(e) =>
              setDetailData({ ...detailData, value: e.target.value })
            }
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancelDetail}>Cancel</Button>
          <Button onClick={handleSaveDetail}>Save</Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
}
