import { useState } from "react";
// import "../../../Style/style.css";

const PathologyTests = () => {
    const [PathologyTests, setPathologyTests] = useState([
        {
            patient: "Roberto Frias Morales",
            email: "nooricare@yopmail.com",
            short_name: "CBC",
            test_name: "CBC",
            test_type: "CBC",
            category_name: "Assorted Meat",
            charge_category: "Lab",
        },
        {
            patient: "Denis Perez",
            email: "nooricare@yopmail.com",
            short_name: "BLOOD SUGAR",
            test_name: "BLOOD SUGAR",
            test_type: "BLOOD SUGAR",
            category_name: "Blood",
            charge_category: "Test",
        },
        {
            patient: "Roberto Frias Morales",
            email: "nooricare@yopmail.com",
            short_name: "URINE SUGAR",
            test_name: "URINE SUGAR",
            test_type: "URINE SUGAR",
            category_name: "Urine",
            charge_category: "Lab",
        },
        {
            patient: "Denis Perez",
            email: "nooricare@yopmail.com",
            short_name: "Glucose",
            test_name: "Glucose",
            test_type: "Glucose",
            category_name: "Blood",
            charge_category: "Test",
        },
        {
            patient: "Roberto Frias Morales",
            email: "nooricare@yopmail.com",
            short_name: "CBC",
            test_name: "CBC",
            test_type: "CBC",
            category_name: "Blood",
            charge_category: "Lab",
        },
        {
            patient: "Denis Perez",
            email: "nooricare@yopmail.com",
            short_name: "BLOOD SUGAR",
            test_name: "BLOOD SUGAR",
            test_type: "BLOOD SUGAR",
            category_name: "Blood",
            charge_category: "Test",
        },
        {
            patient: "Roberto Frias Morales",
            email: "nooricare@yopmail.com",
            short_name: "URINE TEST",
            test_name: "URINE TEST",
            test_type: "URINE TEST",
            category_name: "Urine",
            charge_category: "Lab",
        },
        {
            patient: "Denis Perez",
            email: "nooricare@yopmail.com",
            short_name: "Glucose",
            test_name: "Glucose",
            test_type: "Glucose",
            category_name: "Assorted Meat",
            charge_category: "Test",
        },
        {
            patient: "Roberto Frias Morales",
            email: "nooricare@yopmail.com",
            short_name: "CBC",
            test_name: "CBC",
            test_type: "CBC",
            category_name: "Blood",
            charge_category: "Lab",
        },
        {
            patient: "Denis Perez",
            email: "nooricare@yopmail.com",
            short_name: "BLOOD SUGAR",
            test_name: "BLOOD SUGAR",
            test_type: "BLOOD SUGAR",
            category_name: "Blood",
            charge_category: "Test",
        },
        {
            patient: "Roberto Frias Morales",
            email: "nooricare@yopmail.com",
            short_name: "URINE SUGAR",
            test_name: "URINE SUGAR",
            test_type: "URINE SUGAR",
            category_name: "Urine",
            charge_category: "Lab",
        },
        {
            patient: "Denis Perez",
            email: "nooricare@yopmail.com",
            short_name: "Glucose",
            test_name: "Glucose",
            test_type: "Glucose",
            category_name: "Assorted Meat",
            charge_category: "Test",
        },
        {
            patient: "Roberto Frias Morales",
            email: "nooricare@yopmail.com",
            short_name: "CBC",
            test_name: "CBC",
            test_type: "CBC",
            category_name: "Blood",
            charge_category: "Lab",
        },
        {
            patient: "Denis Perez",
            email: "nooricare@yopmail.com",
            short_name: "CBC",
            test_name: "CBC",
            test_type: "CBC",
            category_name: "Blood",
            charge_category: "Test",
        },
        {
            patient: "Roberto Frias Morales",
            email: "nooricare@yopmail.com",
            short_name: "CBC",
            test_name: "CBC",
            test_type: "CBC",
            category_name: "Blood",
            charge_category: "Lab",
        },
        
    ]);
    return (
        <div className="App">
            <div className="container py-2 mx-auto md:py-8">
                <div className="custom-table-container">
                    <table className="custom-table">
                        <thead id="table-head">
                            <tr>
                                <th> <div className="text-left item-start ml-5">PATIENT</div></th>
                                <th>SHORT NAME</th>
                                <th>TEST NAME</th>
                                <th>TEST TYPE</th>
                                <th>CATEGORY NAME</th>
                                <th>CHARGE CATEGORY</th>
                                <th>ACTION</th>
                            </tr>
                        </thead>
                        <tbody>
                            {PathologyTests.map((item, index) => {
                                return (
                                    <tr key={index} className="text-center" style={{ border: "2px solid black" }}>
                                        <td data-cell="patient">
                                            <div className="text-left items-start ml-5">
                                                <div>{item.patient}</div>
                                                <div className="email-text text-sm">{item.email}</div>
                                            </div>
                                        </td>
                                        <td data-cell="short_name">{item.short_name}</td>
                                        <td data-cell="test_name">{item.test_name}</td>
                                        <td data-cell="test_type">{item.test_type}</td>
                                        <td data-cell="category_name">{item.category_name}</td>
                                        <td data-cell="charge_category">{item.charge_category}</td>

                                        <td data-cell="action">
                                            <div className="actions">
                                                <button className="edit-btn">
                                                    {" "}
                                                    <img src={"/icons/edit.png"} alt="Delete" />
                                                </button>
                                                <button className="delete-btn">
                                                    {" "}
                                                    <img src={"/icons/delete.png"} className="ms-3" alt="Delete" />
                                                </button>
                                            </div>
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
}

export default PathologyTests
