import { useState } from "react";

function Medicines2() {
  const [medicines, setMedicines] = useState([
    {
      name: "Nuobrol",
      brand: "Wallace Roberts",
      quantity: 10,
      sellingPrice: "Tk546,456.45",
      buyingPrice: "Tk65,635.56",
    },
    {
      name: "DOLO",
      brand: "Wallace Roberts",
      quantity: 50,
      sellingPrice: "Tk4,554.54",
      buyingPrice: "Tk5,433.54",
    },
    {
      name: "DOLO 650 mg",
      brand: "Wallace Roberts",
      quantity: 40,
      sellingPrice: "Tk3,232.00",
      buyingPrice: "Tk33.00",
    },
    {
      name: "Panadol",
      brand: "Diamox",
      quantity: 665,
      sellingPrice: "Tk250.00",
      buyingPrice: "Tk150.00",
    },
    {
      name: "Panadol Extra",
      brand: "Diamox",
      quantity: 50,
      sellingPrice: "Tk377.00",
      buyingPrice: "Tk309.00",
    },
    {
      name: "Brophen",
      brand: "Diamox",
      quantity: 997,
      sellingPrice: "Tk2,000.00",
      buyingPrice: "Tk1,500.00",
    },
    {
      name: "Loprin",
      brand: "Abbort",
      quantity: 30,
      sellingPrice: "Tk200.00",
      buyingPrice: "Tk100.00",
    },
    {
      name: "Disprin",
      brand: "Diamox",
      quantity: 200,
      sellingPrice: "Tk5,555.00",
      buyingPrice: "Tk55.00",
    },
    {
      name: "Ansid",
      brand: "Abbort",
      quantity: 150,
      sellingPrice: "Tk11.00",
      buyingPrice: "Tk11.00",
    },
    {
      name: "Periton",
      brand: "Abbort",
      quantity: 500,
      sellingPrice: "Tk4.00",
      buyingPrice: "Tk4.00",
    },
    {
      name: "Relaxin",
      brand: "Abbort",
      quantity: 250,
      sellingPrice: "Tk546,456.45",
      buyingPrice: "Tk65,635.56",
    },
    {
      name: "Evion",
      brand: "Afrique",
      quantity: 40,
      sellingPrice: "Tk4,554.54",
      buyingPrice: "Tk5,433.54",
    },
    {
      name: "Augmenton",
      brand: "Afrique",
      quantity: 999,
      sellingPrice: "Tk3,232.00",
      buyingPrice: "Tk33.00",
    },
    {
      name: "Antial",
      brand: "Afrique",
      quantity: 100,
      sellingPrice: "Tk250.00",
      buyingPrice: "Tk150.00",
    },
    {
      name: "Ponston",
      brand: "Afrique",
      quantity: 10,
      sellingPrice: "Tk377.00",
      buyingPrice: "Tk309.00",
    }
  ]);

  return (
    <div className="App">
      <div className="container py-2 mx-auto md:py-8">
        <div className="custom-table-container">
          <table className="custom-table">
            <thead id="table-head">
              <tr>
                <th>
                  <div className="text-left ml-8">
                  MEDICINE
                  </div>
                  </th>
                <th>BRAND</th>
                <th>AVAILABLE QUANTITY</th>
                <th>SELLING PRICE</th>
                <th>BUYING PRICE</th>
                <th>ACTION</th>
              </tr>
            </thead>
            <tbody>
              {medicines.map((medicine, index) => {
                return (
                  <tr key={index} className="text-center" style={{ border: "2px solid black" }}>
                    <td data-cell="name" className="text-left">
                      <div className="text-left ml-8">
                        {medicine.name}
                      </div>
                    </td>
                    <td data-cell="brand">{medicine.brand}</td>
                    <td data-cell="quantity">{medicine.quantity}</td>
                    <td data-cell="sellingPrice">{medicine.sellingPrice}</td>
                    <td data-cell="buyingPrice">{medicine.buyingPrice}</td>
                    <td data-cell="action">
                      <div className="actions">
                        <button className="edit-btn">
                          <img src={"/icons/edit.png"} alt="Edit" />
                        </button>
                        <button className="delete-btn">
                          <img src={"/icons/delete.png"} className="ms-1" alt="Delete" />
                        </button>
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default Medicines2;
