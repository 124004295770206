import React, { useContext, useEffect, useState } from "react";
import { appContext } from "../context/ContextProvider";
import RootLayout from "../components/layouts/RootLayout/RootLayout";
import AuthFlow from "./AuthFlow";
import Router from "../router/Router"; // Make sure to import Router

function AppFlow() {
  const { user, setUser, token, setToken, setPermissions } =
    useContext(appContext);
  const [loading, setLoading] = useState(true);

  const checkToken = () => {
    let tokenValid = localStorage.getItem("token");
    let userPermissions = localStorage.getItem("permissions");
    let loggedInUser = localStorage.getItem("user");

    if (tokenValid) {
      setToken(JSON.parse(tokenValid));
      setPermissions(JSON.parse(userPermissions));
      setUser(JSON.parse(loggedInUser));
    }
    setLoading(false);
  };

  useEffect(() => {
    checkToken();
  }, []);

  if (loading) {
    return <p>Loading...</p>; // Optionally, add a loading indicator
  }

  return (
    <>
      {token ? (
        <RootLayout>
          <Router /> {/* Render all routes within RootLayout */}
        </RootLayout>
      ) : (
        <AuthFlow />
      )}
    </>
  );
}

export default AppFlow;
