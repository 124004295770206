import React, { useState } from "react";
import ToggleSwitch from "./ToggleSwitch";

const BloodIssuesModal = ({ isOpen, onClose }) => {
  const [role, setRole] = useState("");
  const [message, setMessage] = useState("");
  const [sendDirectly, setSendDirectly] = useState(false);

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
      <div className="p-8 max-w-5xl mx-auto">
        <form className="bg-white p-9 border-black shadow-md rounded-3xl space-y-4">
          <div>
            <h1 className="text-xl font-bold">New Blood Issue</h1>
          </div>
          <hr className="mb-6 border-black" />
          
          {/* Form fields based on the image */}
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-8">
            <div>
              <label className="text-gray-700 font-bold">Issue Date:</label>
              <input
                type="datetime-local"
                className="w-full px-3 py-2 border border-black focus:outline-none focus:ring focus:border-blue-300"
              />
            </div>
            <div>
              <label className="text-gray-700 font-bold">Doctor Name:</label>
              <select className="w-full px-3 py-2 border border-black focus:outline-none focus:ring focus:border-blue-300">
              <option value="" style={{ display: "none" }} >Select Doctor Name</option>
                        <option className="font-bold">mahmoud-ahmed</option>
                        <option>Roberto Frias Morales</option>
                        <option>Denise Perez</option>
                        <option>Vatsal Sakariya</option>
                        <option className = "font-bold" >mahmoud-ahmed</option>
                        <option>Roberto Frias Morales</option>
                        <option>Denise Perez</option>
              </select>
            </div>
            <div>
              <label className="text-gray-700 font-bold">Patient Name:</label>
              <select className="w-full px-3 py-2 border border-black focus:outline-none focus:ring focus:border-blue-300">
              <option value="" style={{ display: "none" }} >Select Patient Name</option>
                        <option className="font-bold">mahmoud-ahmed</option>
                        <option>Roberto Frias Morales</option>
                        <option>Denise Perez</option>
                        <option>Vatsal Sakariya</option>
                        <option className = "font-bold" >mahmoud-ahmed</option>
                        <option>Roberto Frias Morales</option>
                        <option>Denise Perez</option>
              </select>
            </div>
            <div>
              <label className="text-gray-700 font-bold">Donor Name:</label>
              <select className="w-full px-3 py-2 border border-black focus:outline-none focus:ring focus:border-blue-300">
              <option value="" style={{ display: "none" }} >Select Donor Name</option>
                        <option className="font-bold">mahmoud-ahmed</option>
                        <option>Roberto Frias Morales</option>
                        <option>Denise Perez</option>
                        <option>Vatsal Sakariya</option>
                        <option className = "font-bold" >mahmoud-ahmed</option>
                        <option>Roberto Frias Morales</option>
                        <option>Denise Perez</option>
              </select>
            </div>
            <div>
              <label className="text-gray-700 font-bold">Blood Group:</label>
              <select className="w-full px-3 py-2 border border-black focus:outline-none focus:ring focus:border-blue-300">
              <option value="" style={{ display: "none" }} >Select Blood Group</option>
                        <option>A+</option>
                        <option>AB+</option>
                        <option>AB-</option>
                        <option>A-</option>
                        <option>B+</option>
                        <option>B-</option>
                        <option>O-</option>
              </select>
            </div>
            <div>
              <label className="text-gray-700 font-bold">Amount:</label>
              <input
                type="number"
                placeholder="Amount"
                className="w-full px-3 py-2 border border-black focus:outline-none focus:ring focus:border-blue-300"
              />
            </div>
          </div>
          
          <div>
            <label className="text-gray-700 font-bold">Remarks:</label>
            <textarea
              rows="4"
              placeholder="Remarks"
              className="w-full px-3 py-2 border border-black focus:outline-none focus:ring focus:border-blue-300"
            />
          </div>

          {/* Save, Cancel, Back Buttons */}
          <div className="flex justify-end space-x-4">
            <button
              type="submit"
              className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded"
            >
              Save
            </button>
            <button
              type="button"
              onClick={onClose}
              className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded"
            >
              Cancel
            </button>
            <button
              type="button"
              onClick={onClose}
              className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded"
            >
              Back
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default BloodIssuesModal;
