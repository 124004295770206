import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const HorizontalBarChart = () => {
  const data = {
    labels: ["Dermatology", "Cardiology", "Oncology", "Surgery", "Gynecology"],
    datasets: [
      {
        label: "Avg. Waiting Time (minutes)",
        data: [30, 45, 40, 35, 25],
        backgroundColor: [
          "#FF6384", // Dermatology
          "#36A2EB", // Cardiology
          "#FFCE56", // Oncology
          "#4BC0C0", // Surgery
          "#9966FF", // Gynecology
        ],
        borderColor: ["#FF6384", "#36A2EB", "#FFCE56", "#4BC0C0", "#9966FF"],
        borderWidth: 1,
        barThickness: 20, // Adjust bar thickness
      },
    ],
  };

  const options = {
    indexAxis: "y", // Horizontal bar chart
    responsive: true,
    maintainAspectRatio: false, // Ensure chart fills container
    scales: {
      x: {
        beginAtZero: true, // Start the X-axis from zero
        ticks: {
          stepSize: 10, // Define intervals of waiting times
        },
      },
    },
    plugins: {
      legend: {
        display: false, // Hide the legend if not necessary
      },
      tooltip: {
        enabled: true, // Enable tooltips for better UX
      },
    },
    layout: {
      padding: {
        left: 10,
        right: 10,
        top: 10,
        bottom: 10,
      },
    },
  };

  return (
    <div className="h-full w-full">
      <Bar data={data} options={options} />
    </div>
  );
};

export default HorizontalBarChart;
