import React, { useState } from 'react'
import "../../../Style/style.css";

const Payroll = () => {
    const [Payroll, setPayroll] = useState([
        {
          payroll_id: <button className="table-button">6YPARFIS</button>,
          month: "January",
          year: "2024",
          basic_salary: "Tk2,000.00",
          allowance: "Tk100.00",
          deduction: "Tk10.00",
          net_salary : "Tk2,090.00",
          staus: <button className="table-button">Paid</button>
        },
        {
            payroll_id: <button className="table-button">6YPARFIS</button>,
            month: "Febraury",
            year: "2024",
            basic_salary: "Tk2,000.00",
            allowance: "Tk100.00",
            deduction: "Tk10.00",
            net_salary : "Tk2,090.00",
            staus: <button className="table-button">Paid</button>
        },
        {
            payroll_id: <button className="table-button">6YPARFIS</button>,
            month: "march",
            year: "2024",
            basic_salary: "Tk2,000.00",
            allowance: "Tk100.00",
            deduction: "Tk10.00",
            net_salary : "Tk2,090.00",
            staus: <button className="table-button">Paid</button>
        },
        {
            payroll_id: <button className="table-button">6YPARFIS</button>,
            month: "april",
            year: "2024",
            basic_salary: "Tk2,000.00",
            allowance: "Tk100.00",
            deduction: "Tk10.00",
            net_salary : "Tk2,090.00",
            staus: <button className="table-button">Paid</button>
        },
        {
            payroll_id: <button className="table-button">6YPARFIS</button>,
            month: "may",
            year: "2024",
            basic_salary: "Tk2,000.00",
            allowance: "Tk100.00",
            deduction: "Tk10.00",
            net_salary : "Tk2,090.00",
            staus: <button className="table-button">Paid</button>
        },
        {
            payroll_id: <button className="table-button">6YPARFIS</button>,
            month: "june",
            year: "2024",
            basic_salary: "Tk2,000.00",
            allowance: "Tk100.00",
            deduction: "Tk10.00",
            net_salary : "Tk2,090.00",
            staus: <button className="table-button">Paid</button>
        },
        {
            payroll_id: <button className="table-button">6YPARFIS</button>,
            month: "july",
            year: "2024",
            basic_salary: "Tk2,000.00",
            allowance: "Tk100.00",
            deduction: "Tk10.00",
            net_salary : "Tk2,090.00",
            staus: <button className="table-button">Paid</button>
        },
        {
            payroll_id: <button className="table-button">6YPARFIS</button>,
            month: "August",
            year: "2024",
            basic_salary: "Tk2,000.00",
            allowance: "Tk100.00",
            deduction: "Tk10.00",
            net_salary : "Tk2,090.00",
            staus: <button className="table-button">Paid</button>
        },
        {
            payroll_id: <button className="table-button">6YPARFIS</button>,
            month: "N/A",
            year: "2024",
            basic_salary: "Tk2,000.00",
            allowance: "Tk100.00",
            deduction: "Tk10.00",
            net_salary : "Tk2,090.00",
            staus: <button className="table-button">Paid</button>
        },
        {
            payroll_id: <button className="table-button">6YPARFIS</button>,
            month: "N/A",
            year: "N/A",
            basic_salary: "N/A",
            allowance: "22232",
            deduction: "22232",
            net_salary : "22232",
            staus: <button className="table-button">Paid</button>
        },
        {
            payroll_id: <button className="table-button">6YPARFIS</button>,
            month: "N/A",
            year: "N/A",
            basic_salary: "N/A",
            allowance: "42526",
            deduction: "42526",
            net_salary : "42526",
            staus: <button className="table-button">Paid</button>
        },
        {
            payroll_id: <button className="table-button">6YPARFIS</button>,
            month: "N/A",
            year: "N/A",
            basic_salary: "N/A",
            allowance: "27282",
            deduction: "27282",
            net_salary : "27282",
            staus: <button className="table-button">Paid</button>
        },
        {
            payroll_id: <button className="table-button">6YPARFIS</button>,
            month: "N/A",
            year: "N/A",
            basic_salary: "N/A",
            allowance: "93031",
            deduction: "93031",
            net_salary : "93031",
            staus: <button className="table-button">Paid</button>
        },
        {
            payroll_id: <button className="table-button">6YPARFIS</button>,
            month: "N/A",
            year: "N/A",
            basic_salary: "N/A",
            allowance: "32333",
            deduction: "32333",
            net_salary : "32333",
            staus: <button className="table-button">Paid</button>
        },
        {
            payroll_id: <button className="table-button">6YPARFIS</button>,
            month: "N/A",
            year: "N/A",
            basic_salary: "N/A",
            allowance: "32333",
            deduction: "32333",
            net_salary : "32333",
            staus: <button className="table-button">Paid</button>
        },

      ]);
  return (
    <div className="App">
    <div className="container py-2 mx-auto md:py-8">
      <div className="custom-table-container">
        <table className="custom-table">
          <thead id="table-head">
            <tr>
              <th><div className="text-left item-start ml-5">PAYROLL ID</div></th>
              <th>MONTH</th>
              <th>YEAR</th>
              <th>BASIC SALARY</th>
              <th>ALLOWANCE</th>
              <th>DEDUCTION</th>
              <th>NET SALARY</th>
              <th>STATUS</th>
            </tr>
          </thead>
          <tbody>
            {Payroll.map((item, index) => {
              return (
                <tr key={index} className="text-center" style={{ border: "2px solid black" }}>
                  <td data-cell="payroll_id">
                    <div className="text-left items-start ml-4">
                      <div>{item.payroll_id}</div>
                    </div>
                  </td>
                  <td data-cell="month">{item.month}</td>
                  <td data-cell="year">{item.year}</td>
                  <td data-cell="net_salary">{item.basic_salary}</td>
                  <td data-cell="allowance">{item.allowance}</td>
                  <td data-cell="deduction">{item.deduction}</td>
                  <td data-cell="net_salary">{item.net_salary}</td>
                  <td data-cell="status">{item.staus}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  </div>
  )
}

export default Payroll
