import { useState } from "react";
import BloodIssuesModal from "../../../components/shared/BloodIssuesModal";
import Table from "../../../components/shared/Table";

function BloodIssues() {
  const [BloodIssues, setBloodIssues] = useState([
    {
      patient: "Roberto Frias Morales",
      patient_email: "nooricare@yopmail.com",
      doctor: "Vatsal Sakariya",
      doctorEmail: "vatsal@gmail.com",
      donar_name: "Kamran Mehar",
      time: "12:19 PM",
      date: "24th August, 2024",
      blood_group: "A+",
      amount: "Tk45,245.45",
    },
    {
      patient: "Denise Perez",
      patient_email: "nooricare@yopmail.com",
      doctor: "Vatsal Sakariya",
      doctorEmail: "vatsal@gmail.com",
      donar_name: "Abdul Rehman",
      time: "12:19 PM",
      date: "24th August, 2024",
      blood_group: "AB+",
      amount: "Tk10,000.00",
    },
    {
      patient: "Roberto Frias Morales",
      patient_email: "nooricare@yopmail.com",
      doctor: "Vatsal Sakariya",
      doctorEmail: "vatsal@gmail.com",
      donar_name: "Abdul Wahid",
      time: "12:19 PM",
      date: "24th August, 2024",
      blood_group: "A+",
      amount: "Tk10,000.00",
    },
    {
      patient: "Denise Perez",
      patient_email: "nooricare@yopmail.com",
      doctor: "Vatsal Sakariya",
      doctorEmail: "vatsal@gmail.com",
      donar_name: "Essa Mehar",
      time: "12:19 PM",
      date: "24th August, 2024",
      blood_group: "B+",
      amount: "Tk45,245.45",
    },
    {
      patient: "Roberto Frias Morales",
      patient_email: "nooricare@yopmail.com",
      doctor: "Vatsal Sakariya",
      doctorEmail: "vatsal@gmail.com",
      donar_name: "Amina",
      time: "12:19 PM",
      date: "24th August, 2024",
      blood_group: "O+",
      amount: "Tk45,245.45",
    },
    {
      patient: "Denise Perez",
      patient_email: "nooricare@yopmail.com",
      doctor: "Vatsal Sakariya",
      doctorEmail: "vatsal@gmail.com",
      donar_name: "Hina Aqeel",
      time: "12:19 PM",
      date: "24th August, 2024",
      blood_group: "AB-",
      amount: "Tk45,245.45",
    },
    {
      patient: "Roberto Frias Morales",
      patient_email: "nooricare@yopmail.com",
      doctor: "Vatsal Sakariya",
      doctorEmail: "vatsal@gmail.com",
      donar_name: "Nouman",
      time: "12:19 PM",
      date: "24th August, 2024",
      blood_group: "A-",
      amount: "Tk45,245.45",
    },
    {
      patient: "Denise Perez",
      patient_email: "nooricare@yopmail.com",
      doctor: "Vatsal Sakariya",
      doctorEmail: "vatsal@gmail.com",
      donar_name: "Osama Zaheer",
      time: "12:19 PM",
      date: "24th August, 2024",
      blood_group: "AB-",
      amount: "Tk45,245.45",
    },
    {
      patient: "Roberto Frias Morales",
      patient_email: "nooricare@yopmail.com",
      doctor: "Vatsal Sakariya",
      doctorEmail: "vatsal@gmail.com",
      donar_name: "Kamran Mehar",
      time: "12:19 PM",
      date: "24th August, 2024",
      blood_group: "B-",
      amount: "Tk45,245.45",
    },
    {
      patient: "Denise Perez",
      patient_email: "nooricare@yopmail.com",
      doctor: "Vatsal Sakariya",
      doctorEmail: "vatsal@gmail.com",
      donar_name: "Abdul Rehman",
      time: "12:19 PM",
      date: "24th August, 2024",
      blood_group: "O-",
      amount: "Tk45,245.45",
    },
    {
      patient: "Roberto Frias Morales",
      patient_email: "nooricare@yopmail.com",
      doctor: "Vatsal Sakariya",
      doctorEmail: "vatsal@gmail.com",
      donar_name: "Abdul Wahid",
      time: "12:19 PM",
      date: "24th August, 2024",
      blood_group: "B-",
      amount: "Tk45,245.45",
    },
    {
      patient: "Denise Perez",
      patient_email: "nooricare@yopmail.com",
      doctor: "Vatsal Sakariya",
      doctorEmail: "vatsal@gmail.com",
      donar_name: "Essa Mehar",
      time: "12:19 PM",
      date: "24th August, 2024",
      blood_group: "AB+",
      amount: "Tk45,245.45",
    },
    {
      patient: "Roberto Frias Morales",
      patient_email: "nooricare@yopmail.com",
      doctor: "Vatsal Sakariya",
      doctorEmail: "vatsal@gmail.com",
      donar_name: "Amina",
      time: "12:19 PM",
      date: "24th August, 2024",
      blood_group: "AB-",
      amount: "Tk45,245.45",
    },
    {
      patient: "Denise Perez",
      patient_email: "nooricare@yopmail.com",
      doctor: "Vatsal Sakariya",
      doctorEmail: "vatsal@gmail.com",
      donar_name: "Hina Aqeel",
      time: "12:19 PM",
      date: "24th August, 2024",
      blood_group: "B+",
      amount: "Tk45,245.45",
    },
    {
      patient: "Roberto Frias Morales",
      patient_email: "nooricare@yopmail.com",
      doctor: "Vatsal Sakariya",
      doctorEmail: "vatsal@gmail.com",
      donar_name: "Nouman",
      time: "12:19 PM",
      date: "24th August, 2024",
      blood_group: "AB+",
      amount: "Tk45,245.45",
    },
  ]);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  // Define columns for the table
  const columns = [
    {
      title: "PATIENT",
      dataKey: "patient",
    },
    {
      title: "DOCTOR",
      dataKey: "doctor",
    },
    {
      title: "DONOR NAME",
      dataKey: "donar_name",
    },
    {
      title: "ISSUE DATE",
      dataKey: "time",
    },
    {
      title: "BLOOD GROUP",
      dataKey: "blood_group",
    },
    {
      title: "AMOUNT",
      dataKey: "amount",
    },
    {
      title: "ACTION",
      dataKey: "action",
    },
  ];

  return (
    <div className="App">
      <div className="container py-2 mx-auto md:py-8">
        <div className="custom-table-container">
          <Table
            columns={columns}
            data={BloodIssues.map((item) => ({
              ...item,
              patient: (
                <div className="flex flex-col text-left items-start">
                  <div>{item.patient}</div>
                  <div className="email-text text-sm">{item.patient_email}</div>
                </div>
              ),
              doctor: (
                <div className="flex flex-col text-left items-start">
                  <div>{item.doctor}</div>
                  <div className="email-text text-sm">{item.doctorEmail}</div>
                </div>
              ),
              // Applying table-button class for badge representation
              donar_name: (
                <span className="table-button">{item.donar_name}</span>
              ),
              // Applying table-button class for badge representation
              amount: <span className="table-button">{item.amount}</span>,
              time: (
                <div className="flex flex-col text-left items-center">
                  <div>{item.time}</div>
                  <div className="email-text text-sm">{item.date}</div>
                </div>
              ),
              action: (
                <div className="actions">
                  <button onClick={openModal} className="edit-btn">
                    <img src={"/icons/edit.png"} alt="Edit" />
                  </button>
                  <button className="delete-btn">
                    <img
                      src={"/icons/delete.png"}
                      className="ms-1"
                      alt="Delete"
                    />
                  </button>
                  <button className="print-btn">
                    <img src={"/icons/print.png"} alt="Print" />
                  </button>
                </div>
              ),
            }))}
          />
        </div>
      </div>
      {/* Modal Component */}
      <BloodIssuesModal isOpen={isModalOpen} onClose={closeModal} />
    </div>
  );
}

export default BloodIssues;
