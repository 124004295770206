import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { getPatientByID } from "../HIS/components/ApiMethods";
import AddBeneficiaryPersonalDataForm from "./components/AddBeneficiaryPersonalDataForm";
import PatientDetailForm from "./components/PatientDetailForm";
import AppModal from "../../components/AppModal";
import toast from "react-hot-toast";

function EditBeneficiaryForm(props) {
  const navigate = useNavigate();
  const [chiReqRecord, setChiReqRecord] = useState(null);
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const { patientData } = location.state || {};

    const handleGoBack = () => {
      navigate(-1);
    };

  return (
    <div className="superGit_container">
      <div className="benefeciary-create">
        {/* <div className="benefeciary-top">
          <div className="back" onClick={handleGoBack}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                d="M9 19L10.41 17.59L5.83 13H22V11H5.83L10.42 6.41L9 5L2 12L9 19Z"
                fill="#4D4D53"
              />
            </svg>
          </div>
          <div>
            <div className="benefeciary-title">
              <span>{patientData ? "Edit Beneficiary" : "Add Beneficiary"}</span>
            </div>
            <div className="benefeciary-content">
              <span>
                {patientData
                  ? "Edit beneficiary account details"
                  : "Add a new beneficiary account"}
              </span>
            </div>
          </div>
        </div> */}
        <PatientDetailForm setChiReqRecord={setChiReqRecord} item={patientData} />
        <AddBeneficiaryPersonalDataForm
          item={patientData}
          chiReqRecord={chiReqRecord}
          setChiReqRecord={setChiReqRecord}
        />
      </div>
      <AppModal isOpen={loading} />
    </div>
  );
}

export default EditBeneficiaryForm;
