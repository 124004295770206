import { gql } from "@apollo/client";

const GET_ALL_PATIENTS = gql`
  query listPatients($filters: PatientFiltersInput!) {
    listPatients(filters: $filters) {
      status
      message
      data {
        id
        first_name
        middle_name
        last_name
        family_name
        full_name
        file_no
        dob
        gender  
        document_type
        document_id
        nphies_id
        eHealth_id
        nationality
        residency_type
        contact_number
        martial_status
        blood_group
        preferred_language
        emergency_number
        email
        providers_id
        providers_name_en
        providers_branch_name_en
        providers_branch_id
        is_new_born
        street_line
        address_line
        city
        state
        country
        postal_code
      }
      pages
      total
    }
  }
`;

const CREATE_PATIENT = gql`
  mutation registerPatient($input: RegisterPatientInput!) {
    registerPatient(input: $input) {
      message
      status
      data {
        id
        first_name
        middle_name
        family_name
        full_name
        file_no
        dob
        eHealth_id
        nationality
        residency_type
        document_type
        contact_number
        martial_status
        gender
        blood_group
        preferred_language
        emergency_number
        email
        address_line
        is_new_born
        subscriber_id
        subscriber_relationship
        subscriber_insurance_plan
        insurance_plans {
          id
          member_card_id
          policy_number
          expiry_date
          is_primary
          relation_with_subscriber
          coverage_type
          patient_share
          max_limit
          network
          payer_id
          patient_id
        }
      }
    }
  }
`;

const UPDATE_BENEFICIARY = gql`
  mutation updatePatient($input: UpdatePatientInput!) {
    updatePatient(input: $input) {
      message
      status
      data {
        id
        first_name
        middle_name
        family_name
        full_name
        dob
        eHealth_id
        nationality
        residency_type
        document_type
        contact_number
        martial_status
        gender
        blood_group
        preferred_language
        emergency_number
        email
        address_line
      }
    }
  }
`;

const DEL_BENEFICIARY = gql`
  mutation deletePatient($input: DeletePatientInput!) {
    deletePatient(input: $input) {
      message
      status
      id
    }
  }
`;

const GET_SEARCHED_PATIENTS = gql`
  query searchPatients($q: String!) {
    searchPatients(search: $q) {
      status
      message
      data {
        id
        first_name
        document_id
        middle_name
        family_name
        full_name
        file_no
        dob
        eHealth_id
        nationality
        residency_type
        document_type
        contact_number
        martial_status
        gender
        blood_group
        preferred_language
        emergency_number
        email
        address_line
        insurance_plans {
          id
          member_card_id
          policy_number
          expiry_date
          is_primary
          payer_id
          relation_with_subscriber
          coverage_type
          patient_share
          max_limit
          patient_id
          payer {
            id

            license_id
            name_ar
            name_en
          }
        }
      }
    }
  }
`;

const GET_PATIENT_BY_ID = gql`
  query getPatient($id: String!) {
    getPatient(id: $id) {
      status
      message
      data {
        id
        first_name
        middle_name
        last_name
        family_name
        full_name
        file_no
        dob
        gender
        document_type
        document_id
        nphies_id
        eHealth_id
        nationality
        residency_type
        contact_number
        martial_status
        blood_group
        preferred_language
        emergency_number
        email
        providers_id
        is_new_born
        street_line
        address_line
        city
        state
        country
        postal_code
        subscriber_id
        subscriber_relationship
        subscriber_name
        insurance_plans {
          id
          member_card_id
          policy_number
          expiry_date
          issue_date
          is_primary
          relation_with_subscriber
          coverage_type
          patient_share
          max_limit
          patient_id
          payer_id
          network
          payer {
            id

            license_id
            name_ar
            name_en
          }
        }
      }
    }
  }
`;

const GET_PATIENTS_IN_EXCEL = gql`
  query listPatientsExcel($filters: PatientFiltersInput!) {
    listPatientsExcel(filters: $filters) {
      status
      message
      data
    }
  }
`;

export default {
  GET_ALL_PATIENTS,
  CREATE_PATIENT,
  UPDATE_BENEFICIARY,
  DEL_BENEFICIARY,
  GET_SEARCHED_PATIENTS,
  GET_PATIENT_BY_ID,
  GET_PATIENTS_IN_EXCEL,
};
