import React, { useEffect, useState } from "react";
import { Tooltip, Button } from "@mui/material";
import { FaPlus } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";
import { deleteInsurancePolicy, getInsurancePolicyList } from "./ApiMethods";
import { formatCustomDate } from "./CashTable";
import AppLoader from "../../../components/AppLoader";
import "../../Beneficiary/styles/BeneficiaryList.css";

export default function InsurancePolicyMain() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [policyList, setPolicyList] = useState(null);
  useEffect(() => {
    getInsurancePolicyList(setLoading, setPolicyList);
  }, []);

  const tableHeaders = [
    { name: "Payer" },
    { name: "Policy Class" },
    { name: "Policy Number" },
    { name: "Policy Holder" },
    { name: "Start Date" },
    { name: "End Date" },
    { name: "Actions" },
  ];

  function handleEditClick(data) {
    navigate("/receptionist/insurance_policy_form", {
      state: { updateData: data },
    });
  }

  function handleDelete(data) {
    const id = data?._id;
    deleteInsurancePolicy(setLoading, id).then((res) => {
      if (res?.message === "Insurance Policy Deleted successfully ") {
        getInsurancePolicyList(setLoading, setPolicyList);
      }
    });
  }
  return (
    <div className="physicians_container">
      <div className="physicians_page !bg-transparent">
        <div className="flex justify-between flex-col lg:flex-row gap-4 mb-3 px-3">
          <div className="">
            <Tooltip
              placement="right"
              title="Keep track of your insurance policies"
              className="my-auto !mt-2"
            >
              <span className="font-semibold text-black/70 text-lg">
                INSURANCE POLICIES
                <span className="italic text-lg ml-1">
                  ({policyList?.data?.length || 0}{" "}
                  <span className="text-xs font-bold italic">Entries</span>)
                </span>
              </span>
            </Tooltip>
          </div>

          <div className="flex gap-3 flex-col md:flex-row">
            <Button
              variant="contained"
              size="small"
              onClick={() => navigate("/receptionist/insurance_policy_form")}
              style={{
                backgroundImage:
                  "linear-gradient(83deg, #f14f3e 0%, #fab768 100%)",
              }}
              startIcon={<FaPlus />}
            >
              Create Policy
            </Button>
          </div>
        </div>
      </div>
   
        <div className="super-git-table !p-0 !m-0">
          <table class="table-super-git !p-0">
            <thead className="!p-0">
              <tr className="!p-0">
                {tableHeaders.map((el, index) => (
                  <th
                    className="!p-2 !text-sm !bg-[#373d45]"
                    key={"tableHeaders_" + index}
                  >
                    {el.name}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {policyList?.data?.map((el, index) => (
                <tr key={"invoiceData_" + index} className="bg-white border-b">
                  <td className="!p-1 !text-sm">
                    {el?.payer}
                  </td>

                  <td className="!p-1 !text-sm">
                    {el?.policy_class}
                  </td>
                  <td className="!p-1 !text-sm">
                    {el?.policy_number}
                  </td>
                  <td className="!p-1 !text-sm">
                    {el?.policy_holder_name}
                  </td>
                  <td className="!p-1 !text-sm">
                    {formatCustomDate(el?.start_date)}
                  </td>
                  <td className="!p-1 !text-sm">
                    {formatCustomDate(el?.end_date)}
                  </td>

                  <td className="px-4 py-2 flex flex-row items-center justify-center gap-1 text-sm text-gray-700">
                    <span
                      className="cursor-pointer text-blue-600"
                      onClick={() => handleEditClick(el)}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 24 24"
                        fill="none"
                      >
                        <path
                          d="M3 17.25V21h3.75L17.81 9.94l-3.75-3.75L3 17.25zM20.71 7.04c.39-.39.39-1.02 0-1.41l-2.34-2.34c-.39-.39-1.02-.39-1.41 0l-1.83 1.83 3.75 3.75 1.83-1.83z"
                          fill="#4D4D53"
                        />
                      </svg>
                    </span>

                    <span
                      className="cursor-pointer text-blue-600"
                      onClick={() => handleDelete(el)}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 24 24"
                        fill="none"
                      >
                        <path
                          d="M6 19C6 20.1 6.9 21 8 21H16C17.1 21 18 20.1 18 19V7H6V19ZM19 4H15.5L14.71 3.29C14.53 3.11 14.28 3 14 3H10C9.72 3 9.47 3.11 9.29 3.29L8.5 4H5V6H19V4Z"
                          fill="#4D4D53"
                        />
                      </svg>
                    </span>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <AppLoader setShowLoader={setLoading} showLoader={loading} />
      
    </div>
  );
}
