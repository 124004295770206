/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-key */
import { useState } from "react";
import "../../../Style/style.css";

function UsedMedicine2() {
  const [usedMedicines, setusedMedicines] = useState([
    {
      medicine: "Nuobrol",
      usedQuantity: "4",
      usedAt: "Medicine Bill",
      date: "21st Apr, 2024",
    },
    {
      medicine: "DOLO",
      usedQuantity: "1",
      usedAt: "Medicine Bill",
      date: "21st Apr, 2024",
    },
    {
      medicine: "DOLO 650 mg",
      usedQuantity: "4",
      usedAt: "Medicine Bill",
      date: "21st Apr, 2024",
    },
    {
      medicine: "Panadol",
      usedQuantity: "5",
      usedAt: "Medicine Bill",
      date: "21st Apr, 2024",
    },
    {
      medicine: "Panadol Extra",
      usedQuantity: "3",
      usedAt: "Medicine Bill",
      date: "21st Apr, 2024",
    },
    {
      medicine: "Brophen",
      usedQuantity: "1",
      usedAt: "Prescription",
      date: "21st Apr, 2024",
    },
    {
      medicine: "Loprin",
      usedQuantity: "45",
      usedAt: "Prescription",
      date: "21st Apr, 2024",
    },
    {
      medicine: "Disprin",
      usedQuantity: "50",
      usedAt: "Prescription",
      date: "21st Apr, 2024",
    },
    {
      medicine: "Ansid",
      usedQuantity: "6",
      usedAt: "Prescription",
      date: "21st Apr, 2024",
    },
    {
      medicine: "Periton",
      usedQuantity: "11",
      usedAt: "Prescription",
      date: "21st Apr, 2024",
    },
    {
      medicine: "Relaxin",
      usedQuantity: "4",
      usedAt: "Prescription",
      date: "21st Apr, 2024",
    },
    {
      medicine: "Evion",
      usedQuantity: "1",
      usedAt: "Medicine Bill",
      date: "21st Apr, 2024",
    },
    {
      medicine: "Augmenton",
      usedQuantity: "4",
      usedAt: "Medicine Bill",
      date: "21st Apr, 2024",
    },
    {
      medicine: "Antial",
      usedQuantity: "5",
      usedAt: "Medicine Bill",
      date: "21st Apr, 2024",
    },
    {
      medicine: "Ponston",
      usedQuantity: "3",
      usedAt: "Prescription",
      date: "21st Apr, 2024",
    },
  ]);

  return (
    <div className="App">
      <div className="container py-2 mx-auto md:py-8">
        <div className="custom-table-container">
          <table className="custom-table">
            <thead id="table-head">
              <tr>
                <th>
                  <div className="text-left ml-9">MEDICINE</div>
                </th>
                <th>USED QUANTITY</th>
                <th>USED AT</th>
                <th>DATE</th>
              </tr>
            </thead>
            <tbody>
              {usedMedicines.map((item, index) => {
                return (
                  <tr key={index} style={{ border: "2px solid black" }}>
                    <td data-cell="medicine">
                      <div className="ml-9">
                        <div>{item.medicine}</div>
                      </div>
                    </td>
                    <td className="text-center" data-cell="usedQuantity">
                      {item.usedQuantity}
                    </td>
                    <td className="text-center" data-cell="usedAt">
                      {item.usedAt}
                    </td>
                    <td className="text-center" data-cell="date">
                      <button className="table-button">{item.date}</button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default UsedMedicine2;
