import { useState } from "react";

function LiveMeetings() {
  const [LiveMeetingss, setLiveMeetingss] = useState([
    {
        consultationTitle : "N/A",
        status : "N/A",
        date : "N/A",
        createdBy : "N/A",
        password : "12345",
    },
    {
        consultationTitle : "N/A",
        status : "N/A",
        date : "N/A",
        createdBy : "N/A",
        password : "56789",
    },
    {
        consultationTitle : "N/A",
        status : "N/A",
        date : "N/A",
        createdBy : "N/A",
        password : "01234",
    },
    {
        consultationTitle : "N/A",
        status : "N/A",
        date : "N/A",
        createdBy : "N/A",
        password : "45678",
    },
    {
        consultationTitle : "N/A",
        status : "N/A",
        date : "N/A",
        createdBy : "N/A",
        password : "91011",
    },
    {
        consultationTitle : "N/A",
        status : "N/A",
        date : "N/A",
        createdBy : "N/A",
        password : "12131",
    },
    {
        consultationTitle : "N/A",
        status : "N/A",
        date : "N/A",
        createdBy : "N/A",
        password : "41516",
    },
    {
        consultationTitle : "N/A",
        status : "N/A",
        date : "N/A",
        createdBy : "N/A",
        password : "17181",
    },
    {
        consultationTitle : "N/A",
        status : "N/A",
        date : "N/A",
        createdBy : "N/A",
        password : "92021",
    },
    {
        consultationTitle : "N/A",
        status : "N/A",
        date : "N/A",
        createdBy : "N/A",
        password : "22232",
    },
    {
        consultationTitle : "N/A",
        status : "N/A",
        date : "N/A",
        createdBy : "N/A",
        password : "42526",
    },
    {
        consultationTitle : "N/A",
        status : "N/A",
        date : "N/A",
        createdBy : "N/A",
        password : "27282",
    },
    {
        consultationTitle : "N/A",
        status : "N/A",
        date : "N/A",
        createdBy : "N/A",
        password : "93031",
    },
    {
        consultationTitle : "N/A",
        status : "N/A",
        date : "N/A",
        createdBy : "N/A",
        password : "32333",
    },
    {
        consultationTitle : "N/A",
        status : "N/A",
        date : "N/A",
        createdBy : "N/A",
        password : "32333",
    },
  ]);

  return (
    <div className="App">
      <div className="container py-2 mx-auto md:py-8">
        <div className="custom-table-container">
          <table className="custom-table">
            <thead id="table-head">
              <tr>
                <th className="text-left">CONSULTATION TITLE</th>
                <th>STATUS</th>
                <th>DATE</th>
                <th>CREATED BY</th>
                <th>PASSWORD</th>
                <th>ACTION</th>
              </tr>
            </thead>
            <tbody>
              {LiveMeetingss.map((item, index) => {
                return (
                  <tr key={index} className="text-center" style={{ border: "2px solid black" }}>
                    <td data-cell="name">
                      <div className="name-cell">
                        <div>{item.consultationTitle}</div>
                      </div>
                    </td>
                    <td data-cell="phone">{item.status}</td>
                    <td data-cell="role">{item.date}</td>
                    <td data-cell="phone">{item.createdBy}</td>
                    <td data-cell="role">{item.password}</td>
                    <td data-cell="action">
                      <div className="actions">
                        <button className="edit-btn">
                          {" "}
                          <img src={"/icons/edit.png"} alt="Delete" />
                        </button>
                        <button className="delete-btn">
                          {" "}
                          <img src={"/icons/delete.png"} className="ms-3" alt="Delete" />
                        </button>
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default LiveMeetings;
