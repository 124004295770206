import React, { useState } from "react";
import {
  Formik,
  FieldArray,
  Field,
  useFormikContext,
  ErrorMessage,
} from "formik";
import { PlusIcon as PlusIcon } from "@heroicons/react/24/solid";
import { TrashIcon as TrashIconMini } from "@heroicons/react/20/solid";
import _ from "lodash";
import DiagnosisSearchNSelect from "./DiagnosisSearchNSelect";
import { Button, Tooltip } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";

function Diagnosis({ onChange, refId }) {
  const {
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    handleSubmit,
    isSubmitting,
    setFieldValue,
  } = useFormikContext();
  const addData = (values) => {
    let data = _.cloneDeep(values);
    // createInsurancePlan({ variables: { input: data } });
  };

  const tableHeaders = [
    {
      name: "Code - Description",
    },
    {
      name: "Type",
    },
    {
      name: "On Admission",
    },
    {
      name: "",
    },
  ];

  const diagnosisTypes = [
    { id: "principal", name: "Principal Diagnosis" },
    { id: "secondary", name: "Secondary Diagnosis" },
    { id: "admitting", name: "Admitting Diagnosis" },
    { id: "discharge", name: "Discharge Diagnosis" },
    { id: "differential", name: "Differential Diagnosis" },
  ];

  const formikRef = React.useRef();
  React.useImperativeHandle(refId, () => ({
    Submit: async () => {
      await formikRef.current.submitForm();
    },
  }));

  function watchForm(data) {
    if (onChange) {
      onChange({
        values: data,
      });
    }
  }

  return (
    <FieldArray name="diagnosisArr">
      {({ push, remove }) => {
        return (
          <>
            <div class="care_teams !p-3 !mb-3" id="careTeams2">
              <div class="flex_cont !mb-3">
                <div class="personal-title">
                  <div class="inventory-svg">
                    <svg
                      width="34"
                      height="34"
                      viewBox="0 0 34 34"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <rect width="34" height="34" rx="8" fill="#F4F6F8"></rect>
                      <path
                        d="M9.25 11H11.25V14H21.25V11H23.25V16H25.25V11C25.25 9.9 24.35 9 23.25 9H19.07C18.65 7.84 17.55 7 16.25 7C14.95 7 13.85 7.84 13.43 9H9.25C8.15 9 7.25 9.9 7.25 11V25C7.25 26.1 8.15 27 9.25 27H15.25V25H9.25V11ZM16.25 9C16.8 9 17.25 9.45 17.25 10C17.25 10.55 16.8 11 16.25 11C15.7 11 15.25 10.55 15.25 10C15.25 9.45 15.7 9 16.25 9Z"
                        fill="#F5793B"
                      ></path>
                      <path
                        d="M25.25 17.5L19.76 23L16.75 20L15.25 21.5L19.76 26L26.75 19L25.25 17.5Z"
                        fill="#F5793B"
                      ></path>
                    </svg>
                  </div>
                  <div class="personal-head">
                    <span>Diagnosis *</span>
                  </div>
                </div>
                {/* <div
                  class="add_new addNewBtn"
                  onClick={() =>
                    push({
                      id: values.diagnosisArr.length + 1,
                      codeDescription: "",
                      type: "",
                      on_admission: false,
                      codeId: "",
                    })
                  }
                >
                  <svg
                    width="38"
                    height="38"
                    viewBox="0 0 38 38"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle cx="19" cy="19" r="19" fill="#F5793B" />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M26 18H20V12C20 11.447 19.552 11 19 11C18.448 11 18 11.447 18 12V18H12C11.448 18 11 18.447 11 19C11 19.553 11.448 20 12 20H18V26C18 26.553 18.448 27 19 27C19.552 27 20 26.553 20 26V20H26C26.552 20 27 19.553 27 19C27 18.447 26.552 18 26 18Z"
                      fill="white"
                    />
                    <mask
                      id="mask0_90_11610"
                      style={{
                        WebkitMaskType: "luminance",
                        maskType: "luminance",
                      }}
                      maskUnits="userSpaceOnUse"
                      x="11"
                      y="11"
                      width="16"
                      height="16"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M26 18H20V12C20 11.447 19.552 11 19 11C18.448 11 18 11.447 18 12V18H12C11.448 18 11 18.447 11 19C11 19.553 11.448 20 12 20H18V26C18 26.553 18.448 27 19 27C19.552 27 20 26.553 20 26V20H26C26.552 20 27 19.553 27 19C27 18.447 26.552 18 26 18Z"
                        fill="white"
                      />
                    </mask>
                    <g mask="url(#mask0_90_11610)"></g>
                  </svg>
                  <span>Add New</span>
                </div> */}

                <div>
                  <Button
                    variant="contained"
                    size="small"
                    style={{
                      backgroundImage:
                        "linear-gradient(83deg, #f14f3e 0%, #fab768 100%)",
                    }}
                    onClick={() => {
                      const newIndex = values.diagnosisArr.length;
                      // Push new diagnosis with default values based on index
                      push({
                        id: newIndex + 1,
                        codeDescription: "",
                        type:
                          newIndex === 0
                            ? "principal"
                            : newIndex === 1
                            ? "secondary"
                            : newIndex === 2
                            ? "admitting"
                            : newIndex === 3
                            ? "discharge"
                            : newIndex === 4
                            ? "differential"
                            : "",
                        on_admission: false,
                        codeId: "",
                      });
                    }}
                    startIcon={<AddIcon />}
                  >
                    Add Diagnosis
                  </Button>
                </div>
              </div>
              <div class="pre_auth_communication_table">
                <div className="">
                  <div id="tableBody">
                    {values?.diagnosisArr?.map((item, index) => (
                      <div
                        key={`diagnosis-${index}`} // Updated key for uniqueness
                        className="personal-data !p-3 !mb-3 last:!mb-0"
                      >
                        <div className="personal-fields grid grid-cols-2 md:grid-cols-4 gap-2">
                          <div className="">
                            <label className="text-sm">Code-Description</label>
                            <Tooltip
                              title={
                                values.diagnosisArr[index]?.codeDescription
                                  ? `${values.diagnosisArr[index]?.codeDescription} - ${values.diagnosisArr[index]?.codeId}`
                                  : ""
                              }
                            >
                              <span>
                                (
                                {values.diagnosisArr[index]?.codeDescription
                                  ? `${values.diagnosisArr[
                                      index
                                    ]?.codeDescription.slice(0, 20)}...`
                                  : ""}
                                )
                              </span>
                            </Tooltip>
                            <DiagnosisSearchNSelect
                              setFieldValue={(val) => {
                                setFieldValue(
                                  `diagnosisArr.${index}.codeDescription`,
                                  val.AsciiDescription
                                );
                                setFieldValue(
                                  `diagnosisArr.${index}.codeId`,
                                  val.CodeId
                                );
                              }}
                            />
                            <ErrorMessage
                              name={`diagnosisArr.${index}.codeDescription`}
                              component="div"
                              className="text-red-500 text-xs"
                            />
                          </div>
                          <div className="flex items-end">
                            <div className="w-full">
                              <label className="text-sm">Type</label>
                              <Field
                                as="select"
                                name={`diagnosisArr.${index}.type`}
                                value={
                                  index === 0
                                    ? "principal"
                                    : index === 1
                                    ? "secondary"
                                    : index === 2
                                    ? "admitting"
                                    : index === 3
                                    ? "discharge"
                                    : index === 4
                                    ? "differential"
                                    : "" 
                                }
                              >
                                <option value="">
                                  {/* <p>Please Select</p> */}
                                </option>
                                {diagnosisTypes.map((item) => (
                                  <option key={item.id} value={item.id}>
                                    {item.name}
                                  </option>
                                ))}
                              </Field>
                              <ErrorMessage
                                name={`diagnosisArr.${index}.type`}
                                component="div"
                                className="text-red-500 text-xs"
                              />
                            </div>
                          </div>
                          <div className="">
                            <label className="text-sm">On Admission</label>
                            <Field
                              as="select"
                              name={`diagnosisArr.${index}.on_admission`}
                            >
                              <option value="">On Admission</option>
                              <option value={true}>Yes</option>
                              <option value={false}>No</option>
                            </Field>
                            <ErrorMessage
                              name={`diagnosisArr.${index}.on_admission`}
                              component="div"
                              className="text-red-500 text-xs"
                            />
                          </div>
                          <div className="flex justify-end items-end">
                            <div
                              className="remove-row"
                              onClick={() => remove(index)}
                            >
                              <svg
                                width="38"
                                height="38"
                                viewBox="0 0 38 38"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <circle cx="19" cy="19" r="19" fill="#DE5042" />
                                <path
                                  fill-rule="evenodd"
                                  clip-rule="evenodd"
                                  d="M26 20H12C11.448 20 11 19.553 11 19C11 18.447 11.448 18 12 18H26C26.553 18 27 18.447 27 19C27 19.553 26.553 20 26 20Z"
                                  fill="white"
                                />
                                <mask
                                  id="mask0_235_11306"
                                  style={{
                                    WebkitMaskType: "luminance",
                                    maskType: "luminance",
                                  }}
                                  maskUnits="userSpaceOnUse"
                                  x="11"
                                  y="18"
                                  width="16"
                                  height="2"
                                >
                                  <path
                                    fill-rule="evenodd"
                                    clip-rule="evenodd"
                                    d="M26 20H12C11.448 20 11 19.553 11 19C11 18.447 11.448 18 12 18H26C26.553 18 27 18.447 27 19C27 19.553 26.553 20 26 20Z"
                                    fill="white"
                                  />
                                </mask>
                                <g mask="url(#mask0_235_11306)"></g>
                              </svg>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>

                {/* <table>
                  <thead>
                    {tableHeaders.map((el, index) => {
                      return (
                        <th
                          className="!py-3 !pr-0 !pl-8"
                          key={"tableHeaders_" + index}
                        >
                          {el.name}
                        </th>
                      );
                    })}
                  </thead>
                  <tbody id="tableBody">
                    {values.diagnosisArr.map((item, index) => {
                      return (
                        <tr key={"addressform"}>
                          <td>
                            <label className="block text-sm font-medium text-gray-700">
                              {values.diagnosisArr[index]?.codeDescription
                                ? values.diagnosisArr[index]?.codeDescription +
                                  " - " +
                                  values.diagnosisArr[index]?.codeId
                                : ""}
                            </label>
                            <DiagnosisSearchNSelect
                              setFieldValue={(val) => {
                                setFieldValue(
                                  `diagnosisArr.${index}.codeDescription`,
                                  val.AsciiDescription
                                );
                                setFieldValue(
                                  `diagnosisArr.${index}.codeId`,
                                  val.CodeId
                                );
                              }}
                            />
                            <ErrorMessage
                              name={`diagnosisArr.${index}.codeDescription`}
                              component="div"
                              className="text-red-500 text-xs"
                            />
                          </td>
                          <td>
                            <Field
                              as="select"
                              name={`diagnosisArr.${index}.type`}
                            >
                              <option value="">
                                <p>Please Select</p>
                              </option>
                              {diagnosisTypes.map((item) => {
                                return (
                                  <option value={item.id}>{item.name}</option>
                                );
                              })}
                            </Field>
                            <ErrorMessage
                              name={`diagnosisArr.${index}.type`}
                              component="div"
                              className="text-red-500 text-xs"
                            />
                          </td>
                          <td>
                            <Field
                              as="select"
                              name={`diagnosisArr.${index}.on_admission`}
                            >
                              <option value="">Please Select</option>
                              <option value={true}>Yes</option>
                              <option value={false}>No</option>
                            </Field>

                            <ErrorMessage
                              name={`diagnosisArr.${index}.on_admission`}
                              component="div"
                              className="text-red-500 text-xs"
                            />
                          </td>
                          <td class="remove-row" onClick={() => remove(index)}>
                            <svg
                              width="38"
                              height="38"
                              viewBox="0 0 38 38"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <circle cx="19" cy="19" r="19" fill="#DE5042" />
                              <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M26 20H12C11.448 20 11 19.553 11 19C11 18.447 11.448 18 12 18H26C26.553 18 27 18.447 27 19C27 19.553 26.553 20 26 20Z"
                                fill="white"
                              />
                              <mask
                                id="mask0_235_11306"
                                style={{
                                  WebkitMaskType: "luminance",
                                  maskType: "luminance",
                                }}
                                maskUnits="userSpaceOnUse"
                                x="11"
                                y="18"
                                width="16"
                                height="2"
                              >
                                <path
                                  fill-rule="evenodd"
                                  clip-rule="evenodd"
                                  d="M26 20H12C11.448 20 11 19.553 11 19C11 18.447 11.448 18 12 18H26C26.553 18 27 18.447 27 19C27 19.553 26.553 20 26 20Z"
                                  fill="white"
                                />
                              </mask>
                              <g mask="url(#mask0_235_11306)"></g>
                            </svg>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table> */}
              </div>
              {errors.diagnosisArr &&
              touched.diagnosisArr &&
              typeof errors.diagnosisArr === "string" ? (
                <div className="max-w-2xl mt-2 text-sm leading-6">
                  <p className="text-red-500">{errors.diagnosisArr}</p>
                </div>
              ) : null}
            </div>
          </>
        );
      }}
    </FieldArray>
  );
}

export default Diagnosis;
