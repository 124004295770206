import React from "react";

const DiagnosisCategoryModal = ({ isOpen, onClose }) => {
    if (!isOpen) return null;

    return (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
            <div className="bg-white rounded-3xl shadow-lg p-9 w-full max-w-xs sm:max-w-sm">
                {/* Title */}
                <h2 className="text-lg font-bold mb-4">New Diagnosis Category</h2>
                <hr className="mb-6 border-gray-500" />

                {/* Diagnosis Category Field */}
                <div className="mb-6">
                    <label className="block text-gray-700 font-bold mb-2">Diagnosis Category:</label>
                    <input
                        type="text"
                        placeholder="Diagnosis Category"
                        className="w-full px-3 py-2 border border-black rounded focus:outline-none focus:ring focus:border-blue-300"
                    />
                </div>

                {/* Description Field */}
                <div className="mb-6">
                    <label className="block text-gray-700 font-bold mb-2">Description:</label>
                    <textarea
                        placeholder="Description"
                        rows="4"
                        className="w-full px-3 py-2 border border-black rounded focus:outline-none focus:ring focus:border-blue-300"
                    />
                </div>

                {/* Buttons */}
                <div className="flex justify-center space-x-4 mt-6">
                    <button
                        onClick={onClose}
                        className="px-6 py-2 bg-gray-300 rounded-full shadow hover:bg-gray-400 font-semibold"
                    >
                        Save
                    </button>
                    <button
                        onClick={onClose}
                        className="px-6 py-2 bg-gray-300 rounded-full shadow hover:bg-gray-400 font-semibold"
                    >
                        Cancel
                    </button>
                    <button
                        onClick={onClose}
                        className="px-6 py-2 bg-gray-300 rounded-full shadow hover:bg-gray-400 font-semibold"
                    >
                        Back
                    </button>
                </div>
            </div>
        </div>
    );
};

export default DiagnosisCategoryModal;
