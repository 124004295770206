import React from "react";

const BloodGroupModal = ({ isOpen, onClose }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
      <div className="bg-white rounded-lg shadow-lg p-9 w-1/3 max-w-xs sm:max-w-sm md:max-w-md lg:max-w-lg xl:max-w-2xl">
        <h2 className="text-xl font-semibold mb-4">New Blood Group</h2>
        <hr className="mb-6 border-black" />
        <div className="mb-7">
          <label className="block text-gray-700 font-bold mb-2">
            Blood Group:{" "}
          </label>
          <input
            type="text"
            placeholder="Blood Group"
            className="w-full px-3 py-2 border border-black rounded focus:outline-none focus:ring focus:border-blue-300"
          />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700 font-bold mb-2">
            Remained Bags:{" "}
          </label>
          <input
            type="text"
            placeholder="Remained Bags"
            className="w-full px-3 py-2 border border-black rounded focus:outline-none focus:ring focus:border-blue-300"
          />
        </div>
        <div className="flex justify-center space-x-4 mt-6">
          <button
            onClick={onClose}
            className="px-4 py-2 bg-gray-300 rounded shadow hover:bg-gray-400"
          >
            Save
          </button>
          <button
            onClick={onClose}
            className="px-4 py-2 bg-gray-300 rounded shadow hover:bg-gray-400"
          >
            Cancel
          </button>
          <button
            onClick={onClose}
            className="px-4 py-2 bg-gray-300 rounded shadow hover:bg-gray-400"
          >
            Back
          </button>
        </div>
      </div>
    </div>
  );
};

export default BloodGroupModal;
