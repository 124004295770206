import React, { useEffect, useState } from "react";
import { PencilIcon, PlusIcon as PlusIcon } from "@heroicons/react/24/solid";
import { TrashIcon as TrashIconMini } from "@heroicons/react/20/solid";
import { useQuery, gql, useMutation } from "@apollo/client";
import { useNavigate } from "react-router-dom";
// import { ToastContainer, toast } from "react-toastify";
import toast from "react-hot-toast";
import _ from "lodash";
import insurancePlans from "../../../gql/insurancePlans";
import ptpa from "../../../gql/ptpa";
import beneficiary from "../../../gql/beneficiary";
import AddBeneficiaryIplanModal from "./AddBeneficiaryIplanModal";
import ConformationModal from "../../../components/ConformationModal";
import { Button, IconButton } from "@mui/material";
import { Formik, FieldArray, Field, useFormik } from "formik";
import Modal from "react-modal";
import * as Yup from "yup";
import "../styles/BeneficiaryList.css";
import DeleteIcon from "@mui/icons-material/Delete";
import { getPayerList } from "../../HIS/components/ApiMethods";
// yyy
const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

function InsurancePlans({
  item,
  personalDetailValues,
  personalDetailsAreValid,
  patientId,
  setFieldValue,
  chiReqRecord,
  setIsFormReset,
  isFormReset,
  formik,
}) {
  const [reloadKey, setReloadKey] = useState(0);
  const [payers, setPayers] = useState([]);
  const [hisPayer, setHisPayer] = useState([]);
  const coverageTypes = [
    { id: "EHCPOL", name: "Extended Healthcare" },
    { id: "PUBLICPOL", name: "Public Healthcare" },
  ];

  const subscriberRelation = [
    { id: null, name: "Please Select" },
    { id: "child", name: "Child" },
    { id: "parent", name: "Parent" },
    { id: "spouse", name: "Spouse" },
    { id: "common", name: "Common" },
    { id: "other", name: "Other" },
    { id: "self", name: "Self" },
    { id: "injuired", name: "Injuired" },
  ];
  useEffect(() => {
    getPayerList(setHisPayer);
  }, []);

  function convertKeysToSnakeCase(obj) {
    if (typeof obj !== "object" || obj === null) {
      return obj;
    }

    if (Array.isArray(obj)) {
      return obj.map((item) => convertKeysToSnakeCase(item));
    }

    const snakeCaseObj = {};
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        const snakeCaseKey = key.replace(
          /[A-Z]/g,
          (match) => `_${match.toLowerCase()}`
        );
        snakeCaseObj[snakeCaseKey] = convertKeysToSnakeCase(obj[key]);
      }
    }

    return snakeCaseObj;
  }
  const addDataa = (values) => {
    setShowAddIplanModal(false);
    let data = values;
    if (item?.id) {
      let tempData = values;
      tempData["patientId"] = patientId;
      tempData["id"] = item?.id;
      updateInsurancePlan({
        variables: { input: convertKeysToSnakeCase(tempData) },
      });

      console.log("item?.id :: ", convertKeysToSnakeCase(tempData));
    } else if (item) {
      let tempData = [...personalDetailValues.insurance_plans];
      tempData[currentIndex] = data;
      setFieldValue("insurance_plans", convertKeysToSnakeCase(tempData));
      setInsurances(convertKeysToSnakeCase(tempData));

      console.log("item :: ", convertKeysToSnakeCase(tempData));
    } else {
      let tempData = [...personalDetailValues.insurance_plans];
      tempData.push(data);
      setFieldValue("insurance_plans", convertKeysToSnakeCase(tempData));
      setInsurances(convertKeysToSnakeCase(tempData));

      console.log("else :: ", convertKeysToSnakeCase(tempData));
    }
  };

  const getDesiredObject = (desiredValue) => {
    const result = _.find(hisPayer, {
      payer_id: desiredValue,
    });
    return result;
  };

  const getPayerId = (item) => {
    if (item?.payer) {
      return item?.payer?.id;
    } else if (item?.payer_id) {
      let payerObj = getDesiredObject(item?.payer_id);
      return payerObj?.payer_id;
    } else {
      return null;
    }
  };

  useEffect(() => {
    setReloadKey(reloadKey + 1);
  }, [item]);
  useEffect(() => {
    if (chiReqRecord) {
      const insuranceArray = chiReqRecord.Insurance.map(async (insurance) => {
        let payerId = "";

        if (hisPayer?.length > 0) {
          const payer = await hisPayer?.find((payer) => {
            const nameENWithoutAsterisk = payer?.name.replace(/\*/g, "");
            const nameARWithoutAsterisk = payer?.name_ar?.replace(/\*/g, "");
            return (
              _.includes(
                nameARWithoutAsterisk,
                insurance?.InsuranceCompanyAR
              ) ||
              _.includes(nameENWithoutAsterisk, insurance?.InsuranceCompanyEN)
            );
          });

          if (payer) {
            payerId = payer.payer_id || "";
          }
        }

        return {
          member_card_id: insurance.BeneficiaryNumber || "",
          policy_number: insurance.PolicyNumber || "",
          expiry_date: insurance.ExpiryDate || "",
          is_primary: true,
          payer_id: payerId,
 
//           payer_name: payers?.find(
//             (payerGroup) =>
//               payerGroup.payers.find((payer) => payer.id === payerId)?.name_en
//           ),
 
          payer_name: hisPayer?.data?.find((payer) => payer.id === payerId)
            ?.name,
 
          relation_with_subscriber: "self",
          coverage_type: "EHCPOL",
          patient_share: insurance.DeductibleRate || "0.00",
          max_limit: +insurance.MaxLimit || 0,
          network: insurance.NetworkID || "",
          issue_date: insurance.IssueDate || "",
          sponsor_no: insurance.SponsorNumber || "",
          policy_class: insurance.ClassName || "",
          policy_holder: insurance.PolicyHolder || "",
          insurance_type: "",
          insurance_status: "",
          insurance_duration: "",
        };
      });
      console.log(payers);

      // Wait for all promises to resolve
      Promise.all(insuranceArray).then((resolvedInsuranceArray) => {
        setFieldValue("insurance_plans", resolvedInsuranceArray);
      });
    }
  }, [chiReqRecord]);
  // getPayerId(item)

  const navigate = useNavigate();
  const [limit, setLimit] = useState(10);
  const [showDelAlert, setShowDelAlert] = useState(false);
  const [pageNo, setPageNo] = useState(1);
  const [iPlanData, setIPlanData] = useState(1);
  const [currentInsurance, setCurrentInsurance] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(-1);
  const [showAddIplanModal, setShowAddIplanModal] = useState(false);
  const [insurances, setInsurances] = useState([]);
  const [createInsurancePlan, { data, loading, error }] = useMutation(
    insurancePlans.CREATE_INSURANCE_PLAN,
    {
      update(proxy, result) {
        onSuccessfulRequestResponse(result.data);
      },
      onError(error) {
        onResponseError(error.graphQLErrors[0]);
      },
    }
  );

  useEffect(() => {
    if (personalDetailValues.insurance_plans) {
      setInsurances(personalDetailValues.insurance_plans);
    }
  }, [personalDetailValues.insurance_plans]);

  const [
    updateInsurancePlan,
    {
      data: updateIplanData,
      loading: updateIplanLoading,
      error: updateIplanError,
    },
  ] = useMutation(insurancePlans.UPDATE_INSURANCE_PLAN, {
    update(proxy, result) {
      onSuccessfulRequestResponse(result.data);
    },
    onError(error) {
      onResponseError(error.graphQLErrors[0]);
    },
  });

  const onSuccessfulRequestResponse = (data) => {
    if (data.insurancePlansRegistration) {
      toast.success("Successfully Added Insurance Plans!");

      let arr = [...insurances];
      arr.push(data?.insurancePlansRegistration?.Data[0]);
      setInsurances(arr);
      setFieldValue("insurancePlans", arr);
    } else if (data.insurancePlanUpdate) {
      toast.success("Successfully Updated Insurance Plans!");
      console.log(
        "data?.insurancePlanUpdate?.Data[0] :: ",
        data?.insurancePlanUpdate?.Data
      );
      let arr = [...insurances];
      arr[currentIndex] = data?.insurancePlanUpdate?.Data[0];
      setInsurances(arr);
      setFieldValue("insurancePlans", arr);
    } else if (data.deleteInsurancePlan) {
      toast.success("Successfully Delete Insurance Plan!");
      // deleteFromLocalState();
    } else {
    }
  };

  const onResponseError = (error) => {
    toast.error(`${error}`);
  };

  const onSuccessfulCreatePatientRequestResponse = (data) => {
    if (data.patientRegistration) {
      toast.success("Successfully Added!");
      if (!personalDetailValues.is_new_born) {
        let tempData = iPlanData;
        tempData["patientId"] = data.patientRegistration.Data._id;
        console.log("temp", tempData);
        createInsurancePlan({ variables: { input: tempData } });
      } else {
        navigate(-1);
      }
    } else {
    }
  };

  const onResponseCreatePatientError = (error) => {
    toast.error(`${error.message}`);
  };

  const [
    createPatient,
    { data: patientData, loading: patientLoading, error: patientError },
  ] = useMutation(beneficiary.CREATE_PATIENT, {
    update(proxy, result) {
      onSuccessfulCreatePatientRequestResponse(result.data);
    },
    onError(error) {
      onResponseCreatePatientError(error.graphQLErrors[0]);
    },
  });

  const [editBeneficiary, { updatedData, updatedLoading, updatedError }] =
    useMutation(beneficiary.UPDATE_BENEFICIARY, {
      update(proxy, result) {
        onSuccessfulEditResponse(result.data);
      },
      onError(error) {
        onResponseError(error.graphQLErrors[0]);
      },
    });

  const onSuccessfulEditResponse = (data) => {
    if (data.updatePatient) {
      toast.success("Successfully updated! ");
      if (!personalDetailValues.is_new_born) {
        let tempData = {
          memberCardId: iPlanData.memberCardId,
          policyNumber: iPlanData.policyNumber,
          expiryDate: iPlanData.expiryDate,
          maxLimit: iPlanData.maxLimit,
          payerNphiesId: iPlanData.payerNphiesId,
        };

        tempData["patientId"] = data.updatePatient.Data._id;
        tempData["_id"] = item?.plansData?.length && item.plansData[0]._id;

        updateInsurancePlan({ variables: { input: tempData } });
      } else {
        navigate(-1);
      }
    } else {
    }
  };

  // const {
  //   loading: payersLoading,
  //   error: payersError,
  //   data: payersData,
  //   refetch,
  // } = useQuery(ptpa.GET_ALL_PAYERS, {
  //   variables: { limit: 100, page: 1 },
  // });

  const getPayerName = (payerId) => {
    if (payerId && payers?.length > 0) {
      for (const payer of payers) {
        const result = _.find(payer.payers, { id: payerId });

        if (result) {
          return result.name_en;
        }
      }
    }

    return "";
  };

  function findPayerById(payerId) {
    if (payerId && hisPayer?.length > 0) {
      for (const index of hisPayer?.data) {
        if (hisPayer?.data[index]?.payer_id === payerId) {
          return hisPayer?.data[index];
        }
      }
    }
    return null;
  }

  const [deletingIndex, setDeletingIndex] = useState(null);

  const handleOpenDelAlert = (index) => {
    setShowDelAlert(true);
    setDeletingIndex(index);
  };

  const onClickDelete = () => {
    if (deletingIndex !== null && deletingIndex !== undefined) {
      const updatedInsurancePlansa = [...formik?.values?.insurance_plans];
      updatedInsurancePlansa.splice(deletingIndex, 1);
      formik?.setFieldValue("insurance_plans", updatedInsurancePlansa);
    }
    setShowDelAlert(false);
    setDeletingIndex(null);
  };
  React.useEffect(() => {
    const payersData = JSON.parse(localStorage.getItem("payersData"));

    if (payersData && payersData.length > 0) {
      setPayers(payersData);
    } else {
      navigate("/dashboard");
    }
  }, []);

  return (
    <>
      <div>
        <div className="">
          {formik?.values?.insurance_plans?.map((el, index) => (
            <div
              key={index}
              className="insurance-plans !mb-2 !mt-3 !p-2 insurance-plann"
            >
              <div className="search-fields grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 lg:grid-cols-6 gap-2">
                {/* Select Payer */}
                <div className="col-span-2">
                  <label className="text-sm">Payer*</label>
                  <select
                    id="select-payer"
                    name={`insurance_plans[${index}].payer_id`}
                    onChange={formik?.handleChange}
                    value={formik?.values.insurance_plans?.[index]?.payer_id}
                    autoComplete="type-name"
                    className="block w-full rounded-md border-gray-300  focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                  >
                    <option value={null}>Select Payer</option>
                    {hisPayer?.data?.map((category) => (
                      <option key={index} value={category.payer_id}>
                        {category?.name} ({category?.license_id})
                      </option>
                    ))}
                  </select>
                  {formik?.errors.insurance_plans?.length > 0 &&
                    formik?.errors.insurance_plans[index]?.payer_id && (
                      <p className="text-sm text-red-500">
                        {formik?.errors.insurance_plans[index]?.payer_id}
                      </p>
                    )}
                </div>

                {/* Issue Date Input */}
                <div>
                  <label className="text-sm">Issue Date*</label>
                  <input
                    type="text"
                    name={`insurance_plans[${index}].issue_date`}
                    onChange={formik?.handleChange}
                    value={formik?.values.insurance_plans?.[index]?.issue_date}
                    id="issue_date"
                    placeholder="Issue Date"
                    onFocus={(e) => (e.target.type = "date")}
                    onBlur={(e) => (e.target.type = "text")}
                  />
                  {formik?.errors.insurance_plans?.length > 0 &&
                    formik?.errors.insurance_plans[index]?.issue_date && (
                      <p className="text-sm text-red-500">
                        {formik?.errors.insurance_plans[index]?.issue_date}
                      </p>
                    )}
                </div>

                {/* Expiry Date Input */}
                <div>
                  <label className="text-sm">Expiry Date*</label>
                  <input
                    type="text"
                    name={`insurance_plans[${index}].expiry_date`}
                    onChange={formik?.handleChange}
                    value={formik?.values.insurance_plans?.[index]?.expiry_date}
                    id="expiry_date"
                    placeholder="Expiry Date"
                    className="!w-full"
                    onFocus={(e) => (e.target.type = "date")}
                    onBlur={(e) => (e.target.type = "text")}
                  />
                  {formik?.errors.insurance_plans?.length > 0 &&
                    formik?.errors.insurance_plans[index]?.expiry_date && (
                      <p className="text-sm text-red-500">
                        {formik?.errors.insurance_plans[index]?.expiry_date}
                      </p>
                    )}
                </div>

                {/* Member Card ID Input */}
                <div>
                  <label className="text-sm">Member Card ID*</label>
                  <input
                    type="text"
                    name={`insurance_plans[${index}].member_card_id`}
                    onChange={formik?.handleChange}
                    value={
                      formik?.values.insurance_plans?.[index]?.member_card_id
                    }
                    id="member_card_id"
                    placeholder="Member Card ID"
                    className="!w-full"
                  />
                  {formik?.errors.insurance_plans?.length > 0 &&
                    formik?.errors.insurance_plans[index]?.member_card_id && (
                      <p className="text-sm text-red-500">
                        {formik?.errors.insurance_plans[index]?.member_card_id}
                      </p>
                    )}
                </div>

                {/* Policy Number Input */}
                <div>
                  <label className="text-sm">Policy Number*</label>
                  <input
                    type="text"
                    name={`insurance_plans[${index}].policy_number`}
                    onChange={formik?.handleChange}
                    value={
                      formik?.values.insurance_plans?.[index]?.policy_number
                    }
                    id="policy_number"
                    placeholder="Policy Number"
                  />
                  {formik?.errors.insurance_plans?.length > 0 &&
                    formik?.errors.insurance_plans[index]?.policy_number && (
                      <p className="text-sm text-red-500">
                        {formik?.errors.insurance_plans[index]?.policy_number}
                      </p>
                    )}
                </div>

                {/* Relation with Subscriber */}
                <div>
                  <label className="text-sm">Relation with Subscriber</label>
                  <select
                    name={`insurance_plans[${index}].relation_with_subscriber`}
                    onChange={formik?.handleChange}
                    value={
                      formik?.values.insurance_plans?.[index]
                        ?.relation_with_subscriber
                    }
                    id="relation_with_subscriber"
                  >
                    {subscriberRelation.map((cvgItem) => (
                      <option key={cvgItem.id} value={cvgItem.id}>
                        {cvgItem.name}
                      </option>
                    ))}
                  </select>
                  {formik?.errors.insurance_plans?.length > 0 &&
                    formik?.errors.insurance_plans[index]
                      ?.relation_with_subscriber && (
                      <p className="text-sm text-red-500">
                        {
                          formik?.errors.insurance_plans[index]
                            ?.relation_with_subscriber
                        }
                      </p>
                    )}
                </div>

                {/* Patient Share Input */}
                <div>
                  <label className="text-sm">Patient Share</label>
                  <input
                    type="text"
                    name={`insurance_plans[${index}].patient_share`}
                    onChange={formik?.handleChange}
                    value={
                      formik?.values.insurance_plans?.[index]?.patient_share
                    }
                    id="patient_share"
                    placeholder="Patient Share"
                    className="!w-full"
                  />
                  {formik?.errors.insurance_plans?.length > 0 &&
                    formik?.errors.insurance_plans[index]?.patient_share && (
                      <p className="text-sm text-red-500">
                        {formik?.errors.insurance_plans[index]?.patient_share}
                      </p>
                    )}
                </div>

                {/* Max Limit Input */}
                <div>
                  <label className="text-sm">Max Limit*</label>

                  <input
                    type="text"
                    name={`insurance_plans[${index}].max_limit`}
                    onChange={formik?.handleChange}
                    value={formik?.values.insurance_plans?.[index]?.max_limit}
                    id="max_limit"
                    placeholder="Max Limit"
                    className="!w-full"
                  />
                  {formik?.errors.insurance_plans?.length > 0 &&
                    formik?.errors.insurance_plans[index]?.max_limit && (
                      <p className="text-sm text-red-500">
                        {formik?.errors.insurance_plans[index]?.max_limit}
                      </p>
                    )}
                </div>

                {/* Sponsor No Input */}
                <div>
                  <label className="text-sm">Sponsor No</label>

                  <input
                    type="text"
                    name={`insurance_plans[${index}].sponsor_no`}
                    onChange={formik?.handleChange}
                    value={formik?.values.insurance_plans?.[index]?.sponsor_no}
                    id="sponsor_no"
                    placeholder="Sponsor No"
                    className="!w-full"
                  />
                  {formik?.errors.insurance_plans?.length > 0 &&
                    formik?.errors.insurance_plans[index]?.sponsor_no && (
                      <p className="text-sm text-red-500">
                        {formik?.errors.insurance_plans[index]?.sponsor_no}
                      </p>
                    )}
                </div>

                {/* Policy Class Input */}
                <div>
                  <label className="text-sm">Policy Class</label>

                  <input
                    type="text"
                    name={`insurance_plans[${index}].policy_class`}
                    onChange={formik?.handleChange}
                    value={
                      formik?.values.insurance_plans?.[index]?.policy_class
                    }
                    id="policy_class"
                    placeholder="Policy Class"
                    className="!w-full"
                  />
                  {formik?.errors.insurance_plans?.length > 0 &&
                    formik?.errors.insurance_plans[index]?.policy_class && (
                      <p className="text-sm text-red-500">
                        {formik?.errors.insurance_plans[index]?.policy_class}
                      </p>
                    )}
                </div>

                {/* Policy Holder Input */}
                <div>
                  <label className="text-sm">Policy Holder</label>

                  <input
                    type="text"
                    name={`insurance_plans[${index}].policy_holder`}
                    onChange={formik?.handleChange}
                    value={
                      formik?.values.insurance_plans?.[index]?.policy_holder
                    }
                    id="policy_holder"
                    placeholder="Policy Holder"
                    className="!w-full"
                  />
                  {formik?.errors.insurance_plans?.length > 0 &&
                    formik?.errors.insurance_plans[index]?.policy_holder && (
                      <p className="text-sm text-red-500">
                        {formik?.errors.insurance_plans[index]?.policy_holder}
                      </p>
                    )}
                </div>

                {/* Insurance Status Input */}
                <div>
                  <label className="text-sm">Insurance Status</label>

                  <input
                    type="text"
                    name={`insurance_plans[${index}].insurance_status`}
                    onChange={formik?.handleChange}
                    value={
                      formik?.values.insurance_plans?.[index]?.insurance_status
                    }
                    id="insurance_status"
                    placeholder="Insurance Status"
                    className="!w-full"
                  />
                  {formik?.errors.insurance_plans?.length > 0 &&
                    formik?.errors.insurance_plans[index]?.insurance_status && (
                      <p className="text-sm text-red-500">
                        {
                          formik?.errors.insurance_plans[index]
                            ?.insurance_status
                        }
                      </p>
                    )}
                </div>

                {/* Insurance Duration Input */}
                <div>
                  <label className="text-sm">Insurance Duration</label>

                  <input
                    type="text"
                    name={`insurance_plans[${index}].insurance_duration`}
                    onChange={formik?.handleChange}
                    value={
                      formik?.values.insurance_plans?.[index]
                        ?.insurance_duration
                    }
                    id="insurance_duration"
                    placeholder="Insurance Duration"
                    className="!w-full"
                  />
                  {formik?.errors.insurance_plans?.length > 0 &&
                    formik?.errors.insurance_plans[index]
                      ?.insurance_duration && (
                      <p className="text-sm text-red-500">
                        {
                          formik?.errors.insurance_plans[index]
                            ?.insurance_duration
                        }
                      </p>
                    )}
                </div>

                {/* Insurance Type Input */}
                <div>
                  <label className="text-sm">Insurance Type</label>

                  <input
                    type="text"
                    name={`insurance_plans[${index}].insurance_type`}
                    onChange={formik?.handleChange}
                    value={
                      formik?.values.insurance_plans?.[index]?.insurance_type
                    }
                    id="insurance_type"
                    placeholder="Insurance Type"
                    className="!w-full"
                  />
                  {formik?.errors.insurance_plans?.length > 0 &&
                    formik?.errors.insurance_plans[index]?.insurance_type && (
                      <p className="text-sm text-red-500">
                        {formik?.errors.insurance_plans[index]?.insurance_type}
                      </p>
                    )}
                </div>

                {/* Coverage Type Input */}
                <div>
                  <label className="text-sm">Coverage Type</label>

                  <input
                    type="text"
                    name={`insurance_plans[${index}].coverage_type`}
                    onChange={formik?.handleChange}
                    value={
                      formik?.values.insurance_plans?.[index]?.coverage_type
                    }
                    id="coverage_type"
                    placeholder="Coverage Type"
                    className="!w-full"
                  />
                  {formik?.errors.insurance_plans?.length > 0 &&
                    formik?.errors.insurance_plans[index]?.coverage_type && (
                      <p className="text-sm text-red-500">
                        {formik?.errors.insurance_plans[index]?.coverage_type}
                      </p>
                    )}
                </div>

                {/* Network Input */}
                <div>
                  <label className="text-sm">Network</label>

                  <input
                    type="text"
                    name={`insurance_plans[${index}].network`}
                    onChange={formik?.handleChange}
                    value={formik?.values.insurance_plans?.[index]?.network}
                    id="network"
                    placeholder="Network"
                    className="!w-full"
                  />
                  {formik?.errors.insurance_plans?.length > 0 &&
                    formik?.errors.insurance_plans[index]?.network && (
                      <p className="text-sm text-red-500">
                        {formik?.errors.insurance_plans[index]?.network}
                      </p>
                    )}
                </div>

                <div className=" flex justify-end items-end">
                  <IconButton
                    color="error"
                    aria-label="delete"
                    size="small"
                    onClick={() => handleOpenDelAlert(index)}
                  >
                    <DeleteIcon />
                  </IconButton>
                </div>
              </div>

              {findPayerById(formik?.values?.insurance_plans[index]?.payer_id)
                ?.flag ? (
                <div className="text-red-500 text-sm flex gap-2 mt-2">
                  <span className="font-semibold">NOTE:</span>
                  <span>
                    {
                      findPayerById(
                        formik?.values?.insurance_plans[index]?.payer_id
                      )?.name
                    }{" "}
                    is cooperating with multiple TPAs. Kindly crosscheck from
                    the list
                  </span>
                </div>
              ) : null}
            </div>
          ))}
        </div>
      </div>

      <ConformationModal
        open={showDelAlert}
        setOpen={() => setShowDelAlert(false)}
        onDelete={onClickDelete}
      />
    </>
  );
}

export default InsurancePlans;
