import React, { useState } from "react";
import { Formik, FieldArray, Field, useFormikContext } from "formik";
import { PlusIcon as PlusIcon } from "@heroicons/react/24/solid";
import {
  TrashIcon as TrashIconMini,
  PlusCircleIcon,
} from "@heroicons/react/20/solid";
import { useQuery, gql, useMutation } from "@apollo/client";
// import { ToastContainer, toast } from "react-toastify";
import _ from "lodash";
import DiagnosisSearchNSelect from "../../PreAuth/components/request/DiagnosisSearchNSelect";
import supportingInfoElements from "../../PreAuth/components/request/SupportingInfoElement";
import {
  items as sites,
  dentalItems as oralItems,
} from "../../PreAuth/components/request/BodySites";
import ItemCodesSearchNSelect from "../../PreAuth/components/request/ItemCodesSearchNSelect";
import { MdAttachFile, MdDownload } from "react-icons/md";
import DentalTeethSelectModal from "../../PreAuth/components/DentalTeethSelectModal";
import { Button, Tooltip } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";

import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import MailIcon from "@mui/icons-material/Mail";
import toast from "react-hot-toast";

const menuItems = [
  { text: "Inbox", icon: <InboxIcon /> },
  { text: "Starred", icon: <MailIcon /> },
  { text: "Send email", icon: <InboxIcon /> },
  { text: "Drafts", icon: <MailIcon /> },
  { text: "All mail", icon: <InboxIcon /> },
  { text: "Trash", icon: <MailIcon /> },
  { text: "Spam", icon: <InboxIcon /> },
];

function SupportingInfoForm({ requireSupportingInfoElements }) {
  const compulsoryCategories = [
    "chief-complaint",
    "vital-sign-systolic",
    "vital-sign-diastolic",
    "vital-sign-height",
    "vital-sign-weight",
    "temperature",
    "respiratory-rate",
    "pulse",
  ];
  const {
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    handleSubmit,
    isSubmitting,
    setFieldValue,
  } = useFormikContext();
  const handleRemove = (remove, index, supportingInfos) => {
    const currentCategory = supportingInfos[index].category;

    if (compulsoryCategories.includes(currentCategory)) {
      const categoryCount = supportingInfos.filter(
        (info) => info.category === currentCategory
      ).length;

      if (categoryCount === 1) {
        toast.error(`${currentCategory} is compulsory.`);
        return;
      }
    }

    remove(index);
  };
  const [open, setOpen] = React.useState(false);

  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };

  const DrawerList = (push) => (
    <Box sx={{ width: 250 }} role="presentation" onClick={toggleDrawer(false)}>
      <List>
        {(requireSupportingInfoElements &&
        requireSupportingInfoElements.length > 0
          ? requireSupportingInfoElements
          : supportingInfoElements
        ).map((item, index) => (
          <ListItem key={item.id} disablePadding className="!p-0">
            <ListItemButton
              onClick={() => {
                push(item.object);
                setSupportingInfo(index);
              }}
              className="!p-0"
            >
              <ListItemIcon className="!ml-2">{item.icon}</ListItemIcon>
              <ListItemText primary={item?.name} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );

  const [showTeethSelectModal, setShowTeethSelectModal] = useState(false);
  const [supportingInfo, setSupportingInfo] = useState("");

  const addData = (values) => {
    let data = _.cloneDeep(values);
    // createInsurancePlan({ variables: { input: data } });
  };

  //method to transform category value to kebab-case
  const transformedData = () => {
    let data = [];

    (requireSupportingInfoElements && requireSupportingInfoElements.length > 0
      ? requireSupportingInfoElements
      : supportingInfoElements
    ).map((obj) => {
      // iterate over each object and transform its category property
      data.push({
        ...obj, // spread operator to copy all other properties
        object: {
          ...obj.object, // copy all other properties of nested object
          category: obj.object.category.toLowerCase().replace(/\s+/g, "-"),
        },
      });
    });

    return data;
  };

  function downloadBase64File(base64Data, fileName, fileType) {
    const byteCharacters = atob(base64Data);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: fileType });
    const url = URL.createObjectURL(blob);

    const a = document.createElement("a");
    a.href = url;
    a.download = fileName;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);

    URL.revokeObjectURL(url);
  }

  const tableHeaders = [
    {
      name: "No.",
    },
    {
      name: "Category",
    },
    {
      name: "Code",
    },
    {
      name: "Timing",
    },
    {
      name: "Value",
    },
    {
      name: "Attachment",
    },
    {
      name: "Reason",
    },
    {
      name: "",
    },
  ];

  const handleImageChange = (e, setFieldValue, index) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      setFieldValue(`supportingInfos.${index}.fileName`, file.name);
      setFieldValue(`supportingInfos.${index}.fileType`, file.type);

      // Use the extracted base64Data for further processing or upload
      const base64Data = reader.result.split(",")[1]; // Remove data type prefix

      setFieldValue(`supportingInfos.${index}.attachment`, base64Data);
    };
  };

  const missingToothReasons = [
    { id: "extraction", name: "Extraction" },
    { id: "congenital", name: "Congenital" },
    { id: "unknown", name: "Unknown" },
    { id: "other", name: "Other" },
  ];

  const getRequiredItemName = (desiredId) => {
    const result = _.find(oralItems, { id: desiredId });
    return result.name;
  };

  return (
    <form className="space-y-6" action="#">
      <div className="personal-data !p-3 !mb-3 !bg-gray-100">
        <div className="md:grid md:grid-cols-3 md:gap-6">
          <div className="md:col-span-3 md:mt-0">
            <FieldArray name="supportingInfos">
              {({ push, remove }) => {
                return (
                  <>
                    {/* <div className="md:flex md:items-center md:justify-between !mb-4">
                      <h3 className="text-lg font-medium leading-6 text-gray-900">
                        Supporting Info{" "}
                        <span className="text-sm text-red-500"></span>
                      </h3>
                    </div> */}

                    <div class="personal-title !mb-3">
                      <div class="inventory-svg">
                        <svg
                          width="34"
                          height="34"
                          viewBox="0 0 34 34"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <rect
                            width="34"
                            height="34"
                            rx="8"
                            fill="#F4F6F8"
                          ></rect>
                          <path
                            d="M9.25 11H11.25V14H21.25V11H23.25V16H25.25V11C25.25 9.9 24.35 9 23.25 9H19.07C18.65 7.84 17.55 7 16.25 7C14.95 7 13.85 7.84 13.43 9H9.25C8.15 9 7.25 9.9 7.25 11V25C7.25 26.1 8.15 27 9.25 27H15.25V25H9.25V11ZM16.25 9C16.8 9 17.25 9.45 17.25 10C17.25 10.55 16.8 11 16.25 11C15.7 11 15.25 10.55 15.25 10C15.25 9.45 15.7 9 16.25 9Z"
                            fill="#F5793B"
                          ></path>
                          <path
                            d="M25.25 17.5L19.76 23L16.75 20L15.25 21.5L19.76 26L26.75 19L25.25 17.5Z"
                            fill="#F5793B"
                          ></path>
                        </svg>
                      </div>
                      <div class="personal-head">
                        <span>Supporting Info</span>
                      </div>
                    </div>

                    <div className="mb-2 flex justify-end">
                      {/* {supportingInfoElements.map((item, index) => {
                        return (
                          <Button
                            variant="outlined"
                            size="small"
                            id={`supportingInfoElements_${index}`}
                            type="button"
                            // className="m-1 inline-flex items-center gap-x-1.5 rounded-md py-1.5 px-2.5 text-sm font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 !bg-primary"
                            onClick={() => {
                              push(item.object);
                              setSupportingInfo(index);
                            }}
                            startIcon={<AddIcon />}
                            className="!border-primary !text-primary  hover:!bg-primary/10 m-1"
                            // style={{
                            //   backgroundImage:
                            //     "linear-gradient(83deg, #f14f3e 0%, #fab768 100%)",
                            // }}
                          >
                            {item.name}
                          </Button>
                        );
                      })} */}
                      {/* <select
                        id={`supportingInfosElements`}
                        name={`supportingInfosElements`}
                        onChange={(e) => {
                          let index = e.target.value;
                          if (index) {
                            setSupportingInfo(index);
                            push(supportingInfoElements[index].object);
                          }
                          setSupportingInfo("");
                        }}
                        value={supportingInfo}
                        autoComplete="type-name"
                        className="block w-full rounded-md border-gray-300 shadow-sm focus:border-amber-500 focus:ring-amber-500 sm:text-sm"
                      >
                        <option value={""}>
                          <p>Please Select</p>
                        </option>
                        {supportingInfoElements.map((item, index) => {
                          return (
                            <option key={index} value={index}>
                              <p>{item.name}</p>
                            </option>
                          );
                        })}
                      </select> */}

                      {/* <Button
                        variant="contained"
                        size="small"
                        style={{
                          backgroundImage:
                            "linear-gradient(83deg, #f14f3e 0%, #fab768 100%)",
                        }}
                        onClick={toggleDrawer(true)}
                        startIcon={<AddIcon />}
                      >
                        Add Supporting Info
                      </Button>
                      <Drawer
                        // anchor="right"
                        open={open}
                        onClose={toggleDrawer(false)}
                      >
                        {DrawerList(push)}
                      </Drawer> */}
                      <div className="w-full">
                        <List className="grid grid-cols-4 md:grid-cols-8 gap-x-2 gap-y-4 items-center !pt-0 !pr-2.5">
                          {(requireSupportingInfoElements &&
                          requireSupportingInfoElements.length > 0
                            ? requireSupportingInfoElements
                            : supportingInfoElements
                          ).map((item, index) => (
                            <ListItem
                              key={item.id}
                              disablePadding
                              className="!p-0 !rounded-lg flex flex-col items-center"
                            >
                              <Tooltip title={item.name} arrow>
                                <ListItemButton
                                  onClick={() => {
                                    push(item.object);
                                    setSupportingInfo(index);
                                  }}
                                  className="!p-0 flex flex-col items-center"
                                >
                                  <span className="text-sm font-medium mb-1">
                                    {item.label}
                                  </span>{" "}
                                  {/* Display the label */}
                                  <ListItemIcon className="!m-auto !min-w-0 !rounded-md">
                                    {item.icon}
                                  </ListItemIcon>
                                </ListItemButton>
                              </Tooltip>
                            </ListItem>
                          ))}
                        </List>
                      </div>

                      {/* <div className="w-full">
                        <List className="grid grid-cols-12 gap-2 items-center">
                          {supportingInfoElements.map((item, index) => (
                            <ListItem
                              key={item.id}
                              disablePadding
                              className="!p-0 !w-[40px] !h-[40px] !rounded-lg"
                            >
                              <Tooltip title={item.name} arrow>
                                <ListItemButton
                                  onClick={() => {
                                    push(item.object);
                                    setSupportingInfo(index);
                                  }}
                                  className="!p-0"
                                >
                                  <ListItemIcon className="!m-auto !min-w-0 !rounded-md">
                                    {item.icon}
                                  </ListItemIcon>
                                </ListItemButton>
                              </Tooltip>
                            </ListItem>
                          ))}
                        </List>
                      </div> */}
                    </div>

                    {/* supporting-table-head table-not-scorll table-width-sets  */}
                    <div className="super-git-table">
                      {/* table table-hover !mb-0  */}
                      <table className="table-super-git">
                        {/* bg-amber-600 text-white !h-9 */}
                        <thead className="">
                          <tr className="">
                            {tableHeaders?.map((el, index) => {
                              return (
                                <th
                                  className="!py-2.5 !pr-0 !pl-8 !bg-[#ffab2c]"
                                  // className="py-2 first:!rounded-tl-lg last:!rounded-tl-lg"
                                  key={"tableHeaders_" + index}
                                >
                                  {el.name}
                                </th>
                              );
                            })}
                          </tr>
                        </thead>
                        <tbody>
                          {values.supportingInfos.map((item, index) => {
                            return (
                              <tr
                                key={"addressform"}
                                className="bg-transparent shadow-none"
                              >
                                <td>{index}</td>
                                <td>
                                  {values.supportingInfos[index]?.category}
                                </td>
                                <td>
                                  {"code" in item &&
                                    (item.category != "missingtooth" ? (
                                      <>
                                        <Tooltip
                                          title={
                                            values.supportingInfos[index]
                                              ?.code_text
                                              ? values.supportingInfos[index]
                                                  .code_text +
                                                " - " +
                                                values.supportingInfos[index]
                                                  ?.code
                                              : ""
                                          }
                                        >
                                          <span className="">
                                            {values.supportingInfos[index]?.code
                                              ? values.supportingInfos[index]
                                                  ?.code
                                              : ""}

                                            {values.supportingInfos[index]
                                              ?.code_text
                                              ? "( " +
                                                values.supportingInfos[
                                                  index
                                                ].code_text.slice(0, 20) +
                                                "... " +
                                                " )"
                                              : ""}
                                          </span>
                                        </Tooltip>
                                        {item.category != "lab-test" ? (
                                          <DiagnosisSearchNSelect
                                            values={
                                              values?.supportingInfos[index]
                                            }
                                            setFieldValue={(val) => {
                                              setFieldValue(
                                                `supportingInfos.${index}.code`,
                                                val.CodeId
                                              );
                                              setFieldValue(
                                                `supportingInfos.${index}.codeText`,
                                                val.AsciiDescription
                                              );
                                            }}
                                          />
                                        ) : (
                                          <ItemCodesSearchNSelect
                                            typeName={"loinc"}
                                            setFieldValue={(val) => {
                                              setFieldValue(
                                                `supportingInfos.${index}.code`,
                                                val.code
                                              );
                                              setFieldValue(
                                                `supportingInfos.${index}.codeText`,
                                                val.description
                                              );
                                            }}
                                          />
                                        )}
                                      </>
                                    ) : (
                                      <div className="flex">
                                        <select
                                          id={`supportingInfos.${index}.code`}
                                          name={`supportingInfos.${index}.code`}
                                          onChange={(e) => {
                                            let val = e.target.value;
                                            setFieldValue(
                                              `supportingInfos.${index}.code`,
                                              val
                                            );
                                            setFieldValue(
                                              `supportingInfos.${index}.codeText`,
                                              getRequiredItemName(val)
                                            );
                                          }}
                                          value={
                                            values?.supportingInfos[index]?.code
                                          }
                                          autoComplete="type-name"
                                          className="block w-full rounded-md rounded-r-none border-gray-300 shadow-sm focus:border-amber-500 focus:ring-amber-500 sm:text-sm"
                                        >
                                          <option value="">
                                            <p>Please Select</p>
                                          </option>
                                          {oralItems.map((item) => {
                                            return (
                                              <option value={item.id}>
                                                <p>{item.name}</p>
                                              </option>
                                            );
                                          })}
                                        </select>

                                        <DentalTeethSelectModal
                                          setShowTeethSelectModal={
                                            setShowTeethSelectModal
                                          }
                                          showTeethSelectModal={
                                            showTeethSelectModal
                                          }
                                          setFieldValue={setFieldValue}
                                          index={index}
                                          getRequiredItemName={
                                            getRequiredItemName
                                          }
                                        />

                                        <Button
                                          autoFocus
                                          size="small"
                                          onClick={() => {
                                            setShowTeethSelectModal(true);
                                          }}
                                          variant="contained"
                                          style={{
                                            backgroundImage:
                                              "linear-gradient(83deg, #f14f3e 0%, #fab768 100%)",
                                          }}
                                          className="!rounded-l-none !rounded-r-md"
                                        >
                                          {"Select"}
                                        </Button>
                                      </div>
                                    ))}
                                </td>
                                <td>
                                  {"startDate" in item && (
                                    <>
                                      <Field
                                        placeholder="Start Date"
                                        value={item?.startDate}
                                        type="date"
                                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-amber-500 focus:ring-amber-500 sm:text-sm"
                                        name={`supportingInfos.${index}.startDate`}
                                        onChange={handleChange}
                                      />
                                    </>
                                  )}
                                  {"endDate" in item && (
                                    <Field
                                      placeholder="End Date"
                                      value={item?.endDate}
                                      type="date"
                                      className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-amber-500 focus:ring-amber-500 sm:text-sm"
                                      name={`supportingInfos.${index}.endDate`}
                                      onChange={handleChange}
                                    />
                                  )}
                                </td>
                                {/* <td>
                                  {"value" in item && (
                                    <div className="relative mt-2 rounded-md shadow-sm">
                                      <Field
                                        placeholder="Enter Value"
                                        type="text"
                                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-amber-500 focus:ring-amber-500 sm:text-sm"
                                        name={`supportingInfos.${index}.value`}
                                      />
                                      <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                                        <span
                                          className="text-gray-500 sm:text-sm"
                                          id="price-currency"
                                        >
                                          {item.valueUnit}
                                        </span>
                                      </div>
                                    </div>
                                  )}
                                </td> */}
                                <td>
                                  {"value" in item && (
                                    <div className="relative mt-2 rounded-md shadow-sm">
                                      {item.category === "chief-complaint" ? (
                                        <textarea
                                          placeholder="Enter Value"
                                          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-amber-500 focus:ring-amber-500 sm:text-sm"
                                          name={`supportingInfos.${index}.value`}
                                          value={item?.value}
                                          onChange={handleChange}
                                          rows={1}
                                        />
                                      ) : (
                                        <Field
                                          placeholder="Enter Value"
                                          type="text"
                                          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-amber-500 focus:ring-amber-500 sm:text-sm"
                                          name={`supportingInfos.${index}.value`}
                                        />
                                      )}
                                      <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                                        <span
                                          className="text-gray-500 sm:text-sm"
                                          id="price-currency"
                                        >
                                          {item.valueUnit}
                                        </span>
                                      </div>
                                    </div>
                                  )}
                                </td>
                                <td>
                                  {"attachment" in item && (
                                    <>
                                      {!values.supportingInfos[index]
                                        .fileName ? (
                                        <input
                                          placeholder="Attachment"
                                          type="file"
                                          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-amber-500 focus:ring-amber-500 sm:text-sm"
                                          onChange={(e) => {
                                            handleImageChange(
                                              e,
                                              setFieldValue,
                                              index
                                            );
                                          }}
                                          accept="image/*"
                                          name={`imagefile`}
                                        />
                                      ) : (
                                        <div className="flex gap-2">
                                          {values?.supportingInfos[index]
                                            ?.fileType === "image/jpeg" ||
                                          values?.supportingInfos[index]
                                            ?.fileType === "image/png" ? (
                                            <div className="w-20 h-20 my-auto">
                                              <img
                                                src={`data:${values?.supportingInfos[index]?.fileType};base64,${values?.supportingInfos[index]?.attachment}`}
                                                alt={`${values?.supportingInfos[index]?.fileName} Image`}
                                                className="rounded-md"
                                              />
                                            </div>
                                          ) : null}
                                          <label className="flex gap-2 my-auto">
                                            {!(
                                              values?.supportingInfos[index]
                                                ?.fileType === "image/jpeg" ||
                                              values?.supportingInfos[index]
                                                ?.fileType === "image/png"
                                            ) ? (
                                              <span className="font-bold text-lg">
                                                <MdAttachFile />
                                              </span>
                                            ) : null}

                                            {
                                              values.supportingInfos[index]
                                                .fileName
                                            }

                                            <span
                                              className="font-bold text-lg my-auto ml-6"
                                              onClick={() =>
                                                downloadBase64File(
                                                  values?.supportingInfos[index]
                                                    ?.attachment,
                                                  values?.supportingInfos[index]
                                                    ?.fileName,
                                                  values?.supportingInfos[index]
                                                    ?.fileType
                                                )
                                              }
                                            >
                                              <MdDownload />
                                            </span>
                                          </label>
                                        </div>
                                      )}
                                    </>
                                  )}
                                </td>
                                <td>
                                  {"reason" in item &&
                                    (item.category != "missingtooth" ? (
                                      <>
                                        <Field
                                          placeholder="Enter Reason"
                                          type="text"
                                          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-amber-500 focus:ring-amber-500 sm:text-sm"
                                          name={`supportingInfos.${index}.reason`}
                                        />
                                      </>
                                    ) : (
                                      <>
                                        <select
                                          id={`supportingInfos.${index}.reason`}
                                          name={`supportingInfos.${index}.reason`}
                                          onChange={(e) => {
                                            let val = e.target.value;
                                            setFieldValue(
                                              `supportingInfos.${index}.reason`,
                                              val
                                            );
                                          }}
                                          value={
                                            values?.supportingInfos?.index
                                              ?.reason
                                          }
                                          autoComplete="type-name"
                                          className="block w-full rounded-md border-gray-300 shadow-sm focus:border-amber-500 focus:ring-amber-500 sm:text-sm"
                                        >
                                          <option value="">
                                            <p>Please Select</p>
                                          </option>
                                          {missingToothReasons.map((item) => {
                                            return (
                                              <option value={item.id}>
                                                <p>{item.name}</p>
                                              </option>
                                            );
                                          })}
                                        </select>
                                      </>
                                    ))}
                                </td>
                                <td>
                                  <button
                                    onClick={() =>
                                      handleRemove(
                                        remove,
                                        index,
                                        values.supportingInfos
                                      )
                                    }
                                    type="button"
                                    className="ml-3 inline-flex justify-center rounded-full border px-2 py-2 border-transparent bg-amber-600"
                                  >
                                    <TrashIconMini
                                      className="h-5 w-5 text-white"
                                      aria-hidden="true"
                                    />
                                  </button>
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                  </>
                );
              }}
            </FieldArray>
          </div>
        </div>
        {errors.supportingInfos && touched.supportingInfos ? (
          <p className="max-w-2xl mt-2 text-sm leading-6 text-red-500">
            {errors.supportingInfos}
          </p>
        ) : null}
      </div>
    </form>
  );
}

export default SupportingInfoForm;
