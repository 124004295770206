import React, { useState } from "react";
import {
  Formik,
  FieldArray,
  Field,
  useFormikContext,
  ErrorMessage,
} from "formik";
import { PlusIcon as PlusIcon } from "@heroicons/react/24/solid";
import { TrashIcon as TrashIconMini } from "@heroicons/react/20/solid";
import _ from "lodash";
import PractionerSearchNSelect from "./PractionerSearchNSelect";
import { Button, Tooltip } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";

function CareTeam({ data }) {
  const {
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    handleSubmit,
    isSubmitting,
    setFieldValue,
  } = useFormikContext();
  const addData = (values) => {
    let data = _.cloneDeep(values);
    // createInsurancePlan({ variables: { input: data } });
  };

  const tableHeaders = [
    {
      name: "Practitioner",
    },
    // {
    //   name: "Practitioner Role",
    // },
    {
      name: "Care Team Role",
    },
    {
      name: "Qualification",
    },
    {
      name: "",
    },
  ];

  const practionerRoles = [
    { id: "Doctor", name: "Doctor" },
    { id: "Nurse", name: "Nurse" },
    { id: "Pharmacist", name: "Pharmacist" },
    { id: "Researcher", name: "Researcher" },
    { id: "Teacher/educator", name: "Teacher/educator" },
    { id: "Dentist", name: "Dentist" },
    { id: "Physiotherapist", name: "Physiotherapist" },
    { id: "SpeechTherapist", name: "SpeechTherapist" },
    { id: "ICT professional", name: "ICT professional" },
  ];

  const careTeamRoles = [
    { id: "Primary Provider", name: "Primary Provider" },
    { id: "Assisting Provider", name: "Assisting Provider" },
    { id: "Supervising Provider", name: "Supervising Provider" },
    { id: "Other Provider", name: "Other Provider" },
  ];

  const qualifications = [
    {
      id: 1,
      name: "Qualification 1",
    },
    {
      id: 2,
      name: "Qualification 2",
    },
  ];

  const removeItem = (index) => {
    const updatedArr = [...values.careTeamsArr];
    updatedArr.splice(index, 1);
    setFieldValue("careTeamsArr", updatedArr);
  };

  return (
    <div class="care_teams !p-3 !mb-3" id="careTeams1">
      <FieldArray name="careTeamsArr">
        {({ push, remove }) => {
          return (
            <>
              <div class="flex_cont !mb-3">
                <div class="personal-title">
                  <div class="inventory-svg">
                    <svg
                      width="34"
                      height="34"
                      viewBox="0 0 34 34"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <rect width="34" height="34" rx="8" fill="#F4F6F8"></rect>
                      <path
                        d="M9.25 11H11.25V14H21.25V11H23.25V16H25.25V11C25.25 9.9 24.35 9 23.25 9H19.07C18.65 7.84 17.55 7 16.25 7C14.95 7 13.85 7.84 13.43 9H9.25C8.15 9 7.25 9.9 7.25 11V25C7.25 26.1 8.15 27 9.25 27H15.25V25H9.25V11ZM16.25 9C16.8 9 17.25 9.45 17.25 10C17.25 10.55 16.8 11 16.25 11C15.7 11 15.25 10.55 15.25 10C15.25 9.45 15.7 9 16.25 9Z"
                        fill="#F5793B"
                      ></path>
                      <path
                        d="M25.25 17.5L19.76 23L16.75 20L15.25 21.5L19.76 26L26.75 19L25.25 17.5Z"
                        fill="#F5793B"
                      ></path>
                    </svg>
                  </div>
                  <div class="personal-head">
                    <span>Care Team *</span>
                  </div>
                </div>

                <div>
                  <Button
                    variant="contained"
                    size="small"
                    style={{
                      backgroundImage:
                        "linear-gradient(83deg, #f14f3e 0%, #fab768 100%)",
                    }}
                    onClick={() =>
                      push({
                        practitioner: data ? data?.data?.name : "",
                        practitionerName: data ? data?.data?.name : "",
                        practitioner_role: data ? data?.data?.role : "",
                        care_team_role: "",
                        qualification: data ? data?.data?.speciality : "",
                      })
                    }
                    startIcon={<AddIcon />}
                  >
                    Add Care Team
                  </Button>
                </div>
              </div>
              <div class="pre_auth_communication_table">
                <div className="">
                  <div className="" id="tableBody">
                    {values?.careTeamsArr?.map((item, index) => {
                      return (
                        <div
                          key={"addressform"}
                          className="personal-data !p-3 !mb-3 last:!mb-0"
                        >
                          <div className="personal-fields grid grid-cols-1 md:grid-cols-2 gap-2 ">
                            <div className="">
                              <label className="text-sm">Practitioner</label>
                              {/* ({values.careTeamsArr?.[index]?.practitionerName}) */}
                              <Tooltip
                                title={
                                  values.careTeamsArr?.[index]
                                    ?.practitionerName || ""
                                }
                              >
                                <span>
                                  (
                                  {values.careTeamsArr?.[index]
                                    ?.practitionerName
                                    ? values.careTeamsArr[
                                        index
                                      ].practitionerName.slice(0, 20) + "..."
                                    : ""}
                                  )
                                </span>
                              </Tooltip>
                              <PractionerSearchNSelect
                                setFieldValue={(val) => {
                                  setFieldValue(
                                    `careTeamsArr.${index}.practitioner`,
                                    val.id
                                  );
                                  setFieldValue(
                                    `careTeamsArr.${index}.practitionerName`,
                                    val.name
                                  );
                                  setFieldValue(
                                    `careTeamsArr.${index}.practitioner_role`,
                                    val.Role
                                  );
                                  setFieldValue(
                                    `careTeamsArr.${index}.qualification`,
                                    `${val.Speciality}`
                                  );
                                }}
                              />
                              <ErrorMessage
                                name={`careTeamsArr.${index}.practitioner`}
                                component="div"
                                className="text-red-500 text-xs"
                              />
                            </div>
                            <div className="flex items-end">
                              <div className="w-full">
                                <label className="text-sm">
                                  Care Team Role
                                </label>
                                <Field
                                  as="select"
                                  name={`careTeamsArr.${index}.care_team_role`}
                                >
                                  <option value="">
                                    <p>Please Select</p>
                                  </option>
                                  {careTeamRoles.map((item) => {
                                    return (
                                      <option value={item.name}>
                                        {item.name}
                                      </option>
                                    );
                                  })}
                                </Field>
                                <ErrorMessage
                                  name={`careTeamsArr.${index}.care_team_role`}
                                  component="div"
                                  className="text-red-500 text-xs"
                                />
                              </div>
                            </div>
                            <div className="">
                              <label className="text-sm">Qualification</label>
                              <Field
                                placeholder="Qualifications"
                                type="text"
                                disabled
                                name={`careTeamsArr.${index}.qualification`}
                              />
                              <ErrorMessage
                                name={`careTeamsArr.${index}.qualification`}
                                component="div"
                                className="text-red-500 text-xs"
                              />
                            </div>
                            <div className="flex justify-end items-end">
                              <div
                                className=""
                                class="remove-row"
                                onClick={() => remove(index)}
                              >
                                <svg
                                  width="38"
                                  height="38"
                                  viewBox="0 0 38 38"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <circle
                                    cx="19"
                                    cy="19"
                                    r="19"
                                    fill="#DE5042"
                                  />
                                  <path
                                    fill-rule="evenodd"
                                    clip-rule="evenodd"
                                    d="M26 20H12C11.448 20 11 19.553 11 19C11 18.447 11.448 18 12 18H26C26.553 18 27 18.447 27 19C27 19.553 26.553 20 26 20Z"
                                    fill="white"
                                  />
                                  <mask
                                    id="mask0_235_11306"
                                    style={{
                                      WebkitMaskType: "luminance",
                                      maskType: "luminance",
                                    }}
                                    maskUnits="userSpaceOnUse"
                                    x="11"
                                    y="18"
                                    width="16"
                                    height="2"
                                  >
                                    <path
                                      fill-rule="evenodd"
                                      clip-rule="evenodd"
                                      d="M26 20H12C11.448 20 11 19.553 11 19C11 18.447 11.448 18 12 18H26C26.553 18 27 18.447 27 19C27 19.553 26.553 20 26 20Z"
                                      fill="white"
                                    />
                                  </mask>
                                  <g mask="url(#mask0_235_11306)"></g>
                                </svg>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>

                {/* <table>
                  <thead>
                    <tr>
                      {tableHeaders.map((el, index) => {
                        return (
                          <th
                            className="!py-3 !pr-0 !pl-8"
                            key={"tableHeaders_" + index}
                          >
                            {el.name}
                          </th>
                        );
                      })}
                    </tr>
                  </thead>
                  <tbody id="tableBody">
                    {values.careTeamsArr.map((item, index) => {
                      return (
                        <tr key={"addressform"}>
                          <td>
                            ({values.careTeamsArr?.[index]?.practitionerName})
                            <PractionerSearchNSelect
                              setFieldValue={(val) => {
                                setFieldValue(
                                  `careTeamsArr.${index}.practitioner`,
                                  val.id
                                );
                                setFieldValue(
                                  `careTeamsArr.${index}.practitionerName`,
                                  val.name
                                );
                                setFieldValue(
                                  `careTeamsArr.${index}.practitioner_role`,
                                  val.Role
                                );
                                setFieldValue(
                                  `careTeamsArr.${index}.qualification`,
                                  `${val.Speciality}`
                                );
                              }}
                            />
                            <ErrorMessage
                              name={`careTeamsArr.${index}.practitioner`}
                              component="div"
                              className="text-red-500 text-xs"
                            />
                          </td>
                          <td>
                            <Field
                              as="select"
                              name={`careTeamsArr.${index}.care_team_role`}
                            >
                              <option value="">
                                <p>Please Select</p>
                              </option>
                              {careTeamRoles.map((item) => {
                                return (
                                  <option value={item.name}>{item.name}</option>
                                );
                              })}
                            </Field>
                            <ErrorMessage
                              name={`careTeamsArr.${index}.care_team_role`}
                              component="div"
                              className="text-red-500 text-xs"
                            />
                          </td>
                          <td>
                            <Field
                              placeholder="Qualifications"
                              type="text"
                              disabled
                              name={`careTeamsArr.${index}.qualification`}
                            />
                            <ErrorMessage
                              name={`careTeamsArr.${index}.qualification`}
                              component="div"
                              className="text-red-500 text-xs"
                            />
                          </td>
                          <td class="remove-row" onClick={() => remove(index)}>
                            <svg
                              width="38"
                              height="38"
                              viewBox="0 0 38 38"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <circle cx="19" cy="19" r="19" fill="#DE5042" />
                              <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M26 20H12C11.448 20 11 19.553 11 19C11 18.447 11.448 18 12 18H26C26.553 18 27 18.447 27 19C27 19.553 26.553 20 26 20Z"
                                fill="white"
                              />
                              <mask
                                id="mask0_235_11306"
                                style={{
                                  WebkitMaskType: "luminance",
                                  maskType: "luminance",
                                }}
                                maskUnits="userSpaceOnUse"
                                x="11"
                                y="18"
                                width="16"
                                height="2"
                              >
                                <path
                                  fill-rule="evenodd"
                                  clip-rule="evenodd"
                                  d="M26 20H12C11.448 20 11 19.553 11 19C11 18.447 11.448 18 12 18H26C26.553 18 27 18.447 27 19C27 19.553 26.553 20 26 20Z"
                                  fill="white"
                                />
                              </mask>
                              <g mask="url(#mask0_235_11306)"></g>
                            </svg>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table> */}
              </div>
            </>
          );
        }}
      </FieldArray>

      {errors.careTeamsArr &&
      touched.careTeamsArr &&
      typeof errors.careTeamsArr === "string" ? (
        <div className="max-w-2xl mt-2 text-sm leading-6">
          <p className="text-red-500">{errors.careTeamsArr}</p>
        </div>
      ) : null}
    </div>
  );
}

export default CareTeam;
