import React, { createContext, useEffect, useState } from "react";

export const appContext = createContext({});

function ContextProvider({ children }) {
  const [showLoader, setShowLoader] = useState(false);
  const [showErrorAlert, setShowErrorAlert] = useState(false);
  const [ERPuser, setERPUser] = useState(null);
  const [invoiceCode, setInvoice] = useState(null);
  const [selectedRole, setSelectedRole] = useState(null);
  const [user, setUser] = useState({
    FullName: "",
    Email: "",
    RoleId: "",
    RoleName: "",
  });
  const [permissions, setPermissions] = useState([
    "add-users",
    "edit-users",
    "delete-users",
    "add-physician",
    "edit-physician",
    "delete-physician",
    "add-provider",
    "edit-provider",
    "delete-provider",
    "create-patient",
    "edit-patient",
    "delete-patient",
    "add-hospital",
    "edit-hospital",
    "delete-hospital",
    "add-clinic",
    "add-payer",
    "edit-payer",
    "delete-payer",
    "add-tpa",
    "add-staff",
    "delete-staff",
    "edit-staff",
    "add-branch",
    "view-dashboard",
    "view-beneficiary",
    "create-beneficiary",
    "view-providers",
    "view-preauth",
    "view-claims",
    "view-reconciliation",
    "view-priceList",
    "view-physicians",
    "view-users",
    "view-tob",
    "view-verification",
  ]);
  const [token, setToken] = useState(null);
  const [sidebarOpen, setSidebarOpen] = useState(true);
  const [activeLink, setActiveLink] = useState("");
  const [payersData, setPayersData] = useState(null);

  // const {
  //   loading: loadingPayers,
  //   error: errorPayer,
  //   data,
  //   refetch: refetchPayers,
  // } = useQuery(ptpa.GET_ALL_PAYERS);

  // useEffect(() => {
  //   if (data) {
  //     const payers = data.listPayersWithTpa.data;
  //     setPayersData(payers);
  //     localStorage.setItem("payersData", JSON.stringify(payers));
  //   }
  // }, [data]);
  return (
    <appContext.Provider
      value={{
        token,
        setToken,
        user,
        setUser,
        permissions,
        setPermissions,
        sidebarOpen,
        setSidebarOpen,
        showLoader,
        setShowLoader,
        showErrorAlert,
        setShowErrorAlert,
        setERPUser,
        ERPuser,
        setInvoice,
        invoiceCode,
        activeLink, // Include activeLink in context
        setActiveLink,
        selectedRole,
        setSelectedRole,
        // payersData, // Expose payersData to the context consumers
        // refetchPayers, // Include setActiveLink in context
      }}
    >
      {children}
    </appContext.Provider>
  );
}

export default ContextProvider;
