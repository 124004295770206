import { gql } from "@apollo/client";

const USER_LOGIN_MUTATION = gql`
  mutation userSignIn($input: SignInInput!) {
    userSignIn(input: $input) {
      message
      status
      data {
      his_refrence
      business_id
      business_name_en
      business_name_ar
      business_logo
        full_name
        email
        role_id
        role_name
        providers_id
        providers_branch_id
        jwt
        permission {
          permission_url
        }
      }
    }
  }
`;
const USER_REGISTER_MUTATION = gql`
  mutation usersRegistrationMutation($input: Users!) {
    usersRegistrationMutation(input: $input) {
      Message
      Status
      Data {
        ID
        Email
        FullName
        Password
        DateOfBirth
        NationalId
        Mobile2
        ProvidersId
        PmcId
        StatusId
        ProvidersBranchId
        PmcBranchId
      }
    }
  }
`;

const EDIT_USER = gql`
  mutation usersUpdate($input: UpdateUsers!) {
    usersUpdate(input: $input) {
      Message
      Status
      Data {
        ID
        Email
        FullName
        Password
        DateOfBirth
        NationalId
        Mobile2
        ProvidersId
        PmcId
        StatusId
        ProvidersBranchId
        PmcBranchId
        UpdatedAt
      }
    }
  }
`;

const GET_ALL_USERS = gql`
  query GetAllUsers($limit: Int!, $page: Int!) {
    getAllUsers(limit: $limit, page: $page) {
      Status
      Message
      Data {
        ID
        Email
        FullName
        DateOfBirth
        Mobile1
        StatusId
        RoleId
        RoleNameAr
        RoleNameEn
      }
      Pages
    }
  }
`;

const DELETE_USER = gql`
  mutation deleteUser($input: DeleteUser!) {
    deleteUser(input: $input) {
      Message
      Status
      Data {
        ID
        Email
        FullName
        Password
        DateOfBirth
        NationalId
        Mobile2
        ProvidersId
        StatusId
        PmcId
        ProvidersBranchId
        PmcBranchId
        UpdatedAt
      }
    }
  }
`;

//for testing
const GET_LOCATIONS = gql`
  query GetLocations {
    locations {
      id
      name
      description
      photo
    }
  }
`;

export default {
  USER_REGISTER_MUTATION,
  USER_LOGIN_MUTATION,
  GET_ALL_USERS,
  EDIT_USER,
  DELETE_USER,
};
