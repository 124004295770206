import React from "react";
import "../../reports/radilology.css"; // Link to custom CSS file
import { calculateAge } from "../Patient";

const SickLeaveReport = ({ data }) => {
  const dataArray = Array.isArray(data) ? data : [data];

  const patientInfo = dataArray[0]?.patient || {};
  const careTeamsArrInfo = dataArray[0]?.careTeamsArr || {};

  const user = localStorage?.getItem("user");
  const loggedUser = JSON.parse(user);

  return (
    <div className="report-container !p-[20px]">
      {/* Header */}
      <div className="header">
        <div className="header-left">
          <h2>{loggedUser?.business_name_en}</h2>
          <p>{loggedUser?.Email}</p>
        </div>

        <div className="header-center">
          <div className="report-title">Sick Leave Report</div>
        </div>

        <div className="header-right">
          <h2>{loggedUser?.business_name_ar}</h2>

          <div className="contact-info">
            <div className="contact-item">
              <img src={loggedUser?.business_logo} alt="logo" />
            </div>
          </div>
        </div>
      </div>

      {/* Patient Information Section */}
      <div className="patient-info flex justify-between">
        <div className="">
          <div className="info-row">
            <div className="label">
              <span>Patient Name:</span>
              <span className="info-value">
                {patientInfo.full_name || "N/A"}
              </span>
            </div>
          </div>

          <div className="info-row">
            <div className="label">
              <span>Gender:</span>{" "}
              <span className="info-value">{patientInfo.gender || "N/A"}</span>
            </div>
          </div>

          <div className="info-row">
            <div className="label">
              <span>MRN:</span>{" "}
              <span className="info-value">{patientInfo.mrn || "N/A"}</span>
            </div>
          </div>
        </div>

        <div className="">
          <div className="info-row">
            <div className="label">
              <span>Date of Birth:</span>{" "}
              <span className="info-value">{patientInfo?.dob || "N/A"}</span>
            </div>
          </div>
          <div className="info-row">
            <div className="label">
              <span>Age:</span>{" "}
              <span className="info-value">
                {calculateAge(patientInfo?.dob) || "0"} year
              </span>
            </div>
          </div>
          {/* <div className="info-row">
            <div className="label !mr-0">
              <span>Occupation & Place of Works: </span>{" "}
              <span className="info-value !font-light">
                ____________________
              </span>
            </div>
          </div> */}
          <div className="info-row !w-full">
            <div className="label !mr-0">
              <div className="mr-2 font-semibold text-[#000]">
                Occupation & Place of Works:{" "}
              </div>
              <div className="info-value">
                <input
                  className="w-full rounded-md border-none !py-1"
                  type="text"
                  name="occupation-place-of-Works"
                  id="occupation-place-of-Works"
                  placeholder="Enter Occupation"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="patient-info !grid !mb-0 !w-full">
        <div className="info-row !mb-2 !w-full">
          <div className="label !mr-0 !grid !grid-cols-2">
            <div className="font-semibold text-[#000]">
              He / She underwent clinical examination in the:{" "}
            </div>
            <div className="info-value !w-full">
              <input
                className="w-full rounded-md border-none !py-1"
                type="text"
                name="clinical-examination"
                id="clinical-examination"
                placeholder="Enter Name"
              />
            </div>
          </div>
        </div>

        <div className="flex gap-1">
          <div className="w-1/2">
            <div className="info-row !mb-2">
              <div className="label">
                <span>Date of Visit: </span>
                <span className="info-value">{data?.date}</span>
              </div>
            </div>
            <div className="info-row !mb-0">
              <div className="label">
                <span>Patient was admitted in: </span>
                <span className="info-value">General Practice</span>
              </div>
            </div>
          </div>

          <div className="w-1/2">
            <div className="flex w-full mb-1">
              <div className="w-1/3 font-semibold text-[#000]">
                Admit Date:{" "}
              </div>
              <div className="info-value">
                <input
                  className="w-full rounded-md border-none !py-1"
                  type="date"
                  name="admit-date"
                  id="admit-date"
                  placeholder="Date"
                />
              </div>
            </div>

            <div className="flex w-full">
              <div className="w-1/3 font-semibold text-[#000]">
                Discharge Date:{" "}
              </div>
              <div className="info-value">
                <input
                  className="w-full rounded-md border-none !py-1"
                  type="date"
                  name="discharge-date"
                  id="discharge-date"
                  placeholder="Date"
                />
              </div>
            </div>
          </div>
        </div>

        <div className="">
          <div className="info-row">
            <div className="label">
              <span>Diagnose: </span>
              {data?.diagnosisArr?.map((diagnosis, index) => (
                <div key={index} className="">
                  <div className="info-value">
                    {diagnosis?.codeDescription},{" "}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>

        <div className="">
          <div className="info-row">
            <div className="label !mr-0">
              <span>Significant Signs: </span>
              <span className="info-value">
                <div className="data-two py-2">
                  <div className="flex gap-3 items-center">
                    <div className="flex items-center text-center">
                      <p className=" ">
                        <span className="!mr-0 !font-normal">Systolic </span>
                        {data.supportingInfos.find(
                          (info) => info.category === "vital-sign-systolic"
                        )?.value || "No data"}
                      </p>
                    </div>

                    <div className="flex items-center text-center">
                      <p className=" ">
                        <span className="!mr-0 !font-normal">Diastolic </span>
                        {data.supportingInfos.find(
                          (info) => info.category === "vital-sign-diastolic"
                        )?.value || "No data"}
                      </p>
                    </div>

                    <div className="flex items-center text-center">
                      <p className=" ">
                        <span className="!mr-0 !font-normal">Pulse </span>
                        {data.supportingInfos.find(
                          (info) => info.category === "pulse"
                        )?.value || "No data"}
                      </p>
                    </div>

                    <div className="flex items-center text-center">
                      <p className=" ">
                        <span className="!mr-0 !font-normal">Temp </span>
                        {data.supportingInfos.find(
                          (info) => info.category === "temperature"
                        )?.value || "No data"}
                      </p>
                    </div>

                    <div className="flex items-center text-center">
                      <p className=" ">
                        <span className="!mr-0 !font-normal">Weight </span>
                        {data.supportingInfos.find(
                          (info) => info.category === "vital-sign-weight"
                        )?.value || "No data"}
                      </p>
                    </div>

                    <div className="flex items-center text-center">
                      <p className=" ">
                        <span className="!mr-0 !font-normal">Height </span>
                        {data.supportingInfos.find(
                          (info) => info.category === "vital-sign-height"
                        )?.value || "No data"}
                      </p>
                    </div>

                    <div className="flex items-center text-center">
                      <p className=" ">
                        <span className="!mr-0 !font-normal">R.R </span>
                        {data.supportingInfos.find(
                          (info) => info.category === "respiratory-rate"
                        )?.value || "No data"}
                      </p>
                    </div>
                  </div>
                </div>
              </span>
            </div>
          </div>
        </div>

        <div className="">
          <div className="info-row !mb-0">
            <div className="flex w-full">
              <div className="font-semibold text-[#000] mr-[5px]">
                Remarks:{" "}
              </div>
              <div className="w-full">
                <textarea
                  rows={2}
                  placeholder="Enter Remarks"
                  className="block w-full rounded-md border-gray-300 shadow-sm focus:border-amber-500 focus:ring-amber-500 sm:text-sm"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="patient-info !mb-0">
        <div className="">
          <div className="info-row">
            <div className="items-center">
              <div className="font-semibold text-[#000]">
                Following medical examination, sick leave is Recommended for
                ___days
              </div>
              <div className="font-semibold text-[#000]">
                Starting From __________ To __________
              </div>
            </div>
          </div>
        </div>

        <div className="mt-4">
          <div className="info-row">
            <div className="label">
              <span>Treating Physician Name:</span>{" "}
              <span className="info-value">
                {careTeamsArrInfo[0]?.practitionerName}
              </span>
            </div>
          </div>

          <div className="flex">
            <div className="info-row w-1/2">
              <div className="label !mr-0">
                <span>Signature: </span>{" "}
                <span className="info-value !font-light">
                  ____________________
                </span>
              </div>
            </div>

            <div className="info-row">
              <div className="label !mr-0">
                <span>Stamp: </span>{" "}
                <span className="info-value !font-light">
                  ____________________
                </span>
              </div>
            </div>
          </div>

          <div className="info-row w-1/2 mt-4">
            <div className="label !mr-0">
              <span>Medical Director: </span>{" "}
              <span className="info-value !font-light">
                ____________________
              </span>
            </div>
          </div>

          <div className="info-row w-1/2">
            <div className="label !mr-0">
              <span>Signature: </span>{" "}
              <span className="info-value !font-light">
                ____________________
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SickLeaveReport;
