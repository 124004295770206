import { Bar } from "react-chartjs-2";

const PatientsChart = () => {
  const data = {
    labels: ["", "", "", "", "", "", "", "", "", "", "", ""], // Added more labels for extra bars
    datasets: [
      {
        label: "Patients",
        backgroundColor: [
          "#4E79A7", // Muted Blue
          "#A0CBE8", // Light Blue
          "#F28E2B", // Orange
          "#FFBE7D", // Light Orange
          "#59A14F", // Green
          "#8CD17D", // Light Green
          "#B6992D", // Olive
          "#499894", // Teal
          "#E15759", // Red
          "#FF9F40", // Light Orange-Red
          "#F1CE63", // Light Yellow
          "#BCBD22", // Yellow-Green
        ],
        borderWidth: 1,
        hoverBackgroundColor: [
          "#4E79A7",
          "#A0CBE8",
          "#F28E2B",
          "#FFBE7D",
          "#59A14F",
          "#8CD17D",
          "#B6992D",
          "#499894",
          "#E15759",
          "#FF9F40",
          "#F1CE63",
          "#BCBD22",
        ],
        hoverBorderColor: [
          "#4E79A7",
          "#A0CBE8",
          "#F28E2B",
          "#FFBE7D",
          "#59A14F",
          "#8CD17D",
          "#B6992D",
          "#499894",
          "#E15759",
          "#FF9F40",
          "#F1CE63",
          "#BCBD22",
        ],
        data: [300, 400, 200, 350, 500, 450, 250, 300, 450, 375, 280, 320], // Added more data points
      },
    ],
  };

  const options = {
    maintainAspectRatio: false,
    scales: {
      x: {
        grid: {
          display: false,
        },
        ticks: {
          display: false,
        },
      },
      y: {
        grid: {
          display: false,
        },
        ticks: {
          display: false,
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
    },
    barThickness: 15, // Adjust as needed
  };

  return (
    <div className="bg-white p-4 rounded-lg shadow-md flex flex-col items-center justify-between h-full w-full">
      <h3 className="text-xl font-semibold ">Patient Chart</h3>{" "}
      <div className="h-56 w-full">
        <Bar data={data} options={options} height={260} />
      </div>
    </div>
  );
};

export default PatientsChart;
