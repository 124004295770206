import React, { useState } from "react";
import {
  Container,
  Grid,
  MenuItem,
  Select,
  Typography,
  Button,
  Paper,
  Avatar,
  ListItemText,
  Box,
  IconButton,
} from "@mui/material";
import { format, addDays, isToday } from "date-fns";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

// Patients data with name, phone, and image
const patients = [
  {
    name: "John Doe",
    phone: "555-1234",
    image: "https://via.placeholder.com/40",
  },
  {
    name: "Jane Smith",
    phone: "555-5678",
    image: "https://via.placeholder.com/40",
  },
  {
    name: "Michael Green",
    phone: "555-9101",
    image: "https://via.placeholder.com/40",
  },
];

// Doctors data categorized with name, phone, and image
const doctors = {
  General: [
    {
      name: "Dr. John",
      phone: "555-2222",
      image: "https://via.placeholder.com/40",
    },
    {
      name: "Dr. Sarah",
      phone: "555-3333",
      image: "https://via.placeholder.com/40",
    },
  ],
  Pediatric: [
    {
      name: "Dr. Max",
      phone: "555-4444",
      image: "https://via.placeholder.com/40",
    },
    {
      name: "Dr. Emma",
      phone: "555-5555",
      image: "https://via.placeholder.com/40",
    },
  ],
  Dental: [
    {
      name: "Dr. Leo",
      phone: "555-6666",
      image: "https://via.placeholder.com/40",
    },
    {
      name: "Dr. Mia",
      phone: "555-7777",
      image: "https://via.placeholder.com/40",
    },
  ],
};

// Time slots per doctor for appointments
const slotsPerDate = {
  "Dr. John": {
    "2024-10-10": ["09:00 AM", "10:00 AM", "11:00 AM"],
    "2024-10-11": ["10:00 AM", "12:00 PM", "02:00 PM"],
    "2024-10-12": ["09:00 AM", "12:00 PM", "01:00 PM", "03:00 PM"],
  },
  "Dr. Sarah": {
    "2024-10-10": ["08:00 AM", "09:30 AM", "11:30 AM"],
    "2024-10-11": ["09:00 AM", "01:00 PM"],
    "2024-10-12": ["08:30 AM", "10:30 AM", "12:30 PM", "02:00 PM"],
  },
  // Add other doctors and their slots here...
};

// Helper function to generate 30 days ahead
const generateDates = (numDays = 30) => {
  return Array.from({ length: numDays }, (_, i) => addDays(new Date(), i));
};

function Appointment() {
  const [selectedPatient, setSelectedPatient] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedDoctor, setSelectedDoctor] = useState("");
  const [selectedDateIndex, setSelectedDateIndex] = useState(0);
  const [selectedSlot, setSelectedSlot] = useState("");

  const dates = generateDates();
  const daysAheadToShow = 7;

  // Handlers
  const handlePatientChange = (e) => setSelectedPatient(e.target.value);
  const handleCategoryChange = (e) => {
    setSelectedCategory(e.target.value);
    setSelectedDoctor(""); // Reset doctor when category changes
  };
  const handleDoctorChange = (e) => setSelectedDoctor(e.target.value);

  const handleDateChange = (newIndex) => {
    setSelectedDateIndex(newIndex);
    setSelectedSlot(""); // Reset the slot when the date changes
  };

  const handleSlotSelect = (slot) => {
    setSelectedSlot(slot);
  };

  const handleSubmit = () => {
    if (
      selectedPatient &&
      selectedCategory &&
      selectedDoctor &&
      dates[selectedDateIndex] &&
      selectedSlot
    ) {
      alert(
        `Appointment Booked:\nPatient: ${selectedPatient}\nCategory: ${selectedCategory}\nDoctor: ${selectedDoctor}\nDate: ${format(
          dates[selectedDateIndex],
          "MM/dd/yyyy"
        )}\nSlot: ${selectedSlot}`
      );
    } else {
      alert("Please fill in all fields.");
    }
  };

  const displayedDates = dates.slice(
    selectedDateIndex,
    selectedDateIndex + daysAheadToShow
  );

  const currentDate = format(dates[selectedDateIndex], "yyyy-MM-dd");
  const availableSlots =
    selectedDoctor && slotsPerDate[selectedDoctor]?.[currentDate]
      ? slotsPerDate[selectedDoctor][currentDate]
      : [];

  return (
    <Container
      maxWidth="lg"
      sx={{
        mt: 4,
        p: 4,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Paper
        sx={{
          p: 3,
          borderRadius: 2,
          boxShadow: "rgba(0, 0, 0, 0.1) 0px 1px 2px 0px;",
          width: "100%",
        }}
      >
        <Typography
          variant="h4"
          sx={{
            mb: 2,
            fontWeight: "bold",
            textAlign: "center",
            color: "#1976D2",
          }}
        >
          Book Doctor Appointment
        </Typography>

        {/* Patient and Doctor Selection */}
        <Grid container spacing={4}>
          <Grid item xs={12} md={6} lg={4}>
            <Select
              value={selectedPatient}
              onChange={handlePatientChange}
              displayEmpty
              fullWidth
              variant="standard"
              sx={{
                mb: 2,
                "& .MuiSelect-select": {
                  display: "flex !important",
                  alignItems: "center",
                },
              }}
            >
              <MenuItem value="" disabled>
                Select Patient
              </MenuItem>
              {patients.map((patient) => (
                <MenuItem key={patient.name} value={patient.name}>
                  <Avatar
                    src={patient.image}
                    alt={patient.name}
                    sx={{ mr: 2 }}
                  />
                  <ListItemText
                    primary={patient.name}
                    secondary={patient.phone}
                  />
                </MenuItem>
              ))}
            </Select>
          </Grid>

          <Grid item xs={12} md={6} lg={4} className="!flex">
            <Select
              value={selectedCategory}
              onChange={handleCategoryChange}
              displayEmpty
              fullWidth
              variant="standard"
              sx={{ mb: 2 }}
            >
              <MenuItem value="" disabled>
                Select Category
              </MenuItem>
              {Object.keys(doctors).map((category) => (
                <MenuItem key={category} value={category}>
                  {category}
                </MenuItem>
              ))}
            </Select>
          </Grid>

          {selectedCategory && (
            <Grid item xs={12} md={6} lg={4}>
              <Select
                value={selectedDoctor}
                onChange={handleDoctorChange}
                displayEmpty
                fullWidth
                variant="standard"
                sx={{
                  mb: 2,
                  "& .MuiSelect-select": {
                    display: "flex !important",
                    alignItems: "center",
                  },
                }}
              >
                <MenuItem value="" disabled>
                  Select Doctor
                </MenuItem>
                {doctors[selectedCategory].map((doctor) => (
                  <MenuItem key={doctor.name} value={doctor.name}>
                    <Avatar
                      src={doctor.image}
                      alt={doctor.name}
                      sx={{ mr: 2 }}
                    />
                    <ListItemText
                      primary={doctor.name}
                      secondary={doctor.phone}
                    />
                  </MenuItem>
                ))}
              </Select>
            </Grid>
          )}

          {/* Custom Date Slider */}
          <Grid item xs={12}>
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <IconButton
                disabled={selectedDateIndex === 0}
                onClick={() => handleDateChange(selectedDateIndex - 1)}
                sx={{
                  borderRadius: "8px",
                }}
                className="!mr-8"
              >
                <ArrowBackIosIcon />
              </IconButton>

              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  flexGrow: 1,
                  maxWidth: "80%",
                }}
              >
                {displayedDates.map((date, index) => (
                  <Box
                    key={index}
                    sx={{
                      textAlign: "center",
                      p: 1.5,
                      cursor: "pointer",
                      borderRadius: "8px",
                      backgroundColor: isToday(date)
                        ? "#1976D2"
                        : selectedDateIndex === index
                        ? "#e0f7fa"
                        : "transparent",
                      color: isToday(date) ? "white" : "black",
                      transition: "0.3s",
                      "&:hover": {
                        backgroundColor: "#bbdefb",
                      },
                    }}
                    onClick={() => handleDateChange(selectedDateIndex + index)}
                  >
                    <Typography variant="subtitle1">
                      {format(date, "EEE")}
                    </Typography>
                    <Typography variant="h6">{format(date, "dd")}</Typography>
                  </Box>
                ))}
              </Box>

              <IconButton
                disabled={selectedDateIndex + daysAheadToShow >= dates.length}
                onClick={() => handleDateChange(selectedDateIndex + 1)}
                sx={{
                  borderRadius: "8px",
                }}
                className="!ml-8"
              >
                <ArrowForwardIosIcon />
              </IconButton>
            </Box>
          </Grid>

          {/* Time Slot Display */}
          <Grid item xs={12}>
            <Typography
              variant="h6"
              sx={{ mt: 2 }}
              className="!text-base !font-semibold"
            >
              Available Time Slots
            </Typography>
            <Box
              sx={{
                display: "flex",
                gap: 2,
                flexWrap: "wrap",
                mt: 2,
                justifyContent: "center",
              }}
            >
              {availableSlots.length > 0 ? (
                availableSlots.map((slot) => (
                  <Box
                    key={slot}
                    sx={{
                      p: 1.25,
                      borderRadius: "8px",
                      backgroundColor:
                        selectedSlot === slot ? "#1976D2" : "#f0f0f0",
                      color: selectedSlot === slot ? "white" : "black",
                      cursor: "pointer",
                      transition: "0.3s",
                      "&:hover": {
                        backgroundColor: "#bbdefb",
                      },
                    }}
                    onClick={() => handleSlotSelect(slot)}
                  >
                    {slot}
                  </Box>
                ))
              ) : (
                <Typography>No slots available for this day.</Typography>
              )}
            </Box>
          </Grid>

          {/* Submit Button */}
          <Grid item xs={12}>
            <Button
              variant="contained"
              onClick={handleSubmit}
              fullWidth
              sx={{
                backgroundColor: "#1976D2",
                color: "white",
                "&:hover": {
                  backgroundColor: "#1565C0",
                },
              }}
            >
              Book Appointment
            </Button>
          </Grid>
        </Grid>
      </Paper>
    </Container>
  );
}

export default Appointment;
