import { useState } from "react";

function MedicineBills2() {
    const [medicineBills, setmedicineBills] = useState([
        {
            billNo: "6YPARFIS",
            date: "21st Apr, 2024",
            patient: "Roberto Frias Morales",
            patientEmail: "nooricare@yopmail.com",
            doctor: "Vatsal Sakariya",
            doctorEmail: "vatsal@gmail.com",
            paymentMode: "Cash",
            netAmount: "Tk430.00",
            paymentStatus: "Unpaid",
        },
        {
            billNo: "6YPARFIS",
            date: "21st Apr, 2024",
            patient: "Denise Perez",
            patientEmail: "nooricare@yopmail.com",
            doctor: "Vatsal Sakariya",
            doctorEmail: "vatsal@gmail.com",
            paymentMode: "Cheque",
            netAmount: "Tk153.00",
            paymentStatus: "Unpaid",
        },
        {
            billNo: "6YPARFIS",
            date: "21st Apr, 2024",
            patient: "Roberto Frias Morales",
            patientEmail: "nooricare@yopmail.com",
            doctor: "Vatsal Sakariya",
            doctorEmail: "vatsal@gmail.com",
            paymentMode: "Cash",
            netAmount: "Tk4,323.00",
            paymentStatus: "Paid",
        },
        {
            billNo: "6YPARFIS",
            date: "21st Apr, 2024",
            patient: "Roberto Frias Morales",
            patientEmail: "nooricare@yopmail.com",
            doctor: "Vatsal Sakariya",
            doctorEmail: "vatsal@gmail.com",
            paymentMode: "Cash",
            netAmount: "Tk1,722.00",
            paymentStatus: "Paid",
        },
        {
            billNo: "6YPARFIS",
            date: "21st Apr, 2024",
            patient: "Denise Perez",
            patientEmail: "nooricare@yopmail.com",
            doctor: "Vatsal Sakariya",
            doctorEmail: "vatsal@gmail.com",
            paymentMode: "Cash",
            netAmount: "Tk738.00",
            paymentStatus: "Unpaid",
        },
        {
            billNo: "6YPARFIS",
            date: "21st Apr, 2024",
            patient: "Roberto Frias Morales",
            patientEmail: "nooricare@yopmail.com",
            doctor: "Vatsal Sakariya",
            doctorEmail: "vatsal@gmail.com",
            paymentMode: "Cash",
            netAmount: "Tk10,151.00",
            paymentStatus: "Paid",
        },
        {
            billNo: "6YPARFIS",
            date: "21st Apr, 2024",
            patient: "Denise Perez",
            patientEmail: "nooricare@yopmail.com",
            doctor: "Vatsal Sakariya",
            doctorEmail: "vatsal@gmail.com",
            paymentMode: "Cheque",
            netAmount: "Tk200.00",
            paymentStatus: "Unpaid",
        },
        {
            billNo: "6YPARFIS",
            date: "21st Apr, 2024",
            patient: "Roberto Frias Morales",
            patientEmail: "nooricare@yopmail.com",
            doctor: "Vatsal Sakariya",
            doctorEmail: "vatsal@gmail.com",
            paymentMode: "Cheque",
            netAmount: "Tk1,947.00",
            paymentStatus: "Unpaid",
        },
        {
            billNo: "6YPARFIS",
            date: "21st Apr, 2024",
            patient: "Denise Perez",
            patientEmail: "nooricare@yopmail.com",
            doctor: "Vatsal Sakariya",
            doctorEmail: "vatsal@gmail.com",
            paymentMode: "Cheque",
            netAmount: "Tk2,160.00",
            paymentStatus: "Unpaid",
        },
        {
            billNo: "6YPARFIS",
            date: "21st Apr, 2024",
            patient: "Roberto Frias Morales",
            patientEmail: "nooricare@yopmail.com",
            doctor: "Vatsal Sakariya",
            doctorEmail: "vatsal@gmail.com",
            paymentMode: "Cash",
            netAmount: "Tk1,722.00",
            paymentStatus: "Paid",
        },
        {
            billNo: "6YPARFIS",
            date: "21st Apr, 2024",
            patient: "Roberto Frias Morales",
            patientEmail: "nooricare@yopmail.com",
            doctor: "Vatsal Sakariya",
            doctorEmail: "vatsal@gmail.com",
            paymentMode: "Cash",
            netAmount: "Tk430.00",
            paymentStatus: "Paid",
        },
        {
            billNo: "6YPARFIS",
            date: "21st Apr, 2024",
            patient: "Denise Perez",
            patientEmail: "nooricare@yopmail.com",
            doctor: "Vatsal Sakariya",
            doctorEmail: "vatsal@gmail.com",
            paymentMode: "Cash",
            netAmount: "Tk153.00",
            paymentStatus: "Unpaid",
        },
        {
            billNo: "6YPARFIS",
            date: "21st Apr, 2024",
            patient: "Roberto Frias Morales",
            patientEmail: "nooricare@yopmail.com",
            doctor: "Vatsal Sakariya",
            doctorEmail: "vatsal@gmail.com",
            paymentMode: "Cash",
            netAmount: "Tk4,323.00",
            paymentStatus: "Paid",
        },
        {
            billNo: "6YPARFIS",
            date: "21st Apr, 2024",
            patient: "Roberto Frias Morales",
            patientEmail: "nooricare@yopmail.com",
            doctor: "Vatsal Sakariya",
            doctorEmail: "vatsal@gmail.com",
            paymentMode: "Cash",
            netAmount: "Tk1,722.00",
            paymentStatus: "Paid",
        },
        {
            billNo: "6YPARFIS",
            date: "21st Apr, 2024",
            patient: "Denise Perez",
            patientEmail: "nooricare@yopmail.com",
            doctor: "Vatsal Sakariya",
            doctorEmail: "vatsal@gmail.com",
            paymentMode: "Cash",
            netAmount: "Tk738.00",
            paymentStatus: "Unpaid",
        },
    ]);

    return (
        <div className="App">
            <div className="container py-2 mx-auto md:py-8">
                <div className="custom-table-container">
                    <table className="custom-table">
                        <thead id="table-head">
                            <tr>
                                <th className="text-center">BILL NO.</th>
                                <th className="text-center">DATE</th>
                                <th className="text-center">PATIENT</th>
                                <th className="text-center">DOCTOR</th>
                                <th>PAYMENT MODE</th>
                                <th>NET AMOUNT</th>
                                <th>PAYMENT STATUS</th>
                                <th>ACTION</th>
                            </tr>
                        </thead>
                        <tbody>
                            {medicineBills.map((medicineBill, index) => {
                                return (
                                    <tr key={index} className="text-center" style={{ border: "2px solid black" }}>
                                        <td data-cell="billNo" className="text-center"><button className="table-button">{medicineBill.billNo}</button></td>
                                        <td data-cell="date" className="text-center"><button className="table-button text-sm">{medicineBill.date}</button></td>
                                        <td data-cell="patient" className="text-center">
                                            <div className="name-cell flex flex-col text-center">
                                                <div className="email-text text-sm text-black">{medicineBill.patient}</div>
                                                <div className="email-text text-sm">{medicineBill.patientEmail}</div>
                                            </div>
                                        </td>
                                        <td data-cell="doctor" className="text-center">
                                            <div className="name-cell flex flex-col text-left">
                                                <div className="email-text text-sm text-black ">{medicineBill.doctor}</div>
                                                <div className="email-text text-sm">{medicineBill.doctorEmail}</div>
                                            </div>
                                        </td>
                                        <td data-cell="paymentMode"><button className="table-button">{medicineBill.paymentMode}</button></td>
                                        <td data-cell="netAmount">{medicineBill.netAmount}</td>
                                        <td data-cell="paymentStatus"><button className="table-button">{medicineBill.paymentStatus}</button></td>
                                        <td data-cell="action">
                                            <div className="actions">
                                                <button className="edit-btn">
                                                    <img src={"/icons/edit.png"} alt="Edit" />
                                                </button>
                                                <button className="delete-btn">
                                                    <img src={"/icons/delete.png"} alt="Delete" />
                                                </button>
                                                <button className="print-btn">
                                                    <img src={"/icons/print.png"}  alt="Print" />
                                                </button>
                                            </div>
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
}

export default MedicineBills2;
