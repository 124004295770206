import React, { useState } from 'react';

const ToggleSwitch = () => {
  const [isOn, setIsOn] = useState(false);

  const handleToggle = () => {
    setIsOn(!isOn);
  };

  return (
    <div
      className={`flex items-center cursor-pointer p-1 border-2 rounded-full ${
        isOn ? 'bg-gradient-to-r from-orange-500 to-pink-500' : 'bg-gray-300'
      }`}
      style={{ width: '45px', height: '25px' }}
      onClick={handleToggle}
    >
      <div
        className={`rounded-full bg-white border-2 ${
          isOn ? 'translate-x-5' : ''
        } transition-transform duration-300 ease-in-out`}
        style={{ width: '18px', height: '18px' }}
      >
        <div
          className="flex items-center justify-center h-full"
          style={{
            background: isOn
              ? 'linear-gradient(180deg, rgba(255,105,105,1) 0%, rgba(255,175,175,1) 100%)'
              : 'transparent',
            borderRadius: '50%',
          }}
        >
          <span className="block w-1 h-2 rounded-full bg-gradient-to-b from-pink-500 to-orange-500"></span> {/* Adjusted the size of the span */}
        </div>
      </div>
    </div>
  );
};

export default ToggleSwitch;
