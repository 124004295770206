import { Bar, Doughnut, Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
} from "chart.js";
import {
  FaUserCheck,
  FaCheckCircle,
  FaUserFriends,
  FaTimesCircle,
} from "react-icons/fa";
import HorizontalBarChart from "./Charts/HorizontalBarChart";
import PatientsChart from "./Charts/PatientChart";

const carsdata = [
  {
    title: "Beneficiaries",
    count: 52,
    icon: <FaUserCheck />,
    color: "bg-indigo-600", // Deep Indigo for a sophisticated look
  },
  {
    title: "Eligibilities",
    count: 83,
    icon: <FaCheckCircle />,
    color: "bg-teal-500", // Teal for a cool, calm vibe
  },
  {
    title: "User Eligibles",
    count: 68,
    icon: <FaUserFriends />,
    color: "bg-gray-500", // Dark Gray for a neutral, modern appearance
  },
  {
    title: "Non Actives",
    count: 21,
    icon: <FaTimesCircle />,
    color: "bg-yellow-500",
  },
];

// Register required Chart.js components
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement
);

const PatientSatisfaction = () => {
  const data = {
    labels: ["Beneficiaries", "Eligibles", "Eligibilities", "Not Actives"],
    datasets: [
      {
        data: [35, 40, 20, 5],
        backgroundColor: ["#4E79A7", "#A0CBE8", "#F28E2B", "#FFBE7D"],
        hoverBackgroundColor: ["#4E79A7", "#A0CBE8", "#F28E2B", "#FFBE7D"],
        borderWidth: 1,
      },
    ],
  };

  const options = {
    cutout: "70%",
  };

  return (
    <div className="bg-white p-4 rounded-lg shadow-md">
      <h3 className="text-xl font-semibold mb-4">Patient Satisfaction</h3>
      <div className="flex flex-row justify-between items-center">
        <div className="flex-1 w-72 h-72 ">
          <Doughnut data={data} options={options} />
        </div>
        <div className="flex flex-col justify-center ml-4">
          {carsdata.map((item, index) => (
            <div key={index} className="flex items-center mb-2">
              <div className={`p-2 rounded-full ${item.color} mr-2`}>
                {item.icon}
              </div>
              <div className="text-sm">
                {item.title}: {item.count}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

const LineChart = () => {
  const data = {
    labels: [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ],
    datasets: [
      {
        label: "Outpatients",
        backgroundColor: "rgba(75, 192, 192, 0.2)", // Filled area below the line
        borderColor: "rgba(75, 192, 192, 1)", // Line color
        pointBackgroundColor: "rgba(75, 192, 192, 1)", // Point color
        pointBorderColor: "#fff",
        pointHoverBackgroundColor: "#fff",
        pointHoverBorderColor: "rgba(75, 192, 192, 1)",
        data: [650, 590, 800, 810, 560, 550, 400, 600, 650, 700, 800, 900],
        fill: true, // Enable the filled area
        tension: 0.4, // Smoother line for a wavy effect
      },
      {
        label: "Inpatients",
        backgroundColor: "rgba(153, 102, 255, 0.2)",
        borderColor: "rgba(153, 102, 255, 1)",
        pointBackgroundColor: "rgba(153, 102, 255, 1)",
        pointBorderColor: "#fff",
        pointHoverBackgroundColor: "#fff",
        pointHoverBorderColor: "rgba(153, 102, 255, 1)",
        data: [280, 480, 400, 190, 860, 270, 900, 500, 650, 700, 750, 800],
        fill: true,
        tension: 0.4,
      },
    ],
  };

  return (
    <div className="bg-white p-4 w-full rounded-lg  shadow-md">
      <h3 className="text-xl font-semibold mb-4">Outpatients vs Inpatients</h3>
      <Line data={data} height={70} />
    </div>
  );
};

const WaitingTime = () => {
  return (
    <div className="bg-white h-96 p-4 rounded-lg shadow-md">
      <h3 className="text-xl font-semibold mb-4">Average Waiting Time</h3>
      <HorizontalBarChart />
    </div>
  );
};

const PatientsByDivision = () => {
  const divisions = [
    { name: "Dermatology", inpatient: 40, outpatient: 90 },
    { name: "Cardiology", inpatient: 90, outpatient: 200 },
    { name: "Oncology", inpatient: 60, outpatient: 110 },
    { name: "Surgery", inpatient: 220, outpatient: 320 },
    { name: "Gynecology", inpatient: 150, outpatient: 250 },
  ];

  return (
    <div className="bg-white p-4 rounded-lg shadow-md">
      <h3 className="text-xl font-semibold mb-4">Patients by Division</h3>
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
        {divisions.map((division) => (
          <div
            key={division.name}
            className="border border-gray-300 rounded-lg p-4 shadow hover:shadow-lg transition-shadow duration-300"
          >
            <h4 className="text-lg font-semibold mb-2">{division.name}</h4>
            <div className="flex">
              <div className="flex-1 pr-2 overflow-hidden">
                <p className="text-gray-500 truncate">Inpatient</p>
                <p className="text-xl font-bold truncate">{division.inpatient}</p>
              </div>
              <div className="border-l border-gray-300 pl-4 flex-1 overflow-hidden">
                <p className="text-gray-500 truncate">Outpatient</p>
                <p className="text-xl font-bold truncate">{division.outpatient}</p>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};


const Dashboard = () => {
  return (
    <div className="grid gap-8">
      {/* First Row */}
      <div className="grid grid-cols-1 md:grid-cols-4 gap-4">
        {carsdata.map((item, index) => (
          <div
            key={index}
            className={`${item.color} p-4 rounded-sm shadow-lg text-center`}
          >
            <div className="text-2xl font-semibold text-gray-900 mb-1 flex items-center justify-center">
              <span className="mr-2">{item.icon}</span>
              {item.title}
            </div>
            <div className="text-3xl font-bold text-gray-600">{item.count}</div>
          </div>
        ))}
      </div>

      {/* Second Row */}
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
        <div className="h-full">
          <PatientsChart />
        </div>
        <div className="h-full">
          <PatientSatisfaction />
        </div>
      </div>

      {/* Third Row */}
      <div>
        <LineChart />
      </div>

      {/* Fourth Row */}
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
        <div>
          <WaitingTime />
        </div>
        <div>
          <PatientsByDivision />
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
