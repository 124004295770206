import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import ListItemText from "@mui/material/ListItemText";
import ListItemButton from "@mui/material/ListItemButton";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
import { useQuery } from "@apollo/client";
import beneficiary from "../../../gql/beneficiary";
import AppModal from "../../../components/AppModal";
import toast from "react-hot-toast";
import { getPatientByID } from "../../HIS/components/ApiMethods";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function BeneficiaryDetailModal({
  open,
  setOpen,
  handleClose,
  handleClickOpen,
  selectedDetailID,
  setSelectedDetailID,
  patientID,
}) {
  // const { loading, error, data, refetch } = useQuery(
  //   beneficiary.GET_PATIENT_BY_ID,
  //   {
  //     variables: { id: selectedDetailID },
  //     fetchPolicy: "network-only",
  //     skip: !selectedDetailID,
  //   }
  // );

  // useEffect(() => {
  //   if (error?.graphQLErrors[0]?.message) {
  //     toast.error(error?.graphQLErrors[0]?.message);
  //   }
  // }, [error]);
  const data = [{}];
  const [patientData, setPatientData] = useState(null);
  const [loading, setLoading] = useState(false); // Loading state

  useEffect(() => {
    const fetchPatientData = async () => {
      if (patientID) {
        setLoading(true); // Start loading
        console.log("Fetching data for from modal patient ID:", patientID); // Log the ID being fetched
        try {
          // Pass setLoading, patientID, and set to getPatientByID
          const data = await getPatientByID(
            setLoading,
            patientID,
            setPatientData
          );
          setPatientData(data); // Store the fetched patient data
        } catch (error) {
          console.error("Error fetching patient details:", error);
        } finally {
          setLoading(false); // Stop loading
        }
      }
    };

    fetchPatientData(); // Call the fetch function
  }, [patientID]); // Effect runs when patientID changes

  if (loading) return <div>Loading...</div>;
  // if (!patientData) return <div>No patient data available.</div>;
  const insurancePlans = patientData?.data?.insurance_plans || [];

  const tableHeaders = [
    // {
    //   name: "Primary",
    // },
    {
      name: "Payer",
    },
    {
      name: "Expiry Date",
    },
    {
      name: "Member Card ID",
    },
    {
      name: "Policy Number",
    },
    // {
    //   name: "Patient Share",
    // },
    // {
    //   name: "MaxLimit",
    // },
    {
      name: "Relation with Subscriber",
    },
    {
      name: "Coverage Type",
    },
    // {
    //   name: "Action",
    // },
  ];

  // if (loading) return <p>Loading...</p>;
  // if (error) return <p>Error: {error.message}</p>;
  return (
    <div>
      <Dialog
        fullScreen
        open={open}
        onClose={() => {
          handleClose();
          setSelectedDetailID(null);
        }}
        TransitionComponent={Transition}
        style={{ background: "#ffab2c" }}
      >
        <AppBar sx={{ position: "relative" }}>
          <Toolbar className="" style={{ background: "#ffab2c" }}>
            <IconButton
              edge="start"
              color="inherit"
              onClick={() => {
                handleClose();
                setSelectedDetailID(null);
              }}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              Beneficiary Detail
            </Typography>
            <Button
              autoFocus
              color="inherit"
              size="small"
              onClick={() => {
                handleClose();
                setSelectedDetailID(null);
              }}
            >
              Close
            </Button>
          </Toolbar>
        </AppBar>

        <div className="p-3">
          <div className="mt-2">
            <span className="text-2xl font-semibold">Patient Detail</span>
            {/* <Divider className="my-2" /> */}
          </div>
          {patientData && (
            <div className="my-2">
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-2">
                {/* Patient Information Card */}
                <div className="card p-2 shadow-md">
                  <div className="flex justify-between">
                    <span className="font-semibold">Name</span>
                    <span className="capitalize">
                      {patientData.data?.full_name || "____"}
                    </span>
                  </div>

                  <div className="flex justify-between mt-2">
                    <span className="font-semibold">Date of Birth</span>
                    <span className="capitalize">
                      {patientData.data?.dob || "____"}
                    </span>
                  </div>

                  <div className="flex justify-between mt-2">
                    <span className="font-semibold">Gender</span>
                    <span className="capitalize">
                      {patientData.data?.gender || "____"}
                    </span>
                  </div>

                  <div className="flex justify-between mt-2">
                    <span className="font-semibold">Contact Number</span>
                    <span className="capitalize">
                      {patientData.data?.contact || "____"}
                    </span>
                  </div>
                </div>

                {/* Document Information Card */}
                <div className="card p-2 shadow-md">
                  <div className="flex justify-between">
                    <span className="font-semibold">Document Type</span>
                    <span className="capitalize">
                      {patientData.data?.document_type || "____"}
                    </span>
                  </div>

                  <div className="flex justify-between mt-2">
                    <span className="font-semibold">Document ID</span>
                    <span className="capitalize">
                      {patientData.data?.document_id || "____"}
                    </span>
                  </div>

                  <div className="flex justify-between mt-2">
                    <span className="font-semibold">Residency Type</span>
                    <span className="capitalize">
                      {patientData.data?.residency_type || "____"}
                    </span>
                  </div>

                  <div className="flex justify-between mt-2">
                    <span className="font-semibold">File Number</span>
                    <span>{patientData.data?.file_no || "____"}</span>
                  </div>
                </div>

                {/* Visa Information Card */}
                <div className="card p-2 shadow-md">
                  <div className="flex justify-between">
                    <span className="font-semibold">Visa Title</span>
                    <span className="capitalize">
                      {patientData.data?.visa_title || "____"}
                    </span>
                  </div>

                  <div className="flex justify-between mt-2">
                    <span className="font-semibold">Visa Number</span>
                    <span>{patientData.data?.visa_no || "____"}</span>
                  </div>

                  <div className="flex justify-between mt-2">
                    <span className="font-semibold">Visa Type</span>
                    <span className="capitalize">
                      {patientData.data?.visa_type || "____"}
                    </span>
                  </div>
                </div>

                {/* Additional Information Card */}
                <div className="card p-2 shadow-md">
                  <div className="flex justify-between">
                    <span className="font-semibold">Passport Number</span>
                    <span>{patientData.data?.passport_no || "____"}</span>
                  </div>

                  <div className="flex justify-between mt-2">
                    <span className="font-semibold">Border Number</span>
                    <span>{patientData.data?.border_no || "____"}</span>
                  </div>

                  <div className="flex justify-between mt-2">
                    <span className="font-semibold">Insurance Duration</span>
                    <span>
                      {patientData.data?.insurance_duration || "____"}
                    </span>
                  </div>
                </div>
              </div>

              <div className="mt-6">
                <span className="text-2xl font-semibold">Insurance Plans</span>
                {/* <Divider className="my-2" /> */}
              </div>
            </div>
          )}
          {insurancePlans.map((el, index) => (
            <div key={index} className="card shadow-md p-2 m-2">
              <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-3">
                <div>
                  <div className="flex justify-between">
                    <span className="font-semibold">Payer Name</span>
                    <span className="capitalize">
                      {el?.payer_name.length > 35
                        ? el?.payer_name.substring(0, 35) + "..."
                        : el?.payer_name || "____"}
                    </span>
                  </div>

                  <div className="flex justify-between mt-2">
                    <span className="font-semibold">Is Primary</span>
                    <span className="capitalize">
                      {el.is_primary ? "Yes" : "No"}
                    </span>
                  </div>

                  <div className="flex justify-between mt-2">
                    <span className="font-semibold">Issue Date</span>
                    <span className="capitalize">
                      {el?.issue_date || "____"}
                    </span>
                  </div>

                  <div className="flex justify-between mt-2">
                    <span className="font-semibold">Expiry Date</span>
                    <span className="capitalize">
                      {el?.expiry_date || "____"}
                    </span>
                  </div>

                  <div className="flex justify-between mt-2">
                    <span className="font-semibold">
                      Relation With Subscriber
                    </span>
                    <span className="capitalize">
                      {el?.relation_with_subscriber || "____"}
                    </span>
                  </div>

                  <div className="flex justify-between mt-2">
                    <span className="font-semibold">Member Card ID</span>
                    <span className="capitalize">
                      {el?.member_card_id || "____"}
                    </span>
                  </div>
                </div>

                <div>
                  <div className="flex justify-between">
                    <span className="font-semibold">Policy Number</span>
                    <span className="capitalize">
                      {el?.policy_number || "____"}
                    </span>
                  </div>

                  <div className="flex justify-between mt-2">
                    <span className="font-semibold">Patient Share</span>
                    <span className="capitalize">
                      {el?.patient_share || "____"}
                    </span>
                  </div>

                  <div className="flex justify-between mt-2">
                    <span className="font-semibold">Max Limit</span>
                    <span className="capitalize">
                      {el?.max_limit || "____"}
                    </span>
                  </div>

                  <div className="flex justify-between mt-2">
                    <span className="font-semibold">Network ID</span>
                    <span className="capitalize">
                      {el?.network_id || "____"}
                    </span>
                  </div>

                  <div className="flex justify-between mt-2">
                    <span className="font-semibold">Coverage Type</span>
                    <span className="capitalize">
                      {el?.coverage_type || "____"}
                    </span>
                  </div>

                  <div className="flex justify-between mt-2">
                    <span className="font-semibold">Sponsor Number</span>
                    <span className="capitalize">
                      {el?.sponsor_no || "____"}
                    </span>
                  </div>
                </div>

                <div>
                  <div className="flex justify-between">
                    <span className="font-semibold">Policy Class Name</span>
                    <span className="capitalize">
                      {el?.policy_class || "____"}
                    </span>
                  </div>

                  <div className="flex justify-between mt-2">
                    <span className="font-semibold">Policy Holder</span>
                    <span className="capitalize">
                      {el?.policy_holder || "____"}
                    </span>
                  </div>

                  <div className="flex justify-between mt-2">
                    <span className="font-semibold">Insurance Status</span>
                    <span className="capitalize">
                      {el?.insurance_status || "____"}
                    </span>
                  </div>

                  <div className="flex justify-between mt-2">
                    <span className="font-semibold">Insurance Type</span>
                    <span className="capitalize">
                      {el?.insurance_type || "____"}
                    </span>
                  </div>

                  <div className="flex justify-between mt-2">
                    <span className="font-semibold">Insurance Duration</span>
                    <span className="capitalize">
                      {el?.insurance_duration || "____"}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          ))}
          {/* <div class="super-git-table mt-2">
            <table class="table-super-git">
              <thead>
                <tr>
                  {tableHeaders.map((el, index) => {
                    return <th key={"tableHeaders_" + index}>{el.name}</th>;
                  })}
                </tr>
              </thead>
              <tbody>
                {data?.getPatient?.data?.insurance_plans.map((el, index) => {
                  return (
                    <tr key={"beneficiariesData_" + index}>
                      
                      <td>{el.payer?.name_en}</td>
                      <td>{el.expiry_date}</td>
                      <td>{el.member_card_id}</td>
                      <td>{el.policy_number}</td>
                      
                      <td>{el.relation_with_subscriber}</td>
                      <td>{el.coverage_type}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div> */}
        </div>
      </Dialog>
      {/* <AppModal isOpen={loading} /> */}
    </div>
  );
}
