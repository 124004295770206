import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const LabHorizontalBarChart = () => {
  const data = {
    labels: [
      "Hematology",
      "Biochemistry",
      "Microbiology",
      "Immunology",
      "Virology",
    ],
    datasets: [
      {
        label: "Avg. Test Turnaround Time (minutes)",
        data: [35, 45, 30, 50, 40], // Example test turnaround times
        backgroundColor: [
          "#FF6384", // Hematology
          "#36A2EB", // Biochemistry
          "#FFCE56", // Microbiology
          "#4BC0C0", // Immunology
          "#9966FF", // Virology
        ],
        borderColor: ["#FF6384", "#36A2EB", "#FFCE56", "#4BC0C0", "#9966FF"],
        borderWidth: 1,
        barThickness: 10, // Thinner bars
        maxBarThickness: 15, // Ensure the bars don't get too thick
      },
    ],
  };

  const options = {
    indexAxis: "y", // Horizontal bar chart
    responsive: true,
    maintainAspectRatio: false, // Ensure chart fills container
    scales: {
      x: {
        beginAtZero: true, // Start the X-axis from zero
        ticks: {
          stepSize: 10, // Intervals for turnaround times
        },
      },
    },
    plugins: {
      legend: {
        display: false, // Hide the legend for a cleaner look
      },
      tooltip: {
        enabled: true, // Enable tooltips for better interaction
      },
    },
    layout: {
      padding: {
        left: 5, // Reduce left padding
        right: 5, // Reduce right padding
        top: 5, // Reduce top padding
        bottom: 5, // Reduce bottom padding
      },
    },
  };

  return (
    <div className="h-full w-full bg-white p-4 rounded-lg shadow-md">
      <h3 className="text-xl font-semibold mb-4">
        Average Test Turnaround Time by Lab Division
      </h3>
      <div style={{ height: "250px", width: "100%" }}>
        <Bar data={data} options={options} />
      </div>
    </div>
  );
};

export default LabHorizontalBarChart;
