import { useState } from "react";

function NoticeBoards3() {
  const [notices, setNotices] = useState([
    {
      title: "Code Pink! Emergency!",
      createdOn: "24th July 2024",
      time: "12:19 pm",
    },
    {
      title: "ewewqewewq",
      createdOn: "24th July 2024",
      time: "12:19 pm",
    },
    {
      title: "Diagno",
      createdOn: "24th July 2024",
      time: "12:19 pm",
    },
    {
      title: "test city hospital",
      createdOn: "24th July 2024",
      time: "12:19 pm",
    },
    {
      title: "FM services",
      createdOn: "24th July 2024",
      time: "12:19 pm",
    },
    {
      title: "Emergency Request",
      createdOn: "24th July 2024",
      time: "12:19 pm",
    },
    {
      title: "Care Tips for COVID-19",
      createdOn: "24th July 2024",
      time: "12:19 pm",
    },
    {
      title: "Emergency with or without ICU",
      createdOn: "24th July 2024",
      time: "12:19 pm",
    },
    {
      title: "OPT",
      createdOn: "24th July 2024",
      time: "12:19 pm",
    },
    {
      title: "fever",
      createdOn: "24th July 2024",
      time: "12:19 pm",
    },
    {
      title: "OPT",
      createdOn: "24th July 2024",
      time: "12:19 pm",
    },
    {
      title: "test",
      createdOn: "24th July 2024",
      time: "12:19 pm",
    },
    {
      title: "Diagno",
      createdOn: "24th July 2024",
      time: "12:19 pm",
    },
    {
      title: "test dia",
      createdOn: "24th July 2024",
      time: "12:19 pm",
    },
    {
      title: "fever",
      createdOn: "24th July 2024",
      time: "12:19 pm",
    },
  ]);

  return (
    <div className="App">
      <div className="container py-2 mx-auto md:py-8">
        <div className="custom-table-container">
          <table className="custom-table">
            <thead id="table-head">
              <tr>
                <th>

                <div className="text-left ml-9">TITLE</div>
                </th>
                <th>

                <div className="text-left ml-24">POSTED AT</div>
                </th>
                
                <th className="mr-2">ACTION</th>
              </tr>
            </thead>
            <tbody>
              {notices.map((notice, index) => {
                return (
                  <tr key={index} className="text-center" style={{ border: "2px solid black" }}>
                    <td data-cell="title" className="text-center">
                      <div className="name-cell flex flex-col text-left items-start ">
                        <div className="ml-9">{notice.title}</div>
                      </div>
                    </td>
                    {/* <td data-cell="postedAt">{notice.postedAt}</td> */}
                    <td data-cell="createdOn">
                      <div className="text-sm mr-72">{notice.time}</div>
                      <div className="text-sm mr-72">{notice.createdOn}</div>
                    </td>
                    <td data-cell="action">
                      <div className="actions">
                        <button className="edit-btn">
                          <img src={"/icons/edit.png"} alt="Edit" />
                        </button>
                        <button className="delete-btn">
                          <img src={"/icons/delete.png"} className="ms-2" alt="Delete" />
                        </button>
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default NoticeBoards3;
