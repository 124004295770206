import React from "react";

const BloodDonationModal = ({ isOpen, onClose }) => {
    if (!isOpen) return null;

    return (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
            <div className="bg-white rounded-lg shadow-lg p-9 w-1/3 max-w-xs sm:max-w-sm md:max-w-md lg:max-w-lg xl:max-w-2xl">
                <h2 className="text-xl font-semibold mb-4">New Blood Donation</h2>
                <hr className="mb-6 border-black"  />
                <div className="mb-4">
                    <label className="block text-gray-700 font-bold mb-2">Donor Name: </label>
                    <select className="w-full px-3 py-2 border bg-gray-200 border-gray-300 focus:outline-none focus:ring focus:border-blue-300 rounded">
                        <option value="" style={{ display: "none" }} >Select Donor Name</option>
                    </select>
                </div>
                <div className="mb-4">
                    <label className="block text-gray-700 font-bold mb-2">Bags: </label>
                    <input
                        type="text"
                        placeholder="1"
                        className="w-full px-3 py-2 border border-black rounded focus:outline-none focus:ring focus:border-blue-300"
                    />
                </div>
                <div className="flex justify-center space-x-4 mt-6">
                    <button
                        onClick={onClose}
                        className="px-4 py-2 bg-gray-300 rounded shadow hover:bg-gray-400"
                    >
                        Save
                    </button>
                    <button
                        onClick={onClose}
                        className="px-4 py-2 bg-gray-300 rounded shadow hover:bg-gray-400"
                    >
                        Cancel
                    </button>
                    <button
                        onClick={onClose}
                        className="px-4 py-2 bg-gray-300 rounded shadow hover:bg-gray-400"
                    >
                        Back
                    </button>
                </div>
            </div>
        </div>
    );
};

export default BloodDonationModal;
