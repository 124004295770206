import { FileUpload, HistoryOutlined } from "@mui/icons-material";

import { hisLogin } from "../pages/HIS/components/ApiMethods";
import { useEffect } from "react";
import PublishedWithChangesOutlinedIcon from "../assets/imageHis/heart.svg";
import LocalHospitalOutlinedIcon from "../assets/icons/2.png"; // Example path
import PersonIcon from "../assets/imageHis/8.png";
import blood from "../assets/icons/labs/1.png";
import lab from "../assets/icons/labs/3.png";
import labDoctor from "../assets/icons/labs/2.png";
import labDiagnosis from "../assets/icons/labs/5.png";
import meetings from "../assets/icons/labs/4.png";
import medicines from "../assets/icons/labs/6.png";
import payroll from "../assets/icons/labs/7.png"; // Example path
import pathology from "../assets/icons/labs/8.png";
import radiology from "../assets/icons/labs/9.png"; // Example path
import noticeboard from "../assets/icons/Pharmacy/2.png";

// Example path

import FormatListBulletedOutlinedIcon from "../assets/icons/7.png"; // Example path
import AddBoxIcon from "../assets/icons/8.png"; // Example path
import AppointmentIcon from "../assets/icons/appointment.png"; // Example path
import EditNoteIcon from "../assets/icons/Pharmacy/6.png";
import doctors from "../assets/icons/doctors/3.png"; // Example path
import ReceiptIcon from "../assets/icons/Pharmacy/7.png"; // Example path
import hisIcon from "../assets/icons/doctors/9.png"; // Example path
import createPatientIcon from "../assets/icons/9.png"; // Example path
import createAppointmentIcon from "../assets/icons/doctors/2.png"; // Example path
import invoiceIcon from "../assets/icons/Pharmacy/2.png"; // Example path
// Import additional icons as needed

const navigationConfig = {
  6: {
    header: [
      {
        name: "Dashboard",
        path: "dashboard",
        permission: "view-dashboard",
        icon: LocalHospitalOutlinedIcon,
      },
      {
        name: "Beneficiary",
        path: "beneficiary",
        permission: "view-beneficiary",
        icon: PersonIcon,
      },
      {
        name: "Eligibility",
        path: "eligibility",
        permission: "view-eligibility",
      },
      {
        name: "Pre-Authorization",
        path: "preauth",
        permission: "view-preauth",
      },
      {
        name: "Claims",
        path: "claims",
        permission: "view-claims",
        menu: [
          {
            name: "Transaction",
            path: "claims",
            icon: FormatListBulletedOutlinedIcon,
          },
          {
            name: "Submissions",
            path: "claims/submit",
            icon: PublishedWithChangesOutlinedIcon,
          },
          {
            name: "Excel Uploads",
            path: "claims/uploads",
            icon: FileUpload,
          },
          {
            name: "History",
            path: "claims/history",
            icon: HistoryOutlined,
          },
        ],
      },
      {
        name: "Payments / Reconciliation",
        path: "payments",
        permission: "view-payments",
      },
      {
        name: "HIS",
        path: "his",

        permission: "view-his",
        // menu: [
        //   {
        //     name: "Patient",
        //     path: "his",
        //     icon: PersonIcon,
        //   },
        // ],
      },
      {
        name: "Receptionist",
        path: "receptionist",
        permission: "view-receptionist",
        menu: [
          {
            name: "Create Patient",
            path: "beneficiary/form",
            icon: AddBoxIcon,
          },
          {
            name: "Create Appointments",
            path: "receptionist/create-appointment",
            icon: EditNoteIcon,
          },
          {
            name: "Invoice",
            path: "receptionist/invoice",
            icon: ReceiptIcon,
          },
        ],
      },
    ],
    sidebar: [
      {
        name: "Dashboard",
        path: "dashboard",
        permission: "view-dashboard",
        icon: LocalHospitalOutlinedIcon,
      },
      {
        name: "Beneficiary",
        path: "beneficiary",
        permission: "view-beneficiary",
        icon: PersonIcon,
        menu: [
          {
            name: "Create Beneficiary",
            path: "beneficiary/form",
          },
        ],
      },
      {
        name: "Eligibility",
        path: "eligibility",
        permission: "view-eligibility",
        icon: FormatListBulletedOutlinedIcon,
      },
      {
        name: "Pre-Authorization",
        path: "preauth",
        permission: "view-preauth",
        icon: ReceiptIcon,
      },
      {
        name: "Claims",
        path: "claims",
        permission: "view-claims",
        icon: PublishedWithChangesOutlinedIcon,
        menu: [
          {
            name: "Transaction",
            path: "claims",
            icon: FormatListBulletedOutlinedIcon,
          },
          {
            name: "Submissions",
            path: "claims/submit",
            icon: PublishedWithChangesOutlinedIcon,
          },
          {
            name: "Excel Uploads",
            path: "claims/uploads",
            icon: FileUpload,
          },
          {
            name: "History",
            path: "claims/history",
            icon: HistoryOutlined,
          },
        ],
      },
      {
        name: "Payments / Reconciliation",
        path: "payments",
        permission: "view-payments",
        icon: payroll,
      },
      {
        name: "HIS",
        path: "his",
        icon: hisIcon,
        permission: "view-his",
        // menu: [
        //   {
        //     name: "Patient",
        //     path: "his",
        //     icon: PersonIcon,
        //   },
        // ],
      },
      {
        name: "Receptionist",
        path: "receptionist",
        permission: "view-receptionist",
        icon: invoiceIcon,
        menu: [
          {
            name: "Create Patient",
            path: "beneficiary/form",
            icon: AddBoxIcon,
          },
          {
            name: "Create Appointments",
            path: "receptionist/create-appointment",
            icon: EditNoteIcon,
          },
          {
            name: "Invoice",
            path: "receptionist/invoice",
            icon: ReceiptIcon,
          },
        ],
      },
    ],
  },
  9: {
    header: [
      {
        name: "D-Dashboard",
        path: "his/ddashboard",
        permission: "view-dashboard",
      },
      {
        name: "EMR",
        path: "his",
        // command: hisLogin,
        permission: "view-his",
      },

      // {
      //   name: "Appointment",
      //   path: "his/appointment",
      // },
    ],
    sidebar: [
      {
        name: "D-Dashboard",
        path: "his/ddashboard",
        permission: "view-dashboard",
        icon: hisIcon,
      },
      {
        name: "EMR",
        path: "his",
        permission: "view-his",
        icon: doctors,
        // createButton: [
        //   {
        //     name: "Create Patient",
        //     path: "his/create-patient",
        //   },
        // ],
        // menu: [
        //   {
        //     name: "Invoice",
        //     path: "his/invoice",
        //     icon: invoiceIcon,
        //   },
        // ],
      },

      // {
      //   name: "Visits",
      //   path: "his/appointments",
      //   icon: createAppointmentIcon,
      // },
    ],
  },
  7: {
    header: [
      {
        name: "R-Dashboard",
        path: "receptionist/rdashboard",
        permission: "view-dashboard",
      },
      {
        name: "Beneficiary",
        path: "receptionist/beneficiary",
        permission: "view-beneficiary",
      },
      {
        name: "Eligibility",
        path: "receptionist/eligibility",
        permission: "view-eligibility",
      },
      {
        name: "Visits",
        path: "receptionist/appointments",
      },
      {
        name: "Billing",
        path: "receptionist/invoice",
      },
    ],
    sidebar: [
      {
        name: "R-Dashboard",
        path: "receptionist/rdashboard",
        permission: "view-dashboard",
        icon: PublishedWithChangesOutlinedIcon,
      },
      {
        name: "Visits",
        path: "receptionist/appointments", // Correct path to appointments
        icon: FormatListBulletedOutlinedIcon,
        createButton: [
          {
            name: "Create Visits",
            path: "/receptionist/create-visit",
          },
        ],
        // menu: [{ name: "Visit Report" }],
      },
      {
        name: "Beneficiary",
        path: "receptionist/beneficiary",
        permission: "view-beneficiary",
        icon: PersonIcon,
        createButton: [
          {
            name: "Create Beneficaries",
            path: "/beneficiary/form",
          },
        ],
        // menu: [{ name: "Beneficiary Report" }],
      },
      {
        name: "Eligibility",
        path: "receptionist/eligibility",
        permission: "view-eligibility",
        icon: LocalHospitalOutlinedIcon,
        // createButton: [
        //   {
        //     name: "Create Eligibility",
        //     path: "receptionist/eligibility/details",
        //     permission: "view-eligibility",
        //   }, // Correct create button path
        // ],
        // menu: [{ name: "eligi Report" }],
      },

      {
        name: "Billing",
        path: "receptionist/invoice", // Correct path to billing route
        icon: AddBoxIcon,
        // createButton: [
        //   {
        //     name: "Create Invoice",
        //     path: "receptionist/invoice", // Correct path for invoice creation
        //   },
        // ],
        // menu: [{ name: "invoice" }],
      },
      {
        name: "Appointment",
        path: "receptionist/appointment", // Correct path to billing route
        icon: AppointmentIcon,
      },
    ],
  },

  // 7: {
  //   header: [
  //     {
  //       name: "Lab-Dashboard",
  //       path: "labs/ldashboard", // Matches the route for the LabDashboard
  //       permission: "view-dashboard",
  //     },
  //     {
  //       name: "Blood",
  //       path: "labs/blood", // Correct path for the Blood section
  //     },
  //     {
  //       name: "Doctor",
  //       path: "labs/doctor", // Matches the path for LabDashboard route
  //     },
  //     {
  //       name: "Diagnosis",
  //       path: "labs/diagnosis", // Matches the path for LabDashboard route
  //     },
  //     {
  //       name: "Live meetings",
  //       path: "labs/live-meetings", // Matches the path for LabDashboard route
  //     },
  //     {
  //       name: "Payrolls",
  //       path: "labs/payroll", // Matches the path for LabDashboard route
  //     },
  //     {
  //       name: "Medicines",
  //       path: "labs/medicines", // Matches the path for LabDashboard route
  //     },
  //     {
  //       name: "Notice Board",
  //       path: "labs/notice-board", // Matches the path for LabDashboard route
  //     },
  //     {
  //       name: "Pathology Tests",
  //       path: "labs/pathology-test", // Matches the path for LabDashboard route
  //     },
  //     {
  //       name: "Radiology Tests",
  //       path: "labs/radiology-test", // Matches the path for LabDashboard route
  //     },
  //     {
  //       name: "Lab Forms",
  //       path: "labs/lab-form", // Correct path for the Blood section
  //     },
  //   ],
  //   sidebar: [
  //     {
  //       name: "Lab-Dashboard",
  //       path: "labs/ldashboard", // Matches the path for LabDashboard route
  //       permission: "view-dashboard",
  //       icon: lab,
  //     },
  //     {
  //       name: "Blood",
  //       path: "labs/blood", // Matches the Blood base route
  //       icon: blood,
  //       createButton: [
  //         // Placeholder for potential create actions
  //       ],
  //       menu: [
  //         {
  //           name: "Blood banks",
  //           path: "labs/blood", // Updated path to the default Blood Bank page
  //         },
  //         {
  //           name: "Blood Donors",
  //           path: "labs/blood/blood-donar", // Matches the Blood Donor route
  //         },
  //         {
  //           name: "Blood Donations",
  //           path: "labs/blood/blood-donations", // Matches the Blood Donations route
  //         },
  //         {
  //           name: "Blood Issues",
  //           path: "labs/blood/blood-issues", // Matches the Blood Issues route
  //         },
  //       ],
  //     },

  //     {
  //       name: "Doctor",
  //       path: "labs/doctor", // Matches the path for LabDashboard route
  //       icon: labDoctor,
  //       createButton: [
  //         // Placeholder for potential create actions
  //       ],
  //       menu: [],
  //     },
  //     {
  //       name: "Diagnosis",
  //       path: "labs/diagnosis", // Matches the path for LabDashboard route
  //       icon: labDiagnosis,
  //       createButton: [
  //         {
  //           name: "Add Diagnosis",
  //           path: "labs/Diagnosis/add-diagnosis",
  //         },
  //       ],
  //       menu: [
  //         {
  //           name: "Diagnosis Category",
  //           path: "labs/Diagnosis/diagnosis-category", // Updated path to the default Blood Bank page
  //         },
  //         {
  //           name: "Diagnosis Test",
  //           path: "labs/Diagnosis/diagnosis-test", // Matches the Blood Donor route
  //         },
  //       ],
  //     },
  //     {
  //       name: "Live meetings",
  //       path: "labs/live-meetings", // Matches the path for LabDashboard route
  //       icon: meetings,
  //       createButton: [
  //         // Placeholder for potential create actions
  //       ],
  //       menu: [],
  //     },
  //     {
  //       name: "Payrolls",
  //       path: "labs/payroll", // Matches the path for LabDashboard route
  //       icon: payroll,
  //       createButton: [],
  //       menu: [],
  //     },
  //     {
  //       name: "Medicines",
  //       path: "labs/medicines", // Matches the path for LabDashboard route
  //       icon: medicines,
  //       createButton: [
  //         {
  //           name: "Add med",
  //           path: "labs/medicines/add-diagnosis",
  //         },
  //       ],
  //       menu: [
  //         {
  //           name: "Med Category",
  //           path: "labs/medicines/med-category", // Updated path to the default Blood Bank page
  //         },
  //         {
  //           name: "Med Brands",
  //           path: "labs/medicines/med-brands", // Matches the Blood Donor route
  //         },
  //         {
  //           name: "Purchase Med",
  //           path: "labs/medicines/purchase-Med", // Matches the Blood Donor route
  //         },
  //         {
  //           name: "Used Med",
  //           path: "labs/medicines/used-med", // Matches the Blood Donor route
  //         },
  //         {
  //           name: "Med Bills",
  //           path: "labs/medicines/med-bills", // Matches the Blood Donor route
  //         },
  //       ],
  //     },
  //     {
  //       name: "Notice Board",
  //       path: "labs/notice-board", // Matches the path for LabDashboard route
  //       icon: noticeboard,
  //       createButton: [],
  //       menu: [],
  //     },
  //     {
  //       name: "Pathology Tests",
  //       path: "labs/pathology-test",
  //       icon: pathology,
  //     },
  //     {
  //       name: "Lab Forms",
  //       path: "labs/lab-form",
  //       icon: noticeboard,
  //       // Correct path for the Blood section
  //     },
  //   ],
  // },
  // permission: "view-his",
  // menu: [
  //   {
  //     name: "Patient",
  //     path: "his",
  //     icon: PersonIcon,
  //   },
  // ],
  //   },
  //   {
  //     name: "Receptionist",
  //     path: "receptionist",
  //     permission: "view-receptionist",
  //     menu: [
  //       {
  //         name: "Create Patient",
  //         path: "beneficiary/form",
  //         icon: AddBoxIcon,
  //       },
  //       {
  //         name: "Create Visits",
  //         path: "receptionist/create-visit",
  //         icon: EditNoteIcon,
  //       },
  //       {
  //         name: "Invoice",
  //         path: "receptionist/invoice",
  //         icon: ReceiptIcon,
  //       },
  //     ],
  //   },
  // ],
  // 9: [
  //   {
  //     name: "HIS",
  //     path: "his",
  //     // command: hisLogin,
  //     permission: "view-his",
  //   },
  // ],
  // 7: [
  //   {
  //     name: "Beneficiary",
  //     path: "beneficiary",
  //     permission: "view-beneficiary",
  //   },
  //   {
  //     name: "Eligibility",
  //     path: "eligibility",
  //     permission: "view-eligibility",
  //   },
  //   // {
  //   //   name: "Receptionist",
  //   //   path: "receptionist",
  //   //   permission: "view-receptionist",
  //   //   menu: [
  //   //     {
  //   //       name: "Create Patient",
  //   //       path: "beneficiary/form",
  //   //       icon: AddBoxIcon,
  //   //     },
  //   //     {
  //   //       name: "Create Appointments",
  //   //       path: "receptionist/create-appointment",
  //   //       icon: EditNoteIcon,
  //   //     },
  //   //     {
  //   //       name: "Invoice",
  //   //       path: "receptionist/invoice",
  //   //       icon: ReceiptIcon,
  //   //     },
  //   //   ],
  //   // },
  //   {
  //     name: " Visits",
  //     path: "receptionist/visits",
  //   },
  //   {
  //     name: "Billing",
  //     path: "receptionist/invoice",
  //   },
  // ],

  //       createButton: [
  //         {
  //           name: "Add PathologyTest",
  //           path: "labs/pathology-test/add-pathalogy",
  //         },
  //       ],
  //       menu: [], // Matches the path for LabDashboard route
  //     },
  //     {
  //       name: "Rdiology Tests",
  //       path: "labs/radiology-test",
  //       icon: radiology,

  //       createButton: [
  //         {
  //           name: "Add RadiologyTest",
  //           path: "labs/radiology-test/add-radiologytest",
  //         },
  //       ],
  //       menu: [], // Matches the path for LabDashboard route
  //     },
  //   ],
  // },
};

// Function to get navigation based on roleId stored in localStorage
export const getNavigation = () => {
  const user = localStorage.getItem("user");
  const roleId = user ? JSON.parse(user).RoleId : null;

  // Return navigation config based on roleId or empty if none
  return roleId && navigationConfig[roleId]
    ? {
        header: navigationConfig[roleId].header,
        sidebar: navigationConfig[roleId].sidebar,
      }
    : { header: [], sidebar: [] };
};

// Export a function that returns the current navigation
export const navigation = () => getNavigation();

// Function to get sidebar navigation
export const getSidebarNavigation = () => {
  const { sidebar } = getNavigation(); // Destructure sidebar from getNavigation

  if (!sidebar || !sidebar.length) {
    console.log("Sidebar Navigation is empty");
    return [];
  }

  // If there are createButton arrays within the sidebar items, flatten them
  const createButtons = sidebar
    .flatMap((item) => item.createButton || []) // Flatten and filter out undefined
    .map((createItem) => ({ name: createItem.name, path: createItem.path })); // Map name and path

  console.log("Sidebar Create Buttons:", createButtons);

  return { sidebar, createButtons };
};

// const user = localStorage.getItem("user");
// const role_id = user && JSON.parse(user).RoleId;

// export const navigation = navigationConfig[role_id && role_id];

// if (role_id && role_id === 6) {
//   navigation.push(
//     {
//       name: "Dashboard",
//       path: "dashboard",
//       permission: "view-dashboard",
//     },
//     {
//       name: "Beneficiary",
//       path: "beneficiary",
//       permission: "view-beneficiary",
//     },
//     {
//       name: "Eligibility",
//       path: "eligibility",
//       permission: "view-eligibility",
//     },
//     // {
//     //   name: "Providers",
//     //   path: "providers",
//     //   permission: "view-providers",
//     // },
//     {
//       name: "Pre-Authorization",
//       path: "preauth",
//       permission: "view-preauth",
//     },
//     {
//       name: "Claims",
//       path: "claims",
//       permission: "view-claims",
//       menu: [
//         {
//           name: "Transaction",
//           path: "claims",
//           icon: FormatListBulletedOutlinedIcon,
//         },
//         {
//           name: "Submissions",
//           path: "claims/submit",
//           icon: PublishedWithChangesOutlinedIcon,
//         },
//         {
//           name: "Excel Uploads",
//           path: "claims/uploads",
//           icon: FileUpload,
//         },
//         {
//           name: "History",
//           path: "claims/history",
//           icon: HistoryOutlined,
//         },
//       ],
//     },
//     {
//       name: "Payments / Reconciliation",
//       path: "payments",
//       permission: "view-payments",
//     },
//     {
//       name: "HIS",
//       path: "his",
//       command: () => {
//         hisLogin();
//       },
//       permission: "view-his",
//       menu: [
//         {
//           name: "Patient",
//           path: "his/patient",
//           icon: PersonIcon,
//         },
//       ],
//     },
//     {
//       name: "ERP",
//       path: "erp",
//       command: () => {
//         emrLogin();
//       },
//       permission: "view-erp",
//       menu: [
//         {
//           name: "Invoice",
//           path: "erp/invoice",
//           icon: DescriptionOutlinedIcon,
//         },
//         {
//           name: "Report",
//           path: "erp/report",
//           icon: ReceiptLongOutlinedIcon,
//         },
//       ],
//     }
//   );
// }

// else if (role_id && role_id === 8) {
//   navigation.push({
//     name: "ERP",
//     path: "erp",
//     command: () => {
//       emrLogin();
//     },
//     permission: "view-erp",
//     menu: [
//       {
//         name: "Invoice",
//         path: "erp/invoice",
//         icon: DescriptionOutlinedIcon,
//       },
//       {
//         name: "Report",
//         path: "erp/report",
//         icon: ReceiptLongOutlinedIcon,
//       },
//     ],
//   });
// }

// else if (role_id && role_id === 9) {
//   navigation.push({
//     name: "HIS",
//     path: "his",
//     command: () => {
//       hisLogin();
//     },
//     permission: "view-his",
//     menu: [
//       {
//         name: "Patient",
//         path: "his/patient",
//         icon: PersonIcon,
//       },
//     ],
//   });
// }

// else if (role_id && role_id === 7) {
//   navigation.push(
//     // {
//     //   name: "Dashboard",
//     //   path: "dashboard",
//     //   permission: "view-dashboard",
//     // },
//     {
//       name: "Beneficiary",
//       path: "beneficiary",
//       permission: "view-beneficiary",
//     },
//     {
//       name: "Eligibility",
//       path: "eligibility",
//       permission: "view-eligibility",
//     },
//     // {
//     //   name: "Providers",
//     //   path: "providers",
//     //   permission: "view-providers",
//     // },
//     // {
//     //   name: "Pre-Authorization",
//     //   path: "preauth",
//     //   permission: "view-preauth",
//     // },
//     // {
//     //   name: "Claims",
//     //   path: "claims",
//     //   permission: "view-claims",
//     //   menu: [
//     //     {
//     //       name: "Transaction",
//     //       path: "claims",
//     //       icon: FormatListBulletedOutlinedIcon,
//     //     },
//     //     {
//     //       name: "Submissions",
//     //       path: "claims/submit",
//     //       icon: PublishedWithChangesOutlinedIcon,
//     //     },
//     //     {
//     //       name: "Excel Uploads",
//     //       path: "claims/uploads",
//     //       icon: FileUpload,
//     //     },
//     //     {
//     //       name: "History",
//     //       path: "claims/history",
//     //       icon: HistoryOutlined,
//     //     },
//     //   ],
//     // },
//     // {
//     //   name: "Payments / Reconciliation",
//     //   path: "payments",
//     //   permission: "view-payments",
//     // },
//     // {
//     //   name: "HIS",
//     //   path: "his",
//     //   command: () => {
//     //     hisLogin();
//     //   },
//     //   permission: "view-his",
//     //   menu: [
//     //     {
//     //       name: "Patient",
//     //       path: "his/patient",
//     //       icon: PersonIcon,
//     //     },
//     //   ],
//     // },
//     {
//       name: "ERP",
//       path: "erp",
//       command: () => {
//         emrLogin();
//       },
//       permission: "view-erp",
//       menu: [
//         {
//           name: "Invoice",
//           path: "erp/invoice",
//           icon: DescriptionOutlinedIcon,
//         },
//         // {
//         //   name: "Report",
//         //   path: "erp/report",
//         //   icon: ReceiptLongOutlinedIcon,
//         // },
//       ],
//     }
//   );
// }
