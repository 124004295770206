import { Bar, Doughnut } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
} from "chart.js";
import {
  FaUserCheck,
  FaCheckCircle,
  FaUserFriends,
  FaTimesCircle,
} from "react-icons/fa";
import HorizontalBarChart from "./Charts/HorizontalBarChart";
import PatientsChart from "./Charts/PatientChart";

const carsdata = [
  {
    title: "Doctors",
    count: 52,
    icon: <FaUserCheck />,
    color: "bg-blue-300",
  },
  {
    title: "Accountants",
    count: 83,
    icon: <FaCheckCircle />,
    color: "bg-green-300",
  },
  {
    title: "Pharmacy",
    count: 68,
    icon: <FaUserFriends />,
    color: "bg-yellow-300",
  },
  {
    title: "HR",
    count: 21,
    icon: <FaTimesCircle />,
    color: "bg-red-300",
  },
];

// Register required Chart.js components
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement
);

const PatientSatisfaction = () => {
  const data = {
    labels: ["Beneficiaries", "Eligibles", "Eligibilities", "Not Actives"],
    datasets: [
      {
        data: [35, 40, 20, 5],
        backgroundColor: ["#FF6384", "#36A2EB", "#FFCE56", "#AA65B2"],
        hoverBackgroundColor: ["#FF6384", "#36A2EB", "#FFCE56", "#AA65B2"],
        borderWidth: 1,
      },
    ],
  };

  const options = {
    cutout: "70%", // Adjust the cutout size to change the line size
  };

  return (
    <div className="bg-white p-4 rounded-lg shadow-md">
      <h3 className="text-xl font-semibold mb-4">Patient Satisfaction</h3>
      <div className="flex flex-row justify-between items-center">
        <div className="flex-1">
          <Doughnut data={data} options={options} />
        </div>
        <div className="flex flex-col justify-center ml-4">
          {carsdata.map((item, index) => (
            <div key={index} className="flex items-center mb-2">
              <div className={`p-2 rounded-full ${item.color} mr-2`}>
                {item.icon}
              </div>
              <div className="text-sm">
                {item.title}: {item.count}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

const BarChart = () => {
  const data = {
    labels: [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ],
    datasets: [
      {
        label: "Outpatients",
        backgroundColor: "rgba(75, 192, 192, 0.6)",
        borderColor: "rgba(75, 192, 192, 1)",
        borderWidth: 1,
        hoverBackgroundColor: "rgba(75, 192, 192, 0.8)",
        hoverBorderColor: "rgba(75, 192, 192, 1)",
        data: [650, 590, 800, 810, 560, 550, 400, 600, 650, 700, 800, 900],
      },
      {
        label: "Inpatients",
        backgroundColor: "rgba(153, 102, 255, 0.6)",
        borderColor: "rgba(153, 102, 255, 1)",
        borderWidth: 1,
        hoverBackgroundColor: "rgba(153, 102, 255, 0.8)",
        hoverBorderColor: "rgba(153, 102, 255, 1)",
        data: [280, 480, 400, 190, 860, 270, 900, 500, 650, 700, 750, 800],
      },
    ],
  };

  return (
    <div className="bg-white p-4 w-full rounded-lg shadow-md">
      <h3 className="text-xl font-semibold mb-4">Outpatients vs Inpatients</h3>
      <Bar data={data} />
    </div>
  );
};

const WaitingTime = () => {
  return (
    <div className="bg-white h-80 p-4 rounded-lg shadow-md">
      <h3 className="text-xl font-semibold mb-4">Average Waiting Time</h3>
      <HorizontalBarChart />
    </div>
  );
};

const PatientsByDivision = () => (
  <div className="bg-white p-4 rounded-lg shadow-md">
    <h3 className="text-xl font-semibold mb-4">Patients by Division</h3>
    <table className="w-full text-left">
      <thead>
        <tr>
          <th className="border-b-2 border-gray-300 p-2">Division</th>
          <th className="border-b-2 border-gray-300 p-2">Inpatient</th>
          <th className="border-b-2 border-gray-300 p-2">Outpatient</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td className="border-b border-gray-200 p-2">Dermatology</td>
          <td className="border-b border-gray-200 p-2">40</td>
          <td className="border-b border-gray-200 p-2">90</td>
        </tr>
        <tr>
          <td className="border-b border-gray-200 p-2">Cardiology</td>
          <td className="border-b border-gray-200 p-2">90</td>
          <td className="border-b border-gray-200 p-2">200</td>
        </tr>
        <tr>
          <td className="border-b border-gray-200 p-2">Oncology</td>
          <td className="border-b border-gray-200 p-2">60</td>
          <td className="border-b border-gray-200 p-2">110</td>
        </tr>
        <tr>
          <td className="border-b border-gray-200 p-2">Surgery</td>
          <td className="border-b border-gray-200 p-2">220</td>
          <td className="border-b border-gray-200 p-2">320</td>
        </tr>
        <tr>
          <td className="border-b border-gray-200 p-2">Gynecology</td>
          <td className="border-b border-gray-200 p-2">150</td>
          <td className="border-b border-gray-200 p-2">250</td>
        </tr>
      </tbody>
    </table>
  </div>
);

const AdminDash = () => {
  return (
    <div className="grid gap-8">
      {/* First Row */}
      <div className="grid grid-cols-1 md:grid-cols-4 gap-4">
        {carsdata.map((item, index) => (
          <div
            key={index}
            className={`${item.color} p-4 rounded-sm shadow-lg text-center`}
          >
            <div className="text-2xl font-semibold text-gray-900 mb-1 flex items-center justify-center">
              <span className="mr-2">{item.icon}</span>
              {item.title}
            </div>
            <div className="text-3xl font-bold text-gray-600">{item.count}</div>
          </div>
        ))}
      </div>

      {/* Second Row */}
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
        <div className="h-full">
          <PatientsChart />
        </div>
        <div className="h-full">
          <PatientSatisfaction />
        </div>
      </div>

      {/* Third Row */}
      <div>
        <BarChart />
      </div>

      {/* Fourth Row */}
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
        <div>
          <WaitingTime />
        </div>
        <div>
          <PatientsByDivision />
        </div>
      </div>
    </div>
  );
};

export default AdminDash;
