import React from "react";
import { Outlet } from "react-router-dom";
import EligibilityHeader from "./components/EligibilityHeader";

function Eligibility(props) {
  return (
    <>
      <Outlet />
    </>
  );
}

export default Eligibility;
