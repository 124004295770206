import { useState } from "react";
import BloodDonorModal from "../../../components/shared/BloodDonorModal";
import Table from "../../../components/shared/Table"; // Import the Table component

function BloodDonors() {
  const [BloodDonors, setBloodDonors] = useState([
    {
      name: "Kamran Mehar",
      age: "22",
      gender: "Male",
      blood_group: "A+",
      donation_date: "21st Apr, 2024",
    },
    {
      name: "Abdul Rehman",
      age: "24",
      gender: "Male",
      blood_group: "AB+",
      donation_date: "21st Apr, 2024",
    },
    {
      name: "Abdul Wahid",
      age: "22",
      gender: "Male",
      blood_group: "A+",
      donation_date: "21st Apr, 2024",
    },
    {
      name: "Essa Mehar",
      age: "21",
      gender: "Male",
      blood_group: "B+",
      donation_date: "21st Apr, 2024",
    },
    {
      name: "Amina",
      age: "20",
      gender: "Female",
      blood_group: "O+",
      donation_date: "21st Apr, 2024",
    },
    {
      name: "Hina Aqeel",
      age: "32",
      gender: "Female",
      blood_group: "AB-",
      donation_date: "21st Apr, 2024",
    },
    {
      name: "Nouman",
      age: "22",
      gender: "Male",
      blood_group: "A-",
      donation_date: "21st Apr, 2024",
    },
    {
      name: "Osama Zaheer",
      age: "24",
      gender: "Male",
      blood_group: "AB+",
      donation_date: "21st Apr, 2024",
    },
  ]);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  // Define columns for the table
  const columns = [
    { title: "NAME", dataKey: "name" },
    { title: "AGE", dataKey: "age" },
    { title: "GENDER", dataKey: "gender" },
    { title: "BLOOD GROUP", dataKey: "blood_group" },
    { title: "LAST DONATION DATE", dataKey: "donation_date" },
    { title: "ACTION", dataKey: "action" },
  ];

  return (
    <div className="App">
      <div className="container py-2 mx-auto md:py-8">
        <div className="custom-table-container">
          <Table
            columns={columns}
            data={BloodDonors.map((item, index) => ({
              ...item,
              gender: <button className="table-button">{item.gender}</button>,
              donation_date: (
                <button className="table-button">{item.donation_date}</button>
              ),
              action: (
                <div className="actions">
                  <button onClick={openModal} className="edit-btn">
                    <img src={"/icons/edit.png"} alt="Edit" />
                  </button>
                  <button className="delete-btn">
                    <img
                      src={"/icons/delete.png"}
                      className="ms-1"
                      alt="Delete"
                    />
                  </button>
                  <button className="delete-btn">
                    <img src={"/icons/print.png"} alt="Print" />
                  </button>
                </div>
              ),
            }))}
          />
        </div>
      </div>
      {/* Modal Component */}
      <BloodDonorModal isOpen={isModalOpen} onClose={closeModal} />
    </div>
  );
}

export default BloodDonors;
