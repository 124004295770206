import React from "react";
import { Outlet } from "react-router-dom";

function Beneficiary(props) {
  // location.reload()
  // console.log("Beneficiary component rendered");

  return (
    <>
      <Outlet />
    </>
  );
}

export default Beneficiary;
