import React from 'react';

const AddDiagnosisTests = () => {
    return (
        <div className="p-4 max-w-7xl mx-auto">
            <h1 className="font-bold mb-4 text-xl">New Patient Diagnosis Test</h1>
            <form className="bg-white p-9 border-black shadow-md rounded-3xl space-y-6">
                {/* First Row */}
                <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
                    <div>
                        <label className="text-gray-700 font-bold mb-2">Patient:</label>
                        <select className="w-full px-3 py-2 border border-black focus:outline-none focus:ring focus:border-blue-300">
                            <option value="" style={{ display: "none" }}>Select Patient</option>
                        </select>
                    </div>
                    <div>
                        <label className="text-gray-700 font-bold mb-2">Diagnosis Category:</label>
                        <select className="w-full px-3 py-2 border border-black focus:outline-none focus:ring focus:border-blue-300">
                            <option value="" style={{ display: "none" }}>Select Diagnosis Category</option>
                        </select>
                    </div>
                    <div>
                        <label className="text-gray-700 font-bold mb-2">Report Number:</label>
                        <input
                            type="text"
                            placeholder="Report Number"
                            value="0GU7GUTB"
                            readOnly
                            className="w-full px-3 py-2 border border-black focus:outline-none focus:ring focus:border-blue-300"
                        />
                    </div>
                </div>

                {/* Second Row */}
                <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
                    <div>
                        <label className="text-gray-700 font-bold mb-2">Age:</label>
                        <input
                            type="text"
                            placeholder="Age"
                            className="w-full px-3 py-2 border border-black focus:outline-none focus:ring focus:border-blue-300"
                        />
                    </div>
                    <div>
                        <label className="text-gray-700 font-bold mb-2">Height:</label>
                        <input
                            type="text"
                            placeholder="Height"
                            className="w-full px-3 py-2 border border-black focus:outline-none focus:ring focus:border-blue-300"
                        />
                    </div>
                    <div>
                        <label className="text-gray-700 font-bold mb-2">Weight:</label>
                        <input
                            type="text"
                            placeholder="Weight"
                            className="w-full px-3 py-2 border border-black focus:outline-none focus:ring focus:border-blue-300"
                        />
                    </div>
                </div>

                {/* Third Row */}
                <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
                    <div>
                        <label className="text-gray-700 font-bold mb-2">Average Glucose:</label>
                        <input
                            type="text"
                            placeholder="Average Glucose"
                            className="w-full px-3 py-2 border border-black focus:outline-none focus:ring focus:border-blue-300"
                        />
                    </div>
                    <div>
                        <label className="text-gray-700 font-bold mb-2">Fasting Blood Sugar:</label>
                        <input
                            type="text"
                            placeholder="Fasting Blood Sugar"
                            className="w-full px-3 py-2 border border-black focus:outline-none focus:ring focus:border-blue-300"
                        />
                    </div>
                    <div>
                        <label className="text-gray-700 font-bold mb-2">Urine Sugar:</label>
                        <input
                            type="text"
                            placeholder="Urine Sugar"
                            className="w-full px-3 py-2 border border-black focus:outline-none focus:ring focus:border-blue-300"
                        />
                    </div>
                </div>

                {/* Fourth Row */}
                <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
                    <div>
                        <label className="text-gray-700 font-bold mb-2">Blood Pressure:</label>
                        <input
                            type="text"
                            placeholder="Blood Pressure"
                            className="w-full px-3 py-2 border border-black focus:outline-none focus:ring focus:border-blue-300"
                        />
                    </div>
                    <div>
                        <label className="text-gray-700 font-bold mb-2">Diabetes:</label>
                        <input
                            type="text"
                            placeholder="Diabetes"
                            className="w-full px-3 py-2 border border-black focus:outline-none focus:ring focus:border-blue-300"
                        />
                    </div>
                    <div>
                        <label className="text-gray-700 font-bold mb-2">Cholesterol:</label>
                        <input
                            type="text"
                            placeholder="Cholesterol"
                            className="w-full px-3 py-2 border border-black focus:outline-none focus:ring focus:border-blue-300"
                        />
                    </div>
                </div>

                {/* Diagnosis Property Row */}
                <div>
                    <label className="text-gray-700 font-bold mb-2">Add Other Diagnosis Property:</label>
                    <div className="flex flex-col md:flex-row md:space-x-4 space-y-4 md:space-y-0">
                        <input
                            type="text"
                            placeholder="Diagnosis Property Name"
                            className="flex-1 px-3 py-2 border border-black focus:outline-none focus:ring focus:border-blue-300"
                        />
                        <input
                            type="text"
                            placeholder="Diagnosis Property Value"
                            className="flex-1 px-3 py-2 border border-black focus:outline-none focus:ring focus:border-blue-300"
                        />
                        <button className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-700">
                            Add
                        </button>
                    </div>
                </div>

                {/* Save, Cancel, Back Buttons */}
                <div className="flex justify-end space-x-4 mt-8">
                    <button
                        type="submit"
                        className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded"
                    >
                        Save
                    </button>
                    <button
                        type="button"
                        className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded"
                    >
                        Cancel
                    </button>
                    <button
                        type="button"
                        className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded"
                    >
                        Back
                    </button>
                </div>
            </form>
        </div>
    );
};

export default AddDiagnosisTests;
