import React, { useEffect, useState } from "react";
import _ from "lodash";
import { useLocation, useNavigate } from "react-router-dom";
import AddBeneficiaryPersonalDataForm from "./components/AddBeneficiaryPersonalDataForm";
import PatientDetailForm from "./components/PatientDetailForm";
import AppModal from "../../components/AppModal";
import { getPatientByID } from "../HIS/components/ApiMethods";
import toast from "react-hot-toast";

function BeneficiaryForm({}) {
  const navigate = useNavigate();
  const [chiReqRecord, setChiReqRecord] = useState(null);

  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const { patientData } = location.state || {};

  // const [patientData, setPatientData] = useState(null);
  // useEffect(() => {
  //   if (patientId) {
  //     const fetchPatientData = async () => {
  //       try {
  //         setLoading(true);
  //         const fetchedPatientData = await getPatientByID(setLoading, patientId);
  //         setPatientData(fetchedPatientData?.data);
  //       } catch (error) {
  //         console.error("Error fetching patient data:", error);
  //         toast.error("Error fetching patient data");
  //       } finally {
  //         setLoading(false);
  //       }
  //     };

  //     fetchPatientData();
  //   }
  // }, [patientId]);

  return (
    <div className="superGit_container">
      <div class="benefeciary-create">
        <PatientDetailForm setChiReqRecord={setChiReqRecord} item={patientData} />
        <AddBeneficiaryPersonalDataForm
          item={patientData}
          chiReqRecord={chiReqRecord}
          setChiReqRecord={setChiReqRecord}
        />
      </div>
      <AppModal isOpen={loading} />
    </div>
  );
}

export default BeneficiaryForm;
