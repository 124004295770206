import { useField } from "formik";
import React from "react";

export default function SharedInput({
  name,
  label,
  placeholder,
  type,
  className,
  onChange,
  ...props
}) {
  const [field, meta, helpers] = useField(name);

  const handleChange = (e) => {
    helpers.setValue(e.target.value);

    if (onChange) {
      onChange(e);
    }
  };

  return (
    <div className="flex gap-1 flex-col">
      <label className="text-sm">{label}</label>
      <input
        placeholder={placeholder}
        autoFocus
        type={type || "text"}
        id={name}
        className={`w-full ${className} rounded-md`}
        {...props}
        {...field}
        onChange={handleChange}
      />
      {meta.error && meta.touched ? (
        <p className="max-w-2xl text-sm leading-6 text-red-500">{meta.error}</p>
      ) : null}
    </div>
  );
}
