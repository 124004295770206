import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import Sidebar from "./SideBar";
import Navbar from "./NavBar";

export default function RootLayout({ children, createButton, sidebar }) {
  const location = useLocation();

  // Tooltip state to control what gets shown globally
  const [tooltipContent, setTooltipContent] = useState(null);

  // Check if the current path is the landing page
  const isLandingPage = location.pathname === "/";

  return (
    <div
      className={`flex h-screen overflow-hidden ${
        isLandingPage
          ? ""
          : "backgroundnew bg-gradient-to-r from-gray-200 to-gray-300"
      }`}
    >
      {/* Render Sidebar only if not on the Landing Page */}
      {!isLandingPage && (
        <Sidebar
          sidebar={sidebar}
          setTooltipContent={setTooltipContent} // Pass function to set tooltip
          className="w-20 sm:w-24 md:w-28 lg:w-32 xl:w-40"
        />
      )}

      {/* Main content area */}
      <div className="flex flex-col flex-1 overflow-x-auto">
        {/* Render Navbar only if not on the Landing Page */}
        {!isLandingPage && <Navbar createButton={createButton} />}
        <main
          className={`${
            isLandingPage ? "" : "flex-grow p-2 m-1 md:p-3 overflow-y-auto"
          }`}
        >
          {children}
        </main>
      </div>

      {/* Global tooltip container */}
      {tooltipContent && (
        <div
          className="absolute pointer-events-none"
          style={{
            top: tooltipContent.y,
            left: tooltipContent.x,
            zIndex: 1000, // Ensure it's on top of everything
            padding: "5px 10px",
            backgroundColor: "#373d45",
            color: "#fff",
            borderRadius: "4px",
            fontSize: "14px",
            whiteSpace: "nowrap",
          }}
        >
          {tooltipContent.text}
        </div>
      )}
    </div>
  );
}
