import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const LabTestsLineChart = () => {
  const data = {
    labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug"],
    datasets: [
      {
        label: "Lab Test Trends",
        data: [15, 25, 10, 30, 20, 35, 45, 50], // Adjusted data for lab tests
        fill: true,
        backgroundColor: "rgba(54, 162, 235, 0.2)", // Updated background color
        borderColor: "rgba(54, 162, 235, 1)", // Updated border color
        borderWidth: 3, // Thicker line
        tension: 0.6, // Increased tension for a wavier line
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: true,
      },
      tooltip: {
        callbacks: {
          label: (context) => `${context.dataset.label}: ${context.raw}`, // Custom tooltip
        },
      },
    },
    scales: {
      x: {
        title: {
          display: true,
          text: "Months", // X-axis label
        },
      },
      y: {
        title: {
          display: true,
          text: "Number of Tests", // Y-axis label
        },
        beginAtZero: true,
      },
    },
  };

  return (
    <div className="bg-white p-4 rounded-lg shadow-md h-full w-full">
      <h3 className="text-xl font-semibold mb-2">Lab Test Trends</h3>
      <Line data={data} options={options} />
    </div>
  );
};

export default LabTestsLineChart;
