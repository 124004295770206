import { Bar } from "react-chartjs-2";

const AvgCostsBarChart = () => {
  const data = {
    labels: ["Dermatology", "Cardiology", "Oncology", "Surgery"],
    datasets: [
      {
        label: "Average Costs",
        data: [200, 400, 350, 300],
        backgroundColor: ["#6C5B7B", "#C06C84", "#F67280", "#F8B400"],
        borderColor: ["#6C5B7B", "#C06C84", "#F67280", "#F8B400"],
        borderWidth: 1,
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false, // This allows the chart to adjust its dimensions
    scales: {
      y: {
        beginAtZero: true, // Ensure Y axis starts at 0
        ticks: {
          stepSize: 50, // Adjust this as per your data
        },
      },
    },
    plugins: {
      legend: {
        position: "top",
      },
      tooltip: {
        enabled: true, // Ensure tooltips are visible
      },
    },
    layout: {
      padding: {
        left: 10,
        right: 10,
        top: 20,
        bottom: 10,
      },
    },
  };

  return (
    <div
      className="bg-white p-4 rounded-lg shadow-md"
      style={{ height: "400px" }}
    >
      <h3 className="text-xl font-semibold mb-4">
        Average Costs by Department
      </h3>
      <div style={{ height: "100%", width: "100%" }}>
        <Bar data={data} options={options} />
      </div>
    </div>
  );
};

export default AvgCostsBarChart;
