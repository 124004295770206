import { useState } from "react";
import Table from "../../../components/shared/Table"; // Importing the Table component
import BloodDonationModal from "../../../components/shared/BloodDonationModal";

function BloodDonations() {
  const [BloodDonations, setBloodDonations] = useState([
    {
      donar_name: "Kamran Mehar",
      bags: "1",
    },
    {
      donar_name: "Abdul Rehman",
      bags: "2",
    },
    {
      donar_name: "Abdul Wahid",
      bags: "1",
    },
    {
      donar_name: "Essa Mehar",
      bags: "1",
    },
    {
      donar_name: "Amina",
      bags: "1",
    },
    {
      donar_name: "Hina Aqeel",
      bags: "2",
    },
    {
      donar_name: "Nouman",
      bags: "1",
    },
    {
      donar_name: "Osama Zaheer",
      bags: "2",
    },
  ]);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  // Define columns for the Table component
  const columns = [
    { title: "DONOR NAME", dataKey: "donar_name" },
    { title: "BAGS", dataKey: "bags" },
    { title: "ACTION", dataKey: "actions" },
  ];

  return (
    <div className="App">
      <div className="container mx-auto md:py-3">
        <div className="custom-table-container">
          <Table
            columns={columns}
            data={BloodDonations.map((item, index) => ({
              ...item,
              actions: (
                <div className="actions">
                  <button onClick={openModal} className="edit-btn">
                    <img src={"/icons/edit.png"} alt="Edit" />
                  </button>
                  <button className="delete-btn">
                    <img
                      src={"/icons/delete.png"}
                      className="ms-2"
                      alt="Delete"
                    />
                  </button>
                </div>
              ),
            }))}
          />
        </div>
      </div>
      {/* Modal Component */}
      <BloodDonationModal isOpen={isModalOpen} onClose={closeModal} />
    </div>
  );
}

export default BloodDonations;
