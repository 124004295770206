import { useState } from "react";
import ToggleSwitch from "../../../components/shared/ToggleSwitch";
import Table from "../../../components/shared/Table";

const Diagnosis = () => {
  const [reports, setReports] = useState([
    {
      reportType: "dd",
      opdNo: "6YPARFIS",
      reportTime: "4:50 PM",
      reportDate: "27th Jul 2024",
      document: "N/A",
      description: "N/A",
      reportGenerated: true,
    },
    {
      reportType: "test",
      opdNo: "6YPARFIS",
      reportTime: "4:32 PM",
      reportDate: "27th Jul 2024",
      document: "N/A",
      description: "N/A",
      reportGenerated: false,
    },
    {
      reportType: "Diagno",
      opdNo: "6YPARFIS",
      reportTime: "7:11 AM",
      reportDate: "25th Apr 2024",
      document: "N/A",
      description: "N/A",
      reportGenerated: true,
    },
    {
      reportType: "test dia",
      opdNo: "6YPARFIS",
      reportTime: "8:33 AM",
      reportDate: "21st Apr 2024",
      document: "N/A",
      description: "N/A",
      reportGenerated: false,
    },
    {
      reportType: "oi",
      opdNo: "6YPARFIS",
      reportTime: "1:22 PM",
      reportDate: "21st Apr 2024",
      document: "N/A",
      description: "N/A",
      reportGenerated: true,
    },
    {
      reportType: "lljo",
      opdNo: "6YPARFIS",
      reportTime: "10:32 PM",
      reportDate: "17th Apr 2024",
      document: "N/A",
      description: "N/A",
      reportGenerated: false,
    },
    {
      reportType: "test de palu",
      opdNo: "6YPARFIS",
      reportTime: "8:55 AM",
      reportDate: "11th Apr 2024",
      document: "N/A",
      description: "N/A",
      reportGenerated: true,
    },
    {
      reportType: "grossse",
      opdNo: "6YPARFIS",
      reportTime: "8:55 AM",
      reportDate: "11st Apr 2024",
      document: "N/A",
      description: "N/A",
      reportGenerated: false,
    },
    {
      reportType: "OPT",
      opdNo: "6YPARFIS",
      reportTime: "1:09 AM",
      reportDate: "11th Apr 2024",
      document: "N/A",
      description: "N/A",
      reportGenerated: false,
    },
    {
      reportType: "fever",
      opdNo: "6YPARFIS",
      reportTime: "5:13 AM",
      reportDate: "1st Mar 2024",
      document: "N/A",
      description: "N/A",
      reportGenerated: false,
    },
    {
      reportType: "OPT",
      opdNo: "6YPARFIS",
      reportTime: "3:13 PM",
      reportDate: "27th Feb 2024",
      document: "N/A",
      description: "N/A",
      reportGenerated: false,
    },
    {
      reportType: "test",
      opdNo: "6YPARFIS",
      reportTime: "3:13 PM",
      reportDate: "27th Feb 2024",
      document: "N/A",
      description: "N/A",
      reportGenerated: false,
    },
    {
      reportType: "Diagno",
      opdNo: "6YPARFIS",
      reportTime: "3:13 PM",
      reportDate: "27th Feb 2024",
      document: "N/A",
      description: "N/A",
      reportGenerated: false,
    },
    {
      reportType: "test dia",
      opdNo: "6YPARFIS",
      reportTime: "3:13 PM",
      reportDate: "27th Feb 2024",
      document: "N/A",
      description: "N/A",
      reportGenerated: false,
    },
    {
      reportType: "fever",
      opdNo: "6YPARFIS",
      reportTime: "3:13 PM",
      reportDate: "27th Feb 2024",
      document: "N/A",
      description: "N/A",
      reportGenerated: false,
    },
    // Add more dummy data as needed
  ]);

  const handleReportGeneratedChange = (report) => {
    setReports(
      reports.map((r) =>
        r.opdNo === report.opdNo && r.reportType === report.reportType
          ? { ...r, reportGenerated: !r.reportGenerated }
          : r
      )
    );
  };

  // Define the columns for the table
  const columns = [
    {
      title: "REPORT TYPE",
      dataKey: "reportType",
    },
    {
      title: "OPD NO",
      dataKey: "opdNo",
      Cell: ({ row }) => (
        <button className="table-button">{row.original.opdNo}</button>
      ),
    },
    {
      title: "REPORT DATE",
      dataKey: "reportDate",
      Cell: ({ row }) => (
        <div>
          <div className="text-sm">{row.original.reportTime}</div>
          <div className="text-sm">{row.original.reportDate}</div>
        </div>
      ),
    },
    {
      title: "DOCUMENT",
      dataKey: "document",
    },
    {
      title: "DESCRIPTION",
      dataKey: "description",
    },
    {
      title: "REPORT GENERATED",
      dataKey: "reportGenerated",
      Cell: ({ row }) => (
        <label className="switch flex justify-center">
          <ToggleSwitch
            checked={row.original.reportGenerated}
            onChange={() => handleReportGeneratedChange(row.original)}
          />
        </label>
      ),
    },
  ];

  // Format the data for the table
  const formattedReports = reports.map((item) => ({
    ...item,
    reportDate: (
      <div>
        <div className="text-sm">{item.reportTime}</div>
        <div className="text-sm">{item.reportDate}</div>
      </div>
    ),
    opdNo: <button className="table-button">{item.opdNo}</button>,
    reportGenerated: (
      <label className="switch flex justify-center">
        <ToggleSwitch
          checked={item.reportGenerated}
          onChange={() => handleReportGeneratedChange(item)}
        />
      </label>
    ),
  }));

  return (
    <div className="App">
      <div className="container py-2 mx-auto md:py-8">
        <div className="custom-table-container">
          <Table columns={columns} data={formattedReports} />
        </div>
      </div>
    </div>
  );
};

export default Diagnosis;
