import React, { useState } from "react";

const AddPathologyTests = () => {
  const [patient, setPatient] = useState("");
  const [testName, setTestName] = useState("");
  const [shortName, setShortName] = useState("");
  const [testType, setTestType] = useState("");
  const [category, setCategory] = useState("");
  const [unit, setUnit] = useState("");
  const [subCategory, setSubCategory] = useState("");
  const [reportDays, setReportDays] = useState("");
  const [chargeCategory, setChargeCategory] = useState("");
  const [standardCharge, setStandardCharge] = useState("");

  const handleSubmit = (event) => {
    event.preventDefault();
    // Submit the form data to the backend
    console.log("Form data:", {
      patient,
      testName,
      shortName,
      testType,
      category,
      unit,
      subCategory,
      reportDays,
      chargeCategory,
      standardCharge,
    });
  };

  return (
    <div className="p-4 max-w-7xl mx-auto">
      <h1 className="font-bold mb-4 text-xl">New Pathology Test</h1>
      <form
        onSubmit={handleSubmit}
        className="bg-white p-9 border-black shadow-md rounded-3xl space-y-3"
      >
        {/* Patient, Test Name, Short Name, Test Type */}
        <div className="grid grid-cols-1 sm:grid-cols-4 gap-5">
          <div>
            <label className="flex text-gray-700 font-bold mb-2">Patient:</label>
            <select
              value={patient}
              onChange={(e) => setPatient(e.target.value)}
              className="w-full px-3 py-2 border border-black focus:outline-none focus:ring focus:border-blue-300"
            >
              <option value="" style={{ display: "none" }}>
                Select Patient
              </option>
              {/* Add patient options here */}
            </select>
          </div>
          <div>
            <label className="flex text-gray-700 font-bold mb-2">Test Name:</label>
            <input
              type="text"
              value={testName}
              onChange={(e) => setTestName(e.target.value)}
              placeholder="Test Name"
              className="w-full px-3 py-2 border border-black focus:outline-none focus:ring focus:border-blue-300"
            />
          </div>
          <div>
            <label className="flex text-gray-700 font-bold mb-2">Short Name:</label>
            <input
              type="text"
              value={shortName}
              onChange={(e) => setShortName(e.target.value)}
              placeholder="Short Name"
              className="w-full px-3 py-2 border border-black focus:outline-none focus:ring focus:border-blue-300"
            />
          </div>
          <div>
            <label className="flex text-gray-700 font-bold mb-2">Test Type:</label>
            <input
              type="text"
              value={testType}
              onChange={(e) => setTestType(e.target.value)}
              placeholder="Test Type"
              className="w-full px-3 py-2 border border-black focus:outline-none focus:ring focus:border-blue-300"
            />
          </div>
        </div>

        {/* Category, Unit, Sub Category, Test Type */}
        <div className="grid grid-cols-1 sm:grid-cols-4 gap-5">
          <div>
            <label className="flex text-gray-700 font-bold mb-2">Category Name:</label>
            <select
              value={category}
              onChange={(e) => setCategory(e.target.value)}
              className="w-full px-3 py-2 border border-black focus:outline-none focus:ring focus:border-blue-300"
            >
              <option value="" style={{ display: "none" }}>
                Select Category
              </option>
              {/* Add category options here */}
            </select>
          </div>
          <div>
            <label className="flex text-gray-700 font-bold mb-2">Unit:</label>
            <input
              type="text"
              value={unit}
              onChange={(e) => setUnit(e.target.value)}
              placeholder="Unit"
              className="w-full px-3 py-2 border border-black focus:outline-none focus:ring focus:border-blue-300"
            />
          </div>
          <div>
            <label className="flex text-gray-700 font-bold mb-2">Sub Category:</label>
            <input
              type="text"
              value={subCategory}
              onChange={(e) => setSubCategory(e.target.value)}
              placeholder="Sub Category"
              className="w-full px-3 py-2 border border-black focus:outline-none focus:ring focus:border-blue-300"
            />
          </div>
          <div>
            <label className="flex text-gray-700 font-bold mb-2">Test Type:</label>
            <input
              type="text"
              value={testType}
              onChange={(e) => setTestType(e.target.value)}
              placeholder="Test Type"
              className="w-full px-3 py-2 border border-black focus:outline-none focus:ring focus:border-blue-300"
            />
          </div>
        </div>

        {/* Report Days, Charge Category, Standard Charge */}
        <div className="grid grid-cols-1 sm:grid-cols-4 gap-5">
          <div>
            <label className="flex text-gray-700 font-bold mb-2">Report Days:</label>
            <input
              type="text"
              value={reportDays}
              onChange={(e) => setReportDays(e.target.value)}
              placeholder="Report Days"
              className="w-full px-3 py-2 border border-black focus:outline-none focus:ring focus:border-blue-300"
            />
          </div>
          <div>
            <label className="flex text-gray-700 font-bold mb-2">Charge Category:</label>
            <select
              value={category}
              onChange={(e) => setCategory(e.target.value)}
              className="w-full px-3 py-2 border border-black focus:outline-none focus:ring focus:border-blue-300"
            >
              <option value="" style={{ display: "none" }}>
                Select Category
              </option>
              {/* Add category options here */}
            </select>
          </div>
          <div>
            <label className="flex text-gray-700 font-bold mb-2">Standard Charge: (Tk)</label>
            <input
              type="text"
              value={standardCharge}
              onChange={(e) => setStandardCharge(e.target.value)}
              placeholder="Standard Charges"
              className="w-full px-3 py-2 border border-black bg-[#E6E6E6] focus:outline-none focus:ring focus:border-blue-300"
            />
          </div>
        </div>

        
        <div className="grid grid-cols-2 md:grid-cols-4 gap-4 items-center bg-[#E6E6E6] p-2 rounded-lg max-md:hidden border border-black">
          <div className="col-span-2 sm:col-span-1">
            <label className="text-gray-700 font-bold mb-2">Parameter Name</label>
          </div>
          <div className="col-span-2 sm:col-span-1">
            <label className="text-gray-700 font-bold mb-2">Patient Result</label>
          </div>
          <div className="col-span-2 sm:col-span-1">
            <label className="text-gray-700 font-bold mb-2">Reference Range</label>
          </div>
          <div className="col-span-2 sm:col-span-1">
            <label className="text-gray-700 font-bold mb-2">Unit</label>
          </div>
        </div>

        <div className="grid grid-cols-1 sm:grid-cols-4 gap-5 tablebar">
          <div data-cell="parameter name" className="col-span-1">
            <select
              value={category}
              onChange={(e) => setCategory(e.target.value)}
              className="w-full px-3 py-2 border border-black focus:outline-none focus:ring focus:border-blue-300"
            >
              <option value="" style={{ display: "none" }}>
                Select Parameter Name
              </option>
            </select>
          </div>
          <div data-cell="patient result" className="col-span-1">
            <input
              type="text"
              value={standardCharge}
              onChange={(e) => setStandardCharge(e.target.value)}
              placeholder="Patient result"
              className="w-full px-3 py-2 border border-black focus:outline-none focus:ring focus:border-blue-300"
            />
          </div>
          <div data-cell="reference range" className="col-span-1">
            <input
              type="text"
              value={standardCharge}
              onChange={(e) => setStandardCharge(e.target.value)}
              placeholder="Reference Range"
              className="w-full px-3 py-2 border bg-[#E6E6E6] border-black focus:outline-none focus:ring focus:border-blue-300"
            />
          </div>
          <div data-cell="unit" className="col-span-1">
            <div className="flex space-x-2">
              <input
                type="text"
                value={standardCharge}
                onChange={(e) => setStandardCharge(e.target.value)}
                placeholder="Unit"
                className="w-3/4 px-3 py-2 border bg-[#E6E6E6] border-black focus:outline-none focus:ring focus:border-blue-300"
              />
              <button
                type="button"
                className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
              >
                Add
              </button>
            </div>
          </div>
        </div>

        {/* Save, Cancel, Back Buttons */}
        <div className="flex flex-col justify-end space-x-0 gap-y-2 sm:space-x-4 mt-8 sm:flex-row">
          <button
            type="submit"
            className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded"
          >
            Save
          </button>
          <button
            type="button"
            className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded"
          >
            Cancel
          </button>
          <button
            type="button"
            className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded"
          >
            Back
          </button>
        </div>
      </form>
    </div>
  );
};

export default AddPathologyTests;
