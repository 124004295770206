import React from "react";
import { FieldArray, ErrorMessage, useFormikContext } from "formik";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { Button } from "@mui/material";

const allergyCategories = [
  { id: "food", name: "Food" },
  { id: "drug", name: "Drug" },
  { id: "environmental", name: "Environmental" },
  // Add more categories as needed
];

function AllergyIntolerance() {
  const { values, errors, touched, handleChange, handleBlur, setFieldValue } =
    useFormikContext();

  return (
    <div className="allergy-intolerance care_teams !p-3 !mb-3 bg-white rounded-md shadow-md">
      <FieldArray name="allergyIntolerance">
        {({ push, remove }) => (
          <>
            <div className="flex justify-between mb-3">
              <div className="inventory-svg flex flex-row items-center !gap-4">
                <svg
                  width="34"
                  height="34"
                  viewBox="0 0 34 34"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect width="34" height="34" rx="8" fill="#F4F6F8"></rect>
                  <path
                    d="M9.25 11H11.25V14H21.25V11H23.25V16H25.25V11C25.25 9.9 24.35 9 23.25 9H19.07C18.65 7.84 17.55 7 16.25 7C14.95 7 13.85 7.84 13.43 9H9.25C8.15 9 7.25 9.9 7.25 11V25C7.25 26.1 8.15 27 9.25 27H15.25V25H9.25V11ZM16.25 9C16.8 9 17.25 9.45 17.25 10C17.25 10.55 16.8 11 16.25 11C15.7 11 15.25 10.55 15.25 10C15.25 9.45 15.7 9 16.25 9Z"
                    fill="#F5793B"
                  ></path>
                  <path
                    d="M25.25 17.5L19.76 23L16.75 20L15.25 21.5L19.76 26L26.75 19L25.25 17.5Z"
                    fill="#F5793B"
                  ></path>
                </svg>
                <h1 className="font-semibold personal-head !text-[#344054] text-lg">Allergy Intolerance</h1>
              </div>
              {/* <button
                className="px-4 py-2 text-white rounded-md flex items-center"
                style={{
                  backgroundImage:
                    "linear-gradient(83deg, #f14f3e 0%, #fab768 100%)",
                }}
                onClick={() =>
                  push({
                    type: "",
                    category: "",
                    onsetDateTime: "",
                    recordedDate: "",
                  })
                }
              >
                <AddIcon /> Add Allergy Intolerance
              </button> */}
              <div>
                <Button
                  variant="contained"
                  size="small"
                  style={{
                    backgroundImage:
                      "linear-gradient(83deg, #f14f3e 0%, #fab768 100%)",
                  }}
                  onClick={() =>
                    push({
                      type: "",
                      category: "",
                      onsetDateTime: "",
                      recordedDate: "",
                    })
                  }
                  startIcon={<AddIcon />}
                >
                  Add Diagnosis
                </Button>
              </div>
            </div>
            <div className="allergy-intolerance-table">
              {values.allergyIntolerance.map((item, index) => (
                <div
                  key={index}
                  className="allergy-item p-3 last:mb-0 border border-gray-300 rounded-md"
                >
                  <div className="grid grid-cols-3 md:grid-cols-5 gap-2">
                    <div>
                      <label
                        className="block text-sm font-medium mb-1"
                        htmlFor={`allergyIntolerance.${index}.type`}
                      >
                        Type
                      </label>
                      <input
                        className="w-full rounded-md border border-gray-100 p-2"
                        type="text"
                        name={`allergyIntolerance.${index}.type`}
                        value={item.type}
                        onChange={handleChange}
                        placeholder="Type"
                      />
                      <ErrorMessage
                        name={`allergyIntolerance.${index}.type`}
                        component="div"
                        className="text-red-500 text-xs mt-1"
                      />
                    </div>
                    <div>
                      <label
                        className="block text-sm font-medium mb-1"
                        htmlFor={`allergyIntolerance.${index}.category`}
                      >
                        Category
                      </label>
                      <select
                        className="w-full rounded-md border border-gray-100 p-2"
                        name={`allergyIntolerance.${index}.category`}
                        value={item.category}
                        onChange={handleChange}
                      >
                        <option value="">Please Select</option>
                        {allergyCategories.map((category) => (
                          <option key={category.id} value={category.id}>
                            {category.name}
                          </option>
                        ))}
                      </select>
                      <ErrorMessage
                        name={`allergyIntolerance.${index}.category`}
                        component="div"
                        className="text-red-500 text-xs mt-1"
                      />
                    </div>
                    <div>
                      <label
                        className="block text-sm font-medium mb-1"
                        htmlFor={`allergyIntolerance.${index}.onsetDateTime`}
                      >
                        Onset DateTime
                      </label>
                      <input
                        className="w-full rounded-md border border-gray-100 p-2"
                        type="datetime-local"
                        name={`allergyIntolerance.${index}.onsetDateTime`}
                        value={item.onsetDateTime}
                        onChange={handleChange}
                      />
                      <ErrorMessage
                        name={`allergyIntolerance.${index}.onsetDateTime`}
                        component="div"
                        className="text-red-500 text-xs mt-1"
                      />
                    </div>
                    <div>
                      <label
                        className="block text-sm font-medium mb-1"
                        htmlFor={`allergyIntolerance.${index}.recordedDate`}
                      >
                        Recorded Date
                      </label>
                      <input
                        className="w-full rounded-md border border-gray-100 p-2"
                        type="date"
                        name={`allergyIntolerance.${index}.recordedDate`}
                        value={item.recordedDate}
                        onChange={handleChange}
                      />
                      <ErrorMessage
                        name={`allergyIntolerance.${index}.recordedDate`}
                        component="div"
                        className="text-red-500 text-xs mt-1"
                      />
                    </div>
                    <div className="flex items-end justify-end">
                      <button
                        className="bg-red-500 border border-red-500 text-white w-[40px] h-[40px] rounded-full flex justify-center items-center"
                        onClick={() => remove(index)}
                      >
                        <RemoveIcon />
                      </button>
                    </div>
                  </div>
                </div>
              ))}
            </div>
            {errors.allergyIntolerance && touched.allergyIntolerance && (
              <div className="max-w-2xl mt-2 text-sm leading-6">
                <p className="text-red-500">{errors.allergyIntolerance}</p>
              </div>
            )}
          </>
        )}
      </FieldArray>
    </div>
  );
}

export default AllergyIntolerance;
