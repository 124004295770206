import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const data = {
  labels: [
    "Dermatology",
    "Cardiology",
    "Oncology",
    "Surgery",
    "Pediatrics",
    "Radiology",
    "Neurology",
    "Orthopedics",
  ],
  datasets: [
    {
      label: "Patient Visits",
      data: [10, 20, 30, 15, 25, 18, 22, 35],
      backgroundColor: [
        "rgba(255, 99, 132, 0.4)", // Custom color for Dermatology
        "rgba(54, 162, 235, 0.4)", // Custom color for Cardiology
        "rgba(75, 192, 192, 0.4)", // Custom color for Oncology
        "rgba(153, 102, 255, 0.4)", // Custom color for Surgery
        "rgba(255, 206, 86, 0.4)", // Custom color for Pediatrics
        "rgba(75, 192, 192, 0.4)", // Custom color for Radiology
        "rgba(255, 159, 64, 0.4)", // Custom color for Neurology
        "rgba(201, 203, 207, 0.4)", // Custom color for Orthopedics
      ],
      borderColor: [
        "rgba(255, 99, 132, 1)", // Border color for Dermatology
        "rgba(54, 162, 235, 1)", // Border color for Cardiology
        "rgba(75, 192, 192, 1)", // Border color for Oncology
        "rgba(153, 102, 255, 1)", // Border color for Surgery
        "rgba(255, 206, 86, 1)", // Border color for Pediatrics
        "rgba(75, 192, 192, 1)", // Border color for Radiology
        "rgba(255, 159, 64, 1)", // Border color for Neurology
        "rgba(201, 203, 207, 1)", // Border color for Orthopedics
      ],
      borderWidth: 1,
    },
  ],
};

const options = {
  indexAxis: "y", // Makes the bar chart horizontal
  elements: {
    bar: {
      borderWidth: 2,
      barThickness: 10, // Further thins the bars
    },
  },
  responsive: true,
  plugins: {
    legend: {
      position: "top",
    },
    tooltip: {
      callbacks: {
        label: (context) => `${context.dataset.label}: ${context.raw}`,
      },
    },
  },
};

const HorizontalBarChart = () => {
  return <Bar data={data} options={options} />;
};

export default HorizontalBarChart;
