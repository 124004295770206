import React, { useState } from "react";
import ReactModal from "react-modal";
import { FaTimes } from "react-icons/fa";
import { Formik } from "formik";
import * as Yup from "yup";
// import "../Style/";
import { Button } from "@mui/base";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import { useMutation } from "@apollo/client";
import preAuth from "../../../gql/preAuth";
import { CreateNewEmr } from "./ApiMethods";
import AppLoader from "../../../components/AppLoader";
import { useNavigate } from "react-router-dom";
import { Details } from "@mui/icons-material";

const initialValues = {
  dateOrdered: "",
  claimType: "",
  subType: "",
  offlineEligibilityId: "",
  eligibilityResponseIdentifierValue: "",
  eligibilityResponseIdentifierUrl: "",
  offlineEligibilityDate: "",
  relatedPreAuthRefNo: "",
  referringProvider: "",
  priority: "",
};

const validationSchema = Yup.object().shape({
  dateOrdered: Yup.date().required("Date ordered is required"),
  claimType: Yup.string().required("Claim type is required"),
  subType: Yup.string().required("Sub type is required"),
  offlineEligibilityId: Yup.string(),
  eligibilityResponseIdentifierValue: Yup.string(),
  eligibilityResponseIdentifierUrl: Yup.string().nullable(),
  offlineEligibilityDate: Yup.date().nullable(),
  relatedPreAuthRefNo: Yup.string(),
  referringProvider: Yup.string().nullable(),
  priority: Yup.string().nullable(),
});

function convertClinicalData(inputData) {
  return inputData.map((item) => {
    const baseObject = {
      category: item.category,
      code: item.code || null,
      code_text: item.codeText || null,
      start_date: item.startDate || null,
      end_date: item.endDate || null,
      value: item.value || null,
      unit: item.valueUnit || null,
      reason: item.reason || null,
      image_content_type: item.imageContentType || null,
      image_title: item.imageTitle || null,
      image_creation_date: item.imageCreationDate || null,
      image_value: item.imageValue || null,
    };

    if (item.category.startsWith("vital-sign-")) {
      baseObject.code = null;
      baseObject.code_text = null;
    }

    if (item.image) {
      baseObject.image_content_type = item.image.contentType || null;
      baseObject.image_title = item.image.title || null;
      baseObject.image_creation_date = item.image.creationDate || null;
      baseObject.image_value = item.image.value || null;
    }

    return baseObject;
  });
}
function EmrPreAuthModel({ setOpen, open, values, rcmDetail, Detail }) {
  const [Loading, SetLoading] = useState(false);
  const navigate = useNavigate();
  const [providers, setProviders] = useState([]);
  const [subTypes, setSubTypes] = useState([
    { id: "op", name: "OutPatient" },
    { id: "ip", name: "InPatient" },
    { id: "emr", name: "Emergency" },
  ]);
  const types = [
    { id: "institutional", name: "Institutional" },
    { id: "oral", name: "Dental" },
    { id: "pharmacy", name: "Pharmacy" },
    { id: "professional", name: "Professional" },
    { id: "vision", name: "Optical" },
  ];
  const [priorities, setPriorities] = useState([
    { id: "stat", name: "Stat" },
    { id: "normal", name: "Normal" },
    { id: "deferred", name: "Deferred" },
  ]);

  const handleSubType = (val, setFieldValue) => {
    if (val == "institutional") {
      setFieldValue("subType", "ip");
      setSubTypes([
        { id: "ip", name: "InPatient" },
        { id: "emr", name: "Emergency" },
      ]);
    } else {
      setFieldValue("subType", "op");
      setSubTypes([{ id: "op", name: "OutPatient" }]);
    }
  };

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      width: "75%",
      padding: "0",
      borderRadius: "8px",
      overflowY: "auto",
      border: "none",
      backgroundColor: "white",
    },
    overlay: {
      backgroundColor: "rgba(0, 0, 0, 0.3)",
    },
  };
  const [requestPreAuth, { data, loading, error }] = useMutation(
    preAuth.REQUEST_AUTHORIZATION,
    {
      onCompleted(result) {
        if (!result?.preAuthRequest?.message === "error in nphies") {
          CreateNewEmr(SetLoading, values).then(() => {
            navigate("/his/patient");
          });
        }
        setOpen(false);
      },
      onError(err) {
        console.error("Pre-Authorization Request Error:", err);
      },
    }
  );

  const handleSubmit = (inputValues, actions) => {
    const updateddiagnosis = values?.diagnosisArr?.map((des) => ({
      type: des?.type,
      on_admission: des?.on_admission === "false" ? false : true,
      code: des?.codeId,
      description: des?.codeDescription,
    }));
    const UpdatedItems = values?.items.map((item) => ({
      type: item.type,
      standard_code: item.standardCode,
      standard_description: item.standardCodeDescription,
      non_standard_code: item.non_standard_code,
      non_standard_description: item.non_standard_description,
      medication_selection_reason: item.medicationSelectionReason,
      prescribed_medication: item.prescribedMedication,
      body_site: item.body_site || null,
      sub_site: item.sub_site || "",
      service_start_date: item.service_start_date,
      service_end_date: item.service_end_date || "",
      supporting_info: item.supporting_info || [],
      diagnosis: item.diagnosis,
      care_teams: item.care_teams,
      is_package:
        item.is_package === "false" ? false : Boolean(item.is_package),
      quantity: item.quantity,
      code: item.standardCode,
      unit_price: item.unit_price,
      tax_amount: item.tax_amount,
      factor: item.factor,
      net: item.net,
      discount_amount: item.discount_amount,
      patient_share: item.patient_share,
      patient_share_amount: item.patientShareAmount,
      payer_share: typeof item.payer_share === "string" ? 1 : item.payer_share,
    }));
    const mappedValues = {
      is_edit_request: false,
      ref_transaction_id: null,
      is_followup_req: false,
      prv_transaction_id: "",
      patient_id: rcmDetail?.id,
      insurance_plan_id: rcmDetail?.insurance_plans[0]?.id,
      is_new_born: false,
      is_referral: false,
      date_ordered: inputValues.dateOrdered,
      priority: inputValues.priority || "stat",
      type: inputValues.claimType || "professional",
      subtype: inputValues.subType || "op",
      offline_verification_id: inputValues.offlineEligibilityId || null,
      offline_verification_date: inputValues.offlineEligibilityDate || null,
      verification_resp_ident_value:
        inputValues.eligibilityResponseIdentifierValue || null,
      verification_resp_ident_url:
        inputValues.eligibilityResponseIdentifierUrl || null,
      physicians_id: [Detail?.data?.id],
      diagnosis: updateddiagnosis,
      supporting_info: convertClinicalData(values?.supportingInfos),
      is_accident: values?.is_accident,
      accident_info: {
        street_name: "",
        city: "",
        state: "",
        country: "",
        date: "",
      },
      items: UpdatedItems,
      vision_prescription: null,
      is_vision_prescription: false,
      encounter: null,
    };

    requestPreAuth({
      variables: {
        input: mappedValues,
      },
    });

    actions.setSubmitting(false);
  };

  return (
    <ReactModal
      isOpen={open}
      onRequestClose={() => setOpen(false)}
      style={customStyles}
      contentLabel="Pre Auth"
      ariaHideApp={false}
    >
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({
          handleChange,
          handleBlur,
          handleSubmit,
          handleReset,
          values,
          errors,
          touched,
          setFieldValue,
        }) => (
          <>
            <div className="flex justify-between items-center !bg-primary text-white px-2 py-1 rounded-t-md">
              <h2 className="text-lg font-semibold">
                Pre-Authorization Details
              </h2>
              <button onClick={() => setOpen(false)} className="text-xl">
                <FaTimes />
              </button>
            </div>

            <div className="p-6">
              {/*    <p className="text-gray-700">
                <div class="personal-fields grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-3">
                  <div className="flex flex-col gap-1">
                    <label htmlFor="dateOrdered">Date Ordered*</label>
                    <input
                      type="date"
                      name="dateOrdered"
                      value={values.dateOrdered}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      id="dateOrdered"
                      className="w-full rounded-md border"
                    />
                    {errors.dateOrdered && touched.dateOrdered ? (
                      <p className="max-w-2xl text-sm leading-6 text-red-500">
                        {errors.dateOrdered}
                      </p>
                    ) : null}
                  </div>

                  <div className="flex flex-col gap-1">
                    <label htmlFor="claimType">Claim Type*</label>
                    <select
                      id="claimType"
                      name="claimType"
                      onChange={(e) => {
                        setFieldValue("claimType", e.target.value);
                        handleSubType(e.target.value, setFieldValue);
                      }}
                      value={values.claimType}
                      autoComplete="type-name"
                      placeholder="Select Type"
                      className="w-full rounded-md border"
                    >
                      <option value="">Select Type</option>
                      {types.map((item) => {
                        return (
                          <option key={item.id} value={item.id}>
                            <p>{item.name}</p>
                          </option>
                        );
                      })}
                    </select>
                    {errors.claimType && touched.claimType ? (
                      <p className="max-w-2xl text-sm leading-6 text-red-500">
                        {errors.claimType}
                      </p>
                    ) : null}
                  </div>

                  <div className="flex flex-col gap-1">
                    <label htmlFor="subType">Sub Type</label>
                    <select
                      name="subType"
                      onChange={handleChange}
                      value={values.subType}
                      id="sub-type"
                      className="w-full rounded-md border"
                    >
                      <option value="">Select Sub Type</option>
                      {subTypes.map((item) => {
                        return (
                          <option key={item.id} value={item.id}>
                            <p>{item.name}</p>
                          </option>
                        );
                      })}
                    </select>
                  </div>

                  <div className="flex flex-col gap-1">
                    <label htmlFor="offlineEligibilityId">
                      Offline Eligibility Id
                    </label>
                    <input
                      type="text"
                      name="offlineEligibilityId"
                      value={values.offlineEligibilityId}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      id="offlineEligibilityId"
                      placeholder="Offline Eligibility ID"
                      className="w-full rounded-md border"
                    />
                  </div>
                </div>

                <div className="personal-fields grid  grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-3 !mt-5">
                  <div className="col-span-1 sm:col-span-2 lg:col-span-1 flex flex-col gap-2">
                    <label htmlFor="offlineEligibilityId">
                      Eligibility Response Identifier (Value)
                    </label>
                    <input
                      type="text"
                      name="eligibilityResponseIdentifierValue"
                      value={values.eligibilityResponseIdentifierValue}
                      onChange={handleChange}
                      id=""
                      placeholder="Eligibility Response Identifier (Value)"
                      className="w-full rounded-md border"
                    />
                  </div>

                  <div className="flex flex-col gap-1">
                    <label htmlFor="eligibilityResponseIdentifierUrl">
                      Eligibility Response Identifier (URL)
                    </label>
                    <input
                      type="text"
                      name="eligibilityResponseIdentifierUrl"
                      value={values.eligibilityResponseIdentifierUrl}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      id="eligibilityResponseIdentifierUrl"
                      placeholder="Eligibility Response Identifier (URL)"
                      className="w-full rounded-md border"
                    />
                  </div>

                  <div className="flex flex-col gap-1">
                    <label htmlFor="offlineEligibilityDate">
                      Offline Eligibility Date
                    </label>
                    <input
                      type="date"
                      name="offlineEligibilityDate"
                      value={values.offlineEligibilityDate}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      id="offlineEligibilityDate"
                      placeholder="Offline Eligibility Date"
                      className="w-full rounded-md border"
                    />
                  </div>
                </div>

                <div className="personal-fields grid  grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-3 !mt-2.5">
                  <div className="col-span-1 sm:col-span-2 lg:col-span-1 flex flex-col gap-1">
                    <label htmlFor="relatedPreAuthRefNo">
                      Related Preauthorization Reference No(s).
                    </label>
                    <input
                      type="text"
                      name="relatedPreAuthRefNo"
                      value={values.relatedPreAuthRefNo}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      id="relatedPreAuthRefNo"
                      placeholder="Related Preauthorization Reference No(s)."
                      className="w-full rounded-md border"
                    />
                  </div>

                  <div className="flex flex-col gap-1">
                    <label htmlFor="referringProvider">Ref. Provider</label>
                    <select
                      id="referringProvider"
                      name="referringProvider"
                      onChange={handleChange}
                      value={values.referringProvider}
                      className="w-full rounded-md border"
                    >
                      <option value="">Please Select Ref. Provider</option>
                      {providers.map((item) => {
                        return (
                          <option value={item.id}>
                            <p>{item.name}</p>
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  <div className="flex flex-col gap-1">
                    <label htmlFor="priority">Priority</label>
                    <select
                      name="priority"
                      onChange={handleChange}
                      value={values.priority}
                      id="priority"
                      className="w-full rounded-md border"
                    >
                      <option value="">Please Select Priority</option>
                      {priorities.map((item) => {
                        return (
                          <option value={item.id}>
                            <p>{item.name}</p>
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div> */}
              {/* </p> */}
              <div className="text-white flex justify-center mt-4">
                <Button
                  variant="contained"
                  size="small"
                  type="submit"
                  onClick={handleSubmit}
                  style={{
                    backgroundImage:
                      "linear-gradient(83deg, #f14f3e 0%, #fab768 100%)",
                  }}
                  startIcon={<DoneAllIcon />}
                  className="p-2 rounded-md px-3"
                >
                  Confirm
                </Button>
              </div>
            </div>
            <AppLoader
              showLoader={loading || Loading}
              setShowLoader={SetLoading}
            />
          </>
        )}
      </Formik>
    </ReactModal>
  );
}

export default EmrPreAuthModel;
