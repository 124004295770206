import { AddCircleOutline } from "@mui/icons-material";
import React from "react";
import { Link } from "react-router-dom";

const CreateButton = ({ linkPath, linkName }) => {
  // Ensure linkPath and linkName are valid
  if (!linkPath || !linkName) {
    console.error("Invalid linkPath or linkName", { linkPath, linkName });
    return null; // Do not render if path or name is undefined
  }

  const handleClick = () => {
    console.log(`Navigating to: /${linkPath}`); // Log the navigation path
  };

  return (
    <Link
      to={`/${linkPath}`}
      onClick={handleClick} // Add the click handler here
      className="bg-gradient-to-r from-red-500 to-orange-500 text-white py-1 px-2 mx-2 rounded-xl transition-all duration-300 hover:opacity-80"
    >
      <AddCircleOutline />
      {linkName}
    </Link>
  );
};

export default CreateButton;
