import React from "react";
import "./radilology.css"; // Link to custom CSS file
import { calculateAge } from "../HIS/Patient";

const RadiologyReport = ({data}) => {

   const dataArray = Array.isArray(data) ? data : [data];

   const imagingItems = dataArray.reduce((acc, entry) => {
     if (entry && entry.items && Array.isArray(entry.items)) {
       return acc.concat(entry.items.filter(item => item.type === "imaging"));
     }
     return acc;
   }, []);
 
   const patientInfo = dataArray[0]?.patient || {};
   const careTeamsArrInfo = dataArray[0]?.careTeamsArr || {};

   const user = localStorage?.getItem("user");
   const loggedUser = JSON.parse(user);
  
  return (
    <div className="report-container">
      {/* Header */}
      <div className="header">
        <div className="header-left">
          <h2>{loggedUser?.business_name_en}</h2>
          <p>{loggedUser?.Email}</p>
        </div>

        <div className="header-center">
          <div className="report-title">Radiology Report</div>
        </div>

        <div className="header-right">
          <h2>{loggedUser?.business_name_ar}</h2>

          <div className="contact-info">
            <div className="contact-item">
              <img src={loggedUser?.business_logo} alt="logo" />
            </div>
          </div>
        </div>
      </div>

      {/* Patient Information Section */}
      <div className="patient-info flex justify-between">
        <div className="">
          <div className="info-row">
            <div className="label">
              <span>MRN:</span> <span className="info-value">{patientInfo.mrn || 'N/A'}</span>
            </div>
          </div>
          <div className="info-row">
            <div className="label">
              <span>Patient Name:</span>
              <span className="info-value">{patientInfo.full_name || 'N/A'}</span>
            </div>
          </div>
          <div className="info-row">
            <div className="label">
              <span>Gender:</span> <span className="info-value">{patientInfo.gender || 'N/A'}</span>
            </div>
          </div>
        </div>

        <div className="">
          <div className="info-row">
            <div className="label">
              <span>Patient Type:</span> <span className="info-value">{patientInfo.beneficiary_type || 'N/A'}</span>
            </div>
          </div>
          <div className="info-row">
            <div className="label">
              <span>Patient Age:</span> <span className="info-value">{calculateAge(patientInfo?.dob) || "0"} year</span>
            </div>
          </div>
          <div className="info-row">
            <div className="label">
              <span>Practitioner Name:</span>{" "}
              <span className="info-value">{careTeamsArrInfo[0]?.practitionerName}</span>
            </div>
          </div>
        </div>
      </div>

      {/* Table */}
      <table className="radiology-table">
        <thead>
          <tr>
            <th>Start Date</th>
            <th>End Date</th>
            <th>Type</th>
            <th>Standard Code</th>
            <th>Standard Code Description</th>
          </tr>
        </thead>
        <tbody>
        {imagingItems.map((item, index) => (
          <tr>
            <td>{item.service_start_date || 'N/A'}</td>
            <td>{item.service_end_date || 'N/A'}</td>
            <td>{item?.type || 'N/A'}</td>
            <td>{item.standardCode || "N/A"}t</td>
            <td>{item.standardCodeDescription || 'N/A'}</td>
          </tr>
        ))}
        </tbody>
      </table>
    </div>
  );
};

export default RadiologyReport;
