//local server
// export const HIS_BACKEND_URL = "http://localhost:8001/api/v1";
// export const ERP_BACKEND_URL = "http://localhost:8081/api/v1";

 
//LIve server
export const HIS_BACKEND_URL = "https://apihis.supergitsa.com/api/v1";
export const ERP_BACKEND_URL = "https://apierp.supergitsa.com/api/v1";
 
// export const HIS_BACKEND_URL = "https://api-his.super-git.com/api/v1";
//  export const ERP_BACKEND_URL = "https://api-erp.super-git.com/api/v1";
