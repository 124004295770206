import { useState } from "react";

function MedicineBrands2() {
  const [MedicineBrands, setMedicineBrands] = useState([
    {
      brand: "N/A",
      email: "N/A",
      phone: "N/A",
    },
    {
      brand: "N/A",
      email: "N/A",
      phone: "N/A",
    },
    {
      brand: "N/A",
      email: "N/A",
      phone: "N/A",
    },
    {
      brand: "N/A",
      email: "N/A",
      phone: "N/A",
    },
    {
      brand: "N/A",
      email: "N/A",
      phone: "N/A",
    },
    {
      brand: "N/A",
      email: "N/A",
      phone: "N/A",
    },
    {
      brand: "N/A",
      email: "N/A",
      phone: "N/A",
    },
    {
      brand: "N/A",
      email: "N/A",
      phone: "N/A",
    },
    {
      brand: "N/A",
      email: "N/A",
      phone: "N/A",
    },
    {
      brand: "N/A",
      email: "N/A",
      phone: "N/A",
    },
    {
      brand: "N/A",
      email: "N/A",
      phone: "N/A",
    },
    {
      brand: "N/A",
      email: "N/A",
      phone: "N/A",
    },
    {
      brand: "N/A",
      email: "N/A",
      phone: "N/A",
    },
    {
      brand: "N/A",
      email: "N/A",
      phone: "N/A",
    },
    {
      brand: "N/A",
      email: "N/A",
      phone: "N/A",
    },

    // Add more data as needed
  ]);

  return (
    <div className="App">
      <div className="container py-2 mx-auto md:py-8">
        <div className="custom-table-container">
          <table className="custom-table">
            {/* <caption className="text-2xl font-bold text-zinc-700 mt-1">MedicineBrands Table</caption> */}
            <thead id="table-head">
              <tr>
                <th>
                  <div className="text-left ml-12 ">
                    BRAND

                  </div>
                </th>
                <th>EMAIL</th>
                <th>PHONE</th>
                <th>ACTION</th>
              </tr>
            </thead>
            <tbody>
              {MedicineBrands.map((item, index) => {
                return (
                  <tr key={index} className="text-center" style={{ border: "2px solid black" }}>
                    <td data-cell="name">
                      <div className="name-cell ml-12">
                        <div>{item.brand}</div>
                      </div>
                    </td>
                    <td data-cell="phone">{item.email}</td>
                    <td data-cell="role">{item.phone}</td>
                    <td data-cell="action">
                      <div className="actions">
                        <button className="edit-btn">
                          {" "}
                          <img src={"/icons/edit.png"} alt="Delete" />
                        </button>
                        <button className="delete-btn">
                          {" "}
                          <img src={"/icons/delete.png"} className="ms-1" alt="Delete" />
                        </button>
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default MedicineBrands2;
