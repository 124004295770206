import React, { useState } from "react";
import AppLoader from "../../../components/AppLoader";
import SmallEmrCard from "./SmallEmrCard";
import EmrCard from "./EmrCard";

export default function PatientMedicalHistory({
  patientMedicalHistory,
  setloading,
  Loading,
  patientID,
}) {
  const [data, setData] = useState(null);
  console.log(data);


  return (
    
    <>
      {data ? (
        <EmrCard entry={data} setData={setData} />
      ) : (
        <>
          <div className="grid grid-cols-1 gap-3">
            {patientMedicalHistory &&
              patientMedicalHistory?.length > 0 &&
              patientMedicalHistory?.map((emr) => (
                <SmallEmrCard emr={emr} setData={setData} patientID={patientID} />
              ))}
          </div>
          {patientMedicalHistory?.data === null && (
            <div className="flex w-full h-[40vh] items-center justify-center">
              <h1 className="text-2xl font-bold text-center">
                No Record Found
              </h1>
            </div>
          )}
        </>
      )}
      <AppLoader setShowLoader={setloading} showLoader={Loading} />
    </>
  );
}
