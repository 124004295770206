import { useState } from "react";
import Table from "../../../components/shared/Table";
import { Tooltip, Button } from "@mui/material";
import { MdOutlineRemoveRedEye } from "react-icons/md";
import { TbEditCircle } from "react-icons/tb";

const Doctor = () => {
  const [Doctors, setDoctors] = useState([
    {
      doctor: "Vatsal Sakariya",
      doctorEmail: "vatsal@gmail.com",
      specialist: "Surgery",
      status: "Active",
    },
    {
      doctor: "Vatsal Sakariya",
      doctorEmail: "vatsal@gmail.com",
      specialist: "Bones",
      status: "Deactive",
    },
    {
      doctor: "Vatsal Sakariya",
      doctorEmail: "vatsal@gmail.com",
      specialist: "Cardiac",
      status: "Active",
    },
    {
      doctor: "Vatsal Sakariya",
      doctorEmail: "vatsal@gmail.com",
      specialist: "Homepathic",
      status: "Active",
    },
    {
      doctor: "Vatsal Sakariya",
      doctorEmail: "vatsal@gmail.com",
      specialist: "Psychologist",
      status: "Active",
    },
    {
      doctor: "Vatsal Sakariya",
      doctorEmail: "vatsal@gmail.com",
      specialist: "Gynecologist",
      status: "Active",
    },
    {
      doctor: "Vatsal Sakariya",
      doctorEmail: "vatsal@gmail.com",
      specialist: "Medical",
      status: "Active",
    },
    {
      doctor: "Vatsal Sakariya",
      doctorEmail: "vatsal@gmail.com",
      specialist: "Dermatologist",
      status: "Active",
    },
    {
      doctor: "Vatsal Sakariya",
      doctorEmail: "vatsal@gmail.com",
      specialist: "Neurologist",
      status: "Deactive",
    },
    {
      doctor: "Vatsal Sakariya",
      doctorEmail: "vatsal@gmail.com",
      specialist: "Eyes",
      status: "Deactive",
    },
    {
      doctor: "Vatsal Sakariya",
      doctorEmail: "vatsal@gmail.com",
      specialist: "Thoracic",
      status: "Active",
    },
    {
      doctor: "Vatsal Sakariya",
      doctorEmail: "vatsal@gmail.com",
      specialist: "Nose and Throat",
      status: "Active",
    },
    {
      doctor: "Vatsal Sakariya",
      doctorEmail: "vatsal@gmail.com",
      specialist: "Cardiac",
      status: "Active",
    },
    {
      doctor: "Vatsal Sakariya",
      doctorEmail: "vatsal@gmail.com",
      specialist: "Psychologist",
      status: "Active",
    },
    {
      doctor: "Vatsal Sakariya",
      doctorEmail: "vatsal@gmail.com",
      specialist: "Gynecology",
      status: "Active",
    },
  ]);

  const columns = [
    { title: "DOCTOR", dataKey: "doctor" },
    { title: "SPECIALIST", dataKey: "specialist" },
    { title: "STATUS", dataKey: "status" },
    { title: "ACTIONS", dataKey: "actions" }, // New actions column
  ];

  const handleView = (id) => {
    console.log("Viewing details for ID:", id);
    // Add your view logic here
  };

  const handleEdit = (doctor) => {
    console.log("Editing:", doctor);
    // Add your edit logic here
  };

  return (
    <div className="superGit_container !p-0">
      <div className="flex justify-between flex-col lg:flex-row gap-4 mb-3 px-3"></div>

      <div className="super-git-table !p-0 !m-0">
        <Table
          columns={columns}
          data={Doctors.map((doctor) => ({
            ...doctor,

            specialist: (
              <button className="table-button">{doctor.specialist}</button>
            ),
            actions: (
              <td className="flex gap-2 justify-center items-center mx-2 my-auto !mt-2 !p-1">
                <Tooltip title="View">
                  <div
                    onClick={() => handleView(doctor.id)}
                    className="text-xl cursor-pointer text-green-500"
                  >
                    <MdOutlineRemoveRedEye />
                  </div>
                </Tooltip>

                <Tooltip title="Edit">
                  <div
                    onClick={() => handleEdit(doctor)}
                    className="text-xl cursor-pointer text-orange-400"
                  >
                    <TbEditCircle />
                  </div>
                </Tooltip>
              </td>
            ),
          }))}
        />
      </div>
    </div>
  );
};

export default Doctor;
