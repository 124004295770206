import React from "react";
import "./radilology.css"; // Link to custom CSS file
import { calculateAge } from "../HIS/Patient";

const Laboratory1 = ({ data }) => {
  const dataArray = Array.isArray(data) ? data : [data];

  const imagingItems = dataArray.reduce((acc, entry) => {
    if (entry && entry.items && Array.isArray(entry.items)) {
      return acc.concat(
        entry.items.filter((item) => item.type === "laboratory")
      );
    }
    return acc;
  }, []);

  const patientInfo = dataArray[0]?.patient || {};
  const careTeamsArrInfo = dataArray[0]?.careTeamsArr || {};

  const user = localStorage?.getItem("user");
  const loggedUser = JSON.parse(user);

  return (
    <div className="report-container">
      {/* Header */}
      <div className="header">
        <div className="header-left">
          <h2>{loggedUser?.business_name_en}</h2>
          <p>{loggedUser?.Email}</p>
        </div>

        <div className="header-center">
          <div className="report-title">Laboratory Report</div>
        </div>

        <div className="header-right">
          <h2>{loggedUser?.business_name_ar}</h2>

          <div className="contact-info">
            <div className="contact-item">
              <img src={loggedUser?.business_logo} alt="logo" />
            </div>
          </div>
        </div>
      </div>

      {/* Patient Information Section */}
      <div className="patient-info flex justify-between">
        <div className="">
          <div className="info-row">
            <div className="label">
              <span>MRN:</span>{" "}
              <span className="info-value">{patientInfo.mrn || "N/A"}</span>
            </div>
          </div>
          <div className="info-row">
            <div className="label">
              <span>Patient Name:</span>
              <span className="info-value">
                {patientInfo.full_name || "N/A"}
              </span>
            </div>
          </div>
          <div className="info-row">
            <div className="label">
              <span>Gender:</span>{" "}
              <span className="info-value">{patientInfo.gender || "N/A"}</span>
            </div>
          </div>
        </div>

        <div className="">
          <div className="info-row">
            <div className="label">
              <span>Patient Type:</span>{" "}
              <span className="info-value">
                {patientInfo.beneficiary_type || "N/A"}
              </span>
            </div>
          </div>
          <div className="info-row">
            <div className="label">
              <span>Patient Age:</span>{" "}
              <span className="info-value">
                {calculateAge(patientInfo?.dob) || "0"} year
              </span>
            </div>
          </div>
          <div className="info-row">
            <div className="label">
              <span>Practitioner Name:</span>{" "}
              <span className="info-value">
                {careTeamsArrInfo[0]?.practitionerName}
              </span>
            </div>
          </div>
        </div>
      </div>

      {/* Table */}
      <table className="radiology-table">
        <thead>
          <tr>
            <th>Start Date</th>
            <th>End Date</th>
            <th>Type</th>
            <th>Standard Code</th>
            <th>Standard Code Description</th>
          </tr>
        </thead>
        <tbody>
          {imagingItems.map((item, index) => (
            <tr>
              <td>{item.service_start_date || "N/A"}</td>
              <td>{item.service_end_date || "N/A"}</td>
              <td>{item?.type || "N/A"}</td>
              <td>{item.standardCode || "N/A"}t</td>
              <td>{item.standardCodeDescription || "N/A"}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Laboratory1;

// import React from "react";
// import "./radilology.css"; // Link to custom CSS file

// const Laboratory1 = () => {
//   return (
//     <div className="report-container">
//       {/* Header Section */}
//       <div className="header">
//         <div className="header-left">
//           <h2>Al-Mokhtar Medical Complex</h2>
//           <p>Al-Taif - Alawiya</p>
//           <a
//             href="http://www.elmokhtarclinic.com"
//             target="_blank"
//             rel="noreferrer"
//           >
//             www.elmokhtarclinic.com
//           </a>
//         </div>

//         <div className="header-center">
//           <img src="logo-url-here.png" alt="Logo" className="logo" />
//           <div className="report-title">Laboratory Report</div>
//         </div>

//         <div className="header-right">
//           <h2>المجمع الطبي المختار</h2>
//           <p>الطائف - العوية</p>

//           <div className="contact-info">
//             <div className="contact-item">
//               <img src="phone-icon.png" alt="Phone" />
//               <span>+456789</span>
//             </div>
//             <div className="contact-item">
//               <img src="twitter-icon.png" alt="Twitter" />
//               <a
//                 href="https://twitter.com/your-twitter"
//                 target="_blank"
//                 rel="noreferrer"
//               >
//                 @yourtwitter
//               </a>
//             </div>
//           </div>
//         </div>
//       </div>

//       {/* Patient Information Section */}
//       <div className="patient-info">
//         <div className="info-row">
//           <div className="label">
//             <span>MRN:</span> <span className="info-value">49569</span>
//           </div>
//           <div className="label">
//             <span>Account Name:</span> <span className="info-value">CASH</span>
//           </div>
//         </div>
//         <div className="info-row">
//           <div className="label">
//             <span>Patient Name:</span>{" "}
//             <span className="info-value">مرية عيسى الحسي</span>
//           </div>
//           <div className="label">
//             <span>Age:</span> <span className="info-value">50</span>
//           </div>
//         </div>
//         <div className="info-row">
//           <div className="label">
//             <span>Gender:</span> <span className="info-value">F</span>
//           </div>
//           <div className="label">
//             <span>Doctor Signature:</span>{" "}
//             <span className="info-value">Dr. Ibrahim Mostafa</span>
//           </div>
//         </div>
//       </div>

//       {/* Radiology Table */}
//       <table className="radiology-table">
//         <thead>
//           <tr>
//             <th>Laboratory Information</th>
//           </tr>
//         </thead>
//         <tbody>
//           <tr className="bg-indigo-400">
//             <td className="bg-indigo-100">
//               <span>Doctor: Dr. Ahmed Al Shorbagy </span>,{" "}
//               <span>Requested Date: 17/03/2022</span> ,
//               <span> Pharmacy: X-Ray Lumbo Sacral Spine </span>
//             </td>
//           </tr>
//           <tr>
//             <td>
//               Doctor: Dr. Sara Smith, Requested Date: 22/04/2022, Pharmacy: MRI
//               Brain
//             </td>
//           </tr>
//           <tr>
//             <td>
//               Doctor: Dr. John Doe, Requested Date: 15/05/2022, Pharmacy: CT
//               Scan Chest
//             </td>
//           </tr>
//           <tr>
//             <td>
//               Doctor: Dr. Fatima Al Farsi, Requested Date: 30/06/2022, Pharmacy:
//               Ultrasound Abdomen
//             </td>
//           </tr>
//         </tbody>
//       </table>

//       {/* Footer Section */}
//       {/* <div className="footer">
//         <p>Powered By DataOcean</p>
//         <p>12.09.2024 00:00 | 12.09.2024 18:42</p>
//         <p>Page 1 of 1</p>
//       </div> */}
//     </div>
//   );
// };

// export default Laboratory1;
