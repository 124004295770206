import React, { useState } from "react";
import Modal from "react-modal";
import { Formik } from "formik";
// import { ToastContainer, toast } from "react-toastify";
import toast from "react-hot-toast";
import _ from "lodash";
import { useMutation } from "@apollo/client";
import beneficiary from "../../../gql/beneficiary";
import ReactDatePicker from "react-datepicker";
import DatePicker from "react-datepicker";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

const documentIds = [
  { id: 1, name: "doc 1" },
  { id: 2, name: "doc 2" },
];
// const providerIds = [
//   { id: 1, name: "provider 1" },
//   { id: 2, name: "provider 2" },
// ];

const borns = [
  { id: 1, name: "true" },
  { id: 2, name: "false" },
];

function EditBeneficiaryModal({
  showEditBeneficiaryModal,
  setShowEditBeneficiaryModal,
  item,
}) {
  let subtitle;
  const [modalIsOpen, setIsOpen] = React.useState(false);

  const [createPatient, { data, loading, error }] = useMutation(
    beneficiary.CREATE_PATIENT,
    {
      update(proxy, result) {
        onSuccessfulRequestResponse(result.data);
      },
      onError(error) {
        onResponseError(error.graphQLErrors[0]);
      },
    }
  );

  function openModal() {
    setIsOpen(true);
  }

  function afterOpenModal() {
    // references are now sync'd and can be accessed.
    subtitle.style.color = "#f00";
  }

  function closeModal() {
    setIsOpen(false);
  }

  // const { loading, error, data } = useQuery(usersGQL.GET_USER);

  const onSuccessfulRequestResponse = (data) => {
    if (data.registerPatient) {
      toast.success("Successfully Registered! \n Please goto Login Page");
    } else {
    }
  };

  const onResponseError = (error) => {
    toast.error(`${error.message}`);
  };

  const documentIds = [
    { id: 1, name: "doc 1" },
    { id: 2, name: "doc 2" },
  ];
  // const providerIds = [
  //   { id: 1, name: "provider 1" },
  //   { id: 2, name: "provider 2" },
  // ];

  const addData = (values) => {
    setShowEditBeneficiaryModal(false);
    let data = _.cloneDeep(values);
    createPatient({ variables: { input: data } });
  };
  const bloodGroups = [
    { id: 1, name: "A+" },
    { id: 2, name: "B+" },
    { id: 3, name: "AB-" },
    { id: 3, name: "AB+" },
    { id: 3, name: "O-" },
  ];
  return (
    <Modal
      isOpen={showEditBeneficiaryModal}
      onAfterOpen={afterOpenModal}
      onRequestClose={() => setShowEditBeneficiaryModal(false)}
      style={customStyles}
      contentLabel="Example Modal"
    >
      <Formik
        initialValues={{
          ID: item.id,
          document_id: item.document_id,
          first_name: item.first_name,
          middle_name: item.middle_name,
          full_name: item.full_name,
          family_name: item.full_name,
          beneficiary_file_id: item.beneficiary_file_id,
          dob: item.dob,
          eHealth_id: item.eHealth_id,
          nationality: item.nationality,
          residency_type: item.residency_type,
          document_type: item.document_type,
          contact_number: item.contact_number,
          martial_status: item.martial_status,
          gender: item.gender,
          blood_group: item.blood_group,
          preferred_language: item.preferred_language,
          emergency_number: item.emergency_number,
          email: item.email,
          address_line: item.address_line,
          street_line: item.street_line,
          city: item.city,
          state: item.state,
          country: item.country,
          postal_code: item.postal_code,
          nphies_id: item.nphies_id,
         // providers_id: item.providerIds,
          is_new_born: item.is_new_born,
        }}
        validate={(values) => {
          const errors = {};
        }}
        onSubmit={(values, { setSubmitting }) => {
          addData(values);
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          setFieldValue,
          /* and other goodies */
        }) => (
          <div className="preauth-form">
            <form className="space-y-8 divide-y divide-gray-200">
              <div className="space-y-8 divide-y divide-gray-200">
                <div className="">
                  <div>
                    <h3 className="text-lg font-medium leading-6 text-gray-900">
                      Create Patient
                    </h3>
                  </div>
                  <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                    {/* Document Id */}
                    <div className="sm:col-span-3">
                      <label
                        htmlFor="name"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Document ID
                      </label>
                      <div className="mt-1">
                        <select
                          id="name"
                          name="document_id"
                          onChange={handleChange}
                          value={values.document_id}
                          autoComplete="type-name"
                          className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                        >
                          {documentIds.map((item) => {
                            return <option value={item.id}>{item.name}</option>;
                          })}
                        </select>
                      </div>
                    </div>

                    <div className="sm:col-span-3">
                      <label
                        htmlFor="name"
                        className="block text-sm font-medium text-gray-700"
                      >
                        First Name
                      </label>
                      <div className="mt-1">
                        <input
                          type="text"
                          name="first_name"
                          value={values.first_name}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          placeholder="Clinic 1"
                          id="NameEn"
                          autoComplete="given-name"
                          className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                        />
                      </div>
                    </div>

                    <div className="sm:col-span-3">
                      <label
                        htmlFor="name"
                        className="block text-sm font-medium text-gray-700"
                      >
                        last Name
                      </label>
                      <div className="mt-1">
                        <input
                          type="text"
                          name="middle_name"
                          value={values.middle_name}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          placeholder="Clinic 1"
                          id="NameEn"
                          autoComplete="given-name"
                          className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                        />
                      </div>
                    </div>

                    <div className="sm:col-span-3">
                      <label
                        htmlFor="name"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Full Name
                      </label>
                      <div className="mt-1">
                        <input
                          type="text"
                          name="full_name"
                          value={values.full_name}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          placeholder="Clinic 1"
                          id="NameEn"
                          autoComplete="given-name"
                          className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                        />
                      </div>
                    </div>
                    <div className="sm:col-span-3">
                      <label
                        htmlFor="name"
                        className="block text-sm font-medium text-gray-700"
                      >
                        BeneficiaryFile ID
                      </label>
                      <div className="mt-1">
                        <input
                          type="text"
                          name="beneficiary_file_id"
                          value={values.beneficiary_file_id}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          placeholder="Enter Address"
                          id="Address"
                          autoComplete="given-name"
                          className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                        />
                      </div>
                    </div>
                    <div className="sm:col-span-3">
                      <label
                        htmlFor="name"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Date Of Birth
                      </label>
                      <div className="mt-1">
                        <input
                          type="text"
                          name="dob"
                          value={values.dob}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          placeholder="Enter Address"
                          id="Address"
                          autoComplete="given-name"
                          className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                        />
                      </div>
                    </div>
                    <div className="sm:col-span-3">
                      <label
                        htmlFor="name"
                        className="block text-sm font-medium text-gray-700"
                      >
                        E-HealthId
                      </label>
                      <div className="mt-1">
                        <input
                          type="text"
                          name="eHealth_id"
                          value={values.eHealth_id}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          placeholder="Enter Address"
                          id="Address"
                          autoComplete="given-name"
                          className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                        />
                      </div>
                    </div>
                    <div className="sm:col-span-3">
                      <label
                        htmlFor="name"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Nationality
                      </label>
                      <div className="mt-1">
                        <input
                          type="text"
                          name="nationality"
                          value={values.nationality}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          placeholder="Enter Address"
                          id="Address"
                          autoComplete="given-name"
                          className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                        />
                      </div>
                    </div>
                    <div className="sm:col-span-3">
                      <label
                        htmlFor="name"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Residency Type
                      </label>
                      <div className="mt-1">
                        <input
                          type="text"
                          name="residency_type"
                          value={values.residency_type}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          placeholder="Enter Address"
                          id="Address"
                          autoComplete="given-name"
                          className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                        />
                      </div>
                    </div>

                    {/* Blood*/}
                    <div className="sm:col-span-3">
                      <label
                        htmlFor="name"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Blooad Group
                      </label>
                      <div className="mt-1">
                        <select
                          id="Type"
                          name="blood_group"
                          onChange={handleChange}
                          value={values.blood_group}
                          autoComplete="type-name"
                          className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                        >
                          {bloodGroups.map((item) => {
                            return <option value={item.id}>{item.name}</option>;
                          })}
                        </select>
                      </div>
                    </div>

                    {/*  */}

                    {/* DATA IS LOST FROM HERE */}
                    <div className="sm:col-span-3">
                      <label
                        htmlFor="name"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Document Type
                      </label>
                      <div className="mt-1">
                        <input
                          type="text"
                          name="document_type"
                          value={values.document_type}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          placeholder="Enter Address"
                          id="Address"
                          autoComplete="given-name"
                          className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                        />
                      </div>
                    </div>
                    <div className="sm:col-span-3">
                      <label
                        htmlFor="name"
                        className="block text-sm font-medium text-gray-700"
                      >
                        contact Number
                      </label>
                      <div className="mt-1">
                        <input
                          type="text"
                          name="contact_number"
                          value={values.contact_number}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          placeholder="Enter Address"
                          id="Address"
                          autoComplete="given-name"
                          className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                        />
                      </div>
                    </div>
                    <div className="sm:col-span-3">
                      <label
                        htmlFor="name"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Emergency Number
                      </label>
                      <div className="mt-1">
                        <input
                          type="text"
                          name="residency_type"
                          value={values.emergency_number}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          placeholder="Enter Address"
                          id="Address"
                          autoComplete="given-name"
                          className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                        />
                      </div>
                    </div>
                    {/* comes */}
                    <div className="sm:col-span-3">
                      <label
                        htmlFor="name"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Email
                      </label>
                      <div className="mt-1">
                        <input
                          type="email"
                          name="email"
                          value={values.email}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          placeholder="Enter Address"
                          id="Address"
                          autoComplete="given-name"
                          className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                        />
                      </div>
                    </div>

                    <div className="sm:col-span-3">
                      <label
                        htmlFor="name"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Address Line
                      </label>
                      <div className="mt-1">
                        <input
                          type="text"
                          name="address_line"
                          value={values.address_line}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          placeholder="Enter Address"
                          id="Address"
                          autoComplete="given-name"
                          className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                        />
                      </div>
                    </div>

                    <div className="sm:col-span-3">
                      <label
                        htmlFor="name"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Street Line
                      </label>
                      <div className="mt-1">
                        <input
                          type="text"
                          name="street_line"
                          value={values.street_line}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          placeholder="Enter Address"
                          id="Address"
                          autoComplete="given-name"
                          className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                        />
                      </div>
                    </div>

                    <div className="sm:col-span-3">
                      <label
                        htmlFor="name"
                        className="block text-sm font-medium text-gray-700"
                      >
                        City
                      </label>
                      <div className="mt-1">
                        <input
                          type="text"
                          name="city"
                          value={values.city}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          placeholder="Enter Address"
                          id="Address"
                          autoComplete="given-name"
                          className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                        />
                      </div>
                    </div>

                    <div className="sm:col-span-3">
                      <label
                        htmlFor="name"
                        className="block text-sm font-medium text-gray-700"
                      >
                        State
                      </label>
                      <div className="mt-1">
                        <input
                          type="text"
                          name="state"
                          value={values.state}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          placeholder="Enter Address"
                          id="Address"
                          autoComplete="given-name"
                          className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                        />
                      </div>
                    </div>

                    <div className="sm:col-span-3">
                      <label
                        htmlFor="name"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Preferred Language
                      </label>
                      <div className="mt-1">
                        <input
                          type="text"
                          name="preferred_language"
                          value={values.preferred_language}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          placeholder="Enter Address"
                          id="Address"
                          autoComplete="given-name"
                          className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                        />
                      </div>
                    </div>

                    <div className="sm:col-span-3">
                      <label
                        htmlFor="name"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Country
                      </label>
                      <div className="mt-1">
                        <input
                          type="text"
                          name="country"
                          value={values.country}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          placeholder="Enter Address"
                          id="Address"
                          autoComplete="given-name"
                          className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                        />
                      </div>
                    </div>

                    <div className="sm:col-span-3">
                      <label
                        htmlFor="name"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Postal Code
                      </label>
                      <div className="mt-1">
                        <input
                          type="text"
                          name="postal_code"
                          value={values.postal_code}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          placeholder="Enter Address"
                          id="Address"
                          autoComplete="given-name"
                          className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                        />
                      </div>
                    </div>

                    {/* Document Id */}
                    {/* <div className="sm:col-span-3">
                      <label
                        htmlFor="name"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Provider ID
                      </label>
                      <div className="mt-1">
                        <select
                          id="name"
                          name="providerId"
                          onChange={handleChange}
                          value={values.providers_id}
                          autoComplete="type-name"
                          className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                        >
                          {providerIds.map((item) => {
                            return <option value={item.id}>{item.name}</option>;
                          })}
                        </select>
                      </div>
                    </div> */}

                    <div className="sm:col-span-3">
                      <label
                        htmlFor="name"
                        className="block text-sm font-medium text-gray-700"
                      >
                        NewBorn
                      </label>
                      <div className="mt-1">
                        <select
                          id="name"
                          name="is_new_born"
                          onChange={handleChange}
                          value={values.is_new_born}
                          autoComplete="type-name"
                          className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                        >
                          {borns.map((item) => {
                            return <option value={item.id}>{item.name}</option>;
                          })}
                        </select>
                      </div>
                    </div>

                    <div className="sm:col-span-3">
                      <label
                        htmlFor="name"
                        className="block text-sm font-medium text-gray-700"
                      >
                        NPhies ID
                      </label>
                      <div className="mt-1">
                        <input
                          type="text"
                          name="nphies_id"
                          value={values.nphies_id}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          placeholder="Enter Address"
                          id="Address"
                          autoComplete="given-name"
                          className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                        />
                      </div>
                    </div>

                    <div className="sm:col-span-3">
                      <label
                        htmlFor="name"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Martial Status
                      </label>
                      <div className="mt-1">
                        <div className="flex gap-4">
                          <div className="flex gap-4 items-center">
                            <label htmlFor="">Single </label>
                            <input
                              type="radio"
                              name="martial_status"
                              className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-500"
                            />
                          </div>
                          <div className="flex gap-4  items-center ">
                            <label htmlFor="">Married </label>
                            <input
                              type="radio"
                              name="martial_status"
                              value={values.martial_status}
                              className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-500"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* other radio buttons */}
                    <div className="sm:col-span-3">
                      <label
                        htmlFor="name"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Gender
                      </label>
                      <div className="mt-1">
                        <div className="flex gap-4">
                          <div className="flex gap-4 items-center">
                            <label htmlFor="">Male </label>
                            <input
                              type="radio"
                              name="gender"
                              className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-500"
                            />
                          </div>
                          <div className="flex gap-4  items-center ">
                            <label htmlFor="">Female </label>
                            <input
                              type="radio"
                              name="gender"
                              className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-500"
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* DATA ENDING POINT */}
                  </div>
                </div>
              </div>

              <div className="pt-5 pb-2">
                <div className="flex justify-end">
                  <button
                    type="button"
                    className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                    onClick={() => setShowEditBeneficiaryModal(false)}
                  >
                    Cancel
                  </button>
                  <button
                    type="button"
                    onClick={handleSubmit}
                    className="ml-3 inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                  >
                    Update
                  </button>
                </div>
              </div>
            </form>
          </div>
        )}
      </Formik>
    </Modal>
  );
}

export default EditBeneficiaryModal;
