import { useState } from "react";
import Table from "../../../components/shared/Table";

function DiagnosisTests2() {
  const [tests, setTests] = useState([
    {
      reportNo: "6YPARFIS",
      patient: "Roberto Frias Morales",
      patientEmail: "nooricare@yopmail.com",
      doctor: "Vatsal Sakariya",
      doctorEmail: "vatsal@gmail.com",
      diagnosisCategory: "N/A",
      createdOn: "21st Apr, 2024",
    },
    {
      reportNo: "6YPARFIS",
      patient: "Denise Perez",
      patientEmail: "nooricare@yopmail.com",
      doctor: "Vatsal Sakariya",
      doctorEmail: "vatsal@gmail.com",
      diagnosisCategory: "N/A",
      createdOn: "21st Apr, 2024",
    },
    {
      reportNo: "6YPARFIS",
      patient: "Denise Perez",
      patientEmail: "nooricare@yopmail.com",
      doctor: "Vatsal Sakariya",
      doctorEmail: "vatsal@gmail.com",
      diagnosisCategory: "N/A",
      createdOn: "21st Apr, 2024",
    },
    {
      reportNo: "6YPARFIS",
      patient: "Roberto Frias Morales",
      patientEmail: "nooricare@yopmail.com",
      doctor: "Vatsal Sakariya",
      doctorEmail: "vatsal@gmail.com",
      diagnosisCategory: "N/A",
      createdOn: "21st Apr, 2024",
    },
    {
      reportNo: "6YPARFIS",
      patient: "Denise Perez",
      patientEmail: "nooricare@yopmail.com",
      doctor: "Vatsal Sakariya",
      doctorEmail: "vatsal@gmail.com",
      diagnosisCategory: "N/A",
      createdOn: "21st Apr, 2024",
    },
    {
      reportNo: "6YPARFIS",
      patient: "Roberto Frias Morales",
      patientEmail: "nooricare@yopmail.com",
      doctor: "Vatsal Sakariya",
      doctorEmail: "vatsal@gmail.com",
      diagnosisCategory: "N/A",
      createdOn: "21st Apr, 2024",
    },
    {
      reportNo: "6YPARFIS",
      patient: "Denise Perez",
      patientEmail: "nooricare@yopmail.com",
      doctor: "Vatsal Sakariya",
      doctorEmail: "vatsal@gmail.com",
      diagnosisCategory: "N/A",
      createdOn: "21st Apr, 2024",
    },
    {
      reportNo: "6YPARFIS",
      patient: "Roberto Frias Morales",
      patientEmail: "nooricare@yopmail.com",
      doctor: "Vatsal Sakariya",
      doctorEmail: "vatsal@gmail.com",
      diagnosisCategory: "N/A",
      createdOn: "21st Apr, 2024",
    },
    {
      reportNo: "6YPARFIS",
      patient: "Denise Perez",
      patientEmail: "nooricare@yopmail.com",
      doctor: "Vatsal Sakariya",
      doctorEmail: "vatsal@gmail.com",
      diagnosisCategory: "N/A",
      createdOn: "21st Apr, 2024",
    },
    {
      reportNo: "6YPARFIS",
      patient: "Roberto Frias Morales",
      patientEmail: "nooricare@yopmail.com",
      doctor: "Vatsal Sakariya",
      doctorEmail: "vatsal@gmail.com",
      diagnosisCategory: "N/A",
      createdOn: "21st Apr, 2024",
    },
  ]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedTest, setSelectedTest] = useState(null); // To store the selected test for editing

  const openModal = (test) => {
    setSelectedTest(test);
    setIsModalOpen(true);
  };
  const closeModal = () => {
    setSelectedTest(null);
    setIsModalOpen(false);
  };

  const handleDelete = (index) => {
    setTests(tests.filter((_, i) => i !== index));
  };

  const columns = [
    {
      title: "REPORT NO",
      dataKey: "reportNo",
      Cell: ({ row }) => (
        <button className="table-button">{row.reportNo}</button>
      ),
    },
    {
      title: "PATIENT",
      dataKey: "patient",
      Cell: ({ row }) => (
        <div>
          <div className="text-base">{row.patient}</div>
          <div className="email-text text-sm">{row.patientEmail}</div>
        </div>
      ),
    },
    {
      title: "DOCTOR",
      dataKey: "doctor",
      Cell: ({ row }) => (
        <div>
          <div className="text-base">{row.doctor}</div>
          <div className="email-text text-sm">{row.doctorEmail}</div>
        </div>
      ),
    },
    {
      title: "DIAGNOSIS CATEGORY",
      dataKey: "diagnosisCategory",
      Cell: ({ row }) => (
        <div className="text-base">{row.diagnosisCategory}</div>
      ),
    },
    {
      title: "CREATED ON",
      dataKey: "createdOn",
      Cell: ({ row }) => <div className="text-base">{row.createdOn}</div>,
    },
    {
      title: "ACTION",
      dataKey: "action",
      Cell: ({ row, index }) => (
        <div className="actions">
          <button onClick={() => openModal(row)} className="edit-btn">
            <img src={"/icons/edit.png"} alt="Edit" />
          </button>
          <button className="delete-btn" onClick={() => handleDelete(index)}>
            <img src={"/icons/delete.png"} alt="Delete" />
          </button>
          <button className="print-btn">
            <img src={"/icons/print.png"} alt="Print" />
          </button>
        </div>
      ),
    },
  ];

  const formattedTests = tests.map((test, index) => ({
    ...test,
    index, // Keep track of the index for delete functionality
  }));

  return (
    <div className="App">
      <div className="container py-2 mx-auto md:py-8">
        <div className="custom-table-container">
          <Table columns={columns} data={formattedTests} />
        </div>
      </div>
      {/* Modal Component */}
      {/* <DiagnosisTestModal
        isOpen={isModalOpen}
        onClose={closeModal}
        test={selectedTest} // Pass the selected test to the modal for editing
      /> */}
    </div>
  );
}

export default DiagnosisTests2;
