import React, { useContext } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import ContextProvider, { appContext } from "../context/ContextProvider";
// import BeneficiaryForm from "../pages/Beneficiary/components/AddBeneficiaryPersonalDataForm";

import Dashboard from "../pages/Dashboard/Dashboard";
import PreAuth from "../pages/PreAuth";
import Claims from "../pages/Claims";
import Physician from "../pages/Physicians";
import PriceList from "../pages/PriceList";
import PreAuthList from "../pages/PreAuth/PreAuthList";
import PreAuthForm from "../pages/PreAuth/components/PreAuthForm";
import Payments from "../pages/Payments";
import PaymentsList from "../pages/Payments/PaymentsList";
import Eligibility from "../pages/Eligibility/index";
import EligibilityList from "../pages/Eligibility/EligibilityList";
import Beneficiary from "../pages/Beneficiary/index";
import BeneficiaryForm from "../pages/Beneficiary/BeneficiaryForm";
import BeneficiaryList from "../pages/Beneficiary/BeneficiaryList";
import BeneficiaryDetails from "../pages/Beneficiary/BeneficiaryDetails";
import EligibilityDetails from "../pages/Eligibility/components/responseEligbility/EligibilityDetails";
import PreAuthDetails from "../pages/PreAuth/components/PreAuthDetails";
import ClaimsList from "../pages/Claims/ClaimsList";
import SubmitClaims from "../pages/Claims/SubmitClaims";
import ClaimsForm from "../pages/Claims/components/ClaimsForm";
import ClaimsDetails from "../pages/Claims/components/ClaimsDetails";
import EditBeneficiaryForm from "../pages/Beneficiary/EditBeneficiaryForm";
import PhysiciansList from "../pages/Physicians/PhysiciansList";
import PhysiciansForm from "../pages/Physicians/PhysiciansForm";
import PriceListMain from "../pages/PriceList/PriceListMain";
import PriceListSpecificPayer from "../pages/PriceList/PriceListSpecificPayer";
import ClaimHistoryList from "../pages/Claims/ClaimHistoryList";
import ClaimUploadList from "../pages/Claims/ClaimUploadList";
// import MainDash from "../pages/Dashboard/components/MainDash/MainDash";
import HIS from "../pages/HIS/index";
import Patient from "../pages/HIS/Patient";
import Practitioner from "../pages/HIS/Practitioner";
import NewAppointment from "../pages/HIS/components/NewAppointment";
import InvoiceMain from "../pages/HIS/components/InvoiceMain";
import ShowInvoice from "../pages/HIS/components/ShowInvoice";
import CashTable from "../pages/HIS/components/CashTable";
import InsuranceTable from "../pages/HIS/components/InsuranceTable";
import NewAppointmentMain from "../pages/HIS/components/NewAppointmentMain";
import InsurancePolicyMain from "../pages/HIS/components/InsurancePolicyMain";
import InsurancePolicyForm from "../pages/HIS/components/InsurancePolicyForm";
import EmrForm from "../pages/HIS/components/EmrForm";
import PatientAppoinmentHistory from "../pages/HIS/components/PatientAppoinmentHistory";

import HisPayers from "../pages/HIS/HisPayers";
import PayersForm from "../pages/HIS/components/PayersForm";

import LandingPage from "../components/landing/LandingPage";
import DDashboard from "../pages/Dashboard/DDashboard";
import AdminDash from "../pages/Dashboard/AdminDash";
import Labs from "../pages/labs";

import LabDashboard from "../pages/Dashboard/LabDashboard";
import BloodBank from "../pages/labs/Blood/BloodBanks";
import BloodDonors from "../pages/labs/Blood/BloodDonors";
import BloodDonations from "../pages/labs/Blood/BloodDonations";
import BloodIssues from "../pages/labs/Blood/BloodIssues";

import Blood from "../pages/labs/Blood";
import Doctor from "../pages/labs/doctors/Doctor";

import LiveMeetings from "../pages/labs/LiveMeetings/LiveMeetings";
import Doctors from "../pages/labs/doctors";
import Diagnosises from "../pages/labs/Diagnosis";
import DiagnosisCategory2 from "../pages/labs/Diagnosis/DiagnosisCategory2";
import DiagnosisTests2 from "../pages/labs/Diagnosis/DiagnosisTests2";
import Diagnosis from "../pages/labs/Diagnosis/Diagnosis";
import AddDiagnosisTests from "../pages/labs/Diagnosis/AddDiagnosisTests";
import Medicines from "../pages/labs/Medicine";
import Medicines2 from "../pages/labs/Medicine/Medicines2";
import MedicineCategories2 from "../pages/labs/Medicine/MedicineCategories2";
import MedicineBrand2 from "../pages/labs/Medicine/MedicineBrands2";
import PurchaseMedicine from "../pages/labs/Medicine/PurchaseMedicine";
import UsedMedicine2 from "../pages/labs/Medicine/UsedMedicine2";
import MedicineBills2 from "../pages/labs/Medicine/MedicineBills2";
import Payroll from "../pages/labs/Payrolls/Payroll";
import NoticeBoards3 from "../pages/labs/NoticeBoards/NoticeBoards3";
import PathologyTest from "../pages/labs/Pathology Tests";
import PathologyTests from "../pages/labs/Pathology Tests/PathologyTests";
import AddPathologyTests from "../pages/labs/Pathology Tests/AddPathologyTests";
import RadiologyTest from "../pages/labs/RadiologyTests";
import RadiologyTests from "../pages/labs/RadiologyTests/RadiologyTests";
import AddRadiologyTests from "../pages/labs/RadiologyTests/AddRadiologyTests";
import LabForm from "../pages/labs/labForms/LabForms";
import Radiology from "../pages/reports/Radiology";
import Pahrmacy from "../pages/reports/Pahrmacy";
import Treatement from "../pages/reports/Treatement";
import HomeAppointment from "../pages/HIS/components/HomeAppointment";
import ServiceItemMain from "../pages/serviceItem/ServiceItemMain";
import Appointment from "../pages/serviceItem/components/Appointment";

function Router(props) {
  const { token, user, setUser, setToken, setPermissions, permissions } =
    useContext(appContext);
  const getDashboardPath = () => {
    if (user?.RoleName === "reception") {
      return "/receptionist/rdashboard";
    } else if (user?.RoleName === "doctor") {
      return "/his/ddashboard";
    }
    return "/dashboard";
  };

  const dashboardPath = token ? getDashboardPath() : "/";
  return (
    <Routes>
      <Route path="/" element={<LandingPage />} />
      {token ? (
        <>
          <Route path="dashboard" element={<AdminDash />}></Route>
          <Route element={<Radiology />} path="radiology-report" />

          <Route path="beneficiary" element={<Beneficiary />}>
            <Route index element={<BeneficiaryList />} />
            <Route path="form" element={<BeneficiaryForm />} />
            {/* <Route path="edit/form" element={<EditBeneficiaryForm />} /> */}
            <Route path="edit-form" element={<EditBeneficiaryForm />} />

            <Route path="details" element={<BeneficiaryDetails />} />
          </Route>
          <Route path="eligibility" element={<Eligibility />}>
            <Route index element={<EligibilityList />} />
            <Route path="details" element={<EligibilityDetails />} />
          </Route>
          <Route path="preauth" element={<PreAuth />}>
            <Route index element={<PreAuthList />} />
            <Route path="form" element={<PreAuthForm />} />
            <Route path="details" element={<PreAuthDetails />} />
            <Route path="details/:id" element={<PreAuthDetails />} />
            <Route path="edit/form/:id" element={<PreAuthForm />} />
            <Route path="edit/form/:id/:followUp" element={<PreAuthForm />} />
          </Route>
          <Route path="claims" element={<Claims />}>
            <Route index element={<ClaimsList />} />
            <Route path="submit" element={<SubmitClaims />} />
            <Route path="form" element={<ClaimsForm />} />
            <Route path="edit/form/:id" element={<ClaimsForm />} />
            <Route path="edit/form/:id/:followUp" element={<ClaimsForm />} />
            <Route path="details" element={<ClaimsDetails />} />
            <Route path="details/:id" element={<ClaimsDetails />} />
            <Route path="history" element={<ClaimHistoryList />} />
            <Route path="uploads" element={<ClaimUploadList />} />
          </Route>
          <Route path="physicians" element={<Physician />}>
            <Route index element={<PhysiciansList />} />
            <Route path="form" element={<PhysiciansForm />} />
            <Route path="edit/form/:id" element={<PhysiciansForm />} />
          </Route>
          <Route path="price-list" element={<PriceList />}>
            <Route index element={<PriceListMain />} />
            <Route
              path="specific/:payerId/:effectiveDate/:payerName"
              element={<PriceListSpecificPayer />}
            />
          </Route>
          <Route path="payments" element={<Payments />}>
            <Route index element={<PaymentsList />} />
          </Route>

          {/* <Route path="his" element={<HIS />}>
            <Route path="ddashboard" element={<DDashboard />} />
            <Route element={<Patient />} index />
            <Route element={<EmrForm />} path="emr" />
            <Route element={<Patient />} path="patient" />
            <Route element={<NewAppointmentMain />} path="appointments" />
          </Route> */}
          <Route path="receptionist" element={<HIS />}>
            <Route path="appointment" element={<Appointment />} />
            <Route path="rdashboard" element={<Dashboard />} />
            <Route path="beneficiary" element={<BeneficiaryList />} />
            <Route path="beneficiary/form" element={<BeneficiaryForm />} />
            <Route
              path="beneficiary/edit/form"
              element={<EditBeneficiaryForm />}
            />
            <Route
              path="beneficiary/details"
              element={<BeneficiaryDetails />}
            />
            <Route path="eligibility" element={<Eligibility />}>
              <Route index element={<EligibilityList />} />
              <Route path="details" element={<EligibilityDetails />} />
            </Route>

            <Route element={<NewAppointment />} path="create-visit" />

            <Route element={<HomeAppointment />} path="create-home-visit" />
            <Route element={<NewAppointmentMain />} path="visits" />
            <Route element={<InvoiceMain />} path="invoice" />
            <Route element={<ShowInvoice />} path="show-invoice" />
            <Route element={<CashTable />} path="cash_table" />
            <Route element={<InsuranceTable />} path="insurance_table" />
            <Route element={<InsurancePolicyMain />} path="insurance_policy" />
            <Route
              element={<InsurancePolicyForm />}
              path="insurance_policy_form"
            />
            <Route element={<NewAppointment />} path="create-visit" />
            <Route element={<NewAppointmentMain />} path="appointments" />
            <Route element={<InvoiceMain />} path="invoice" />
            <Route element={<ShowInvoice />} path="show-invoice" />
            <Route element={<CashTable />} path="cash_table" />
            <Route element={<InsuranceTable />} path="insurance_table" />
            <Route element={<InsurancePolicyMain />} path="insurance_policy" />
            <Route
              element={<InsurancePolicyForm />}
              path="insurance_policy_form"
            />
          </Route>
          <Route path="his" element={<HIS />}>
            <Route path="ddashboard" element={<DDashboard />} />
            <Route element={<Patient />} index />
            <Route element={<EmrForm />} path="emr" />
            <Route element={<Patient />} path="patient" />
            <Route element={<NewAppointmentMain />} path="appointments" />
            <Route element={<EmrForm />} path="emr" />
            <Route element={<EmrForm />} path="emr-edit" />
            <Route element={<Patient />} path="patient" />
            <Route element={<HisPayers />} path="payers" />
            <Route element={<PayersForm />} path="payers_form" />
            <Route element={<Radiology />} path="radiology-report" />
          </Route>
          <Route path="labs" element={<Labs />}>
            <Route path="ldashboard" element={<LabDashboard />} />
            <Route path="blood" element={<Blood />}>
              <Route index element={<BloodBank />} />
              <Route path="blood-donar" element={<BloodDonors />} />
              <Route path="blood-donations" element={<BloodDonations />} />
              <Route path="blood-issues" element={<BloodIssues />} />
            </Route>
            <Route path="doctor" element={<Doctors />}>
              <Route index element={<Doctor />} />
            </Route>
            <Route path="diagnosis" element={<Diagnosises />}>
              <Route index element={<Diagnosis />} />
              <Route
                path="diagnosis-category"
                element={<DiagnosisCategory2 />}
              />
              <Route path="diagnosis-test" element={<DiagnosisTests2 />} />
              <Route path="add-diagnosis" element={<AddDiagnosisTests />} />
            </Route>
            <Route path="live-meetings" element={<LiveMeetings />} />
            <Route path="payroll" element={<Payroll />} />
            <Route path="medicines" element={<Medicines />}>
              <Route index element={<Medicines2 />} />
              <Route path="med-category" element={<MedicineCategories2 />} />
              <Route path="med-brands" element={<MedicineBrand2 />} />
              <Route path="purchase-Med" element={<PurchaseMedicine />} />
              <Route path="used-med" element={<UsedMedicine2 />} />
              <Route path="med-bills" element={<MedicineBills2 />} />
            </Route>

            <Route path="notice-board" element={<NoticeBoards3 />} />
            <Route path="pathology-test" element={<PathologyTest />}>
              <Route index element={<PathologyTests />} />
              <Route path="add-pathalogy" element={<AddPathologyTests />} />
            </Route>
            <Route path="radiology-test" element={<RadiologyTest />}>
              <Route index element={<RadiologyTests />} />
              <Route path="add-radiologytest" element={<AddRadiologyTests />} />
            </Route>
            <Route path="lab-form" element={<LabForm />} />
          </Route>
        </>
      ) : (
        <>
          <Route path="*" element={<Navigate to="/" />} />
        </>
      )}
      <Route path="*" element={<Navigate to={dashboardPath} />} />
    </Routes>
  );
}

export default Router;
