/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-key */
import { useState } from "react";
import "../../../Style/style.css";

function RadiologyTests() {
  const [radiologyTests, setRadiologyTests] = useState([
    { testName: "CBC", shortName: "CBC", testType: "CBC", categoryName: "Assorted Meat", chargeCategory: "Lab" },
    { testName: "BLOOD SUGAR", shortName: "BLOOD SUGAR", testType: "BLOOD SUGAR", categoryName: "Blood", chargeCategory: "Test" },
    { testName: "URINE SUGAR", shortName: "URINE SUGAR", testType: "URINE SUGAR", categoryName: "Urine", chargeCategory: "Lab" },
    { testName: "Glucose", shortName: "Glucose", testType: "Glucose", categoryName: "Blood", chargeCategory: "Test" },
    { testName: "CBC", shortName: "CBC", testType: "CBC", categoryName: "Blood", chargeCategory: "Lab" },
    { testName: "BLOOD SUGAR", shortName: "BLOOD SUGAR", testType: "BLOOD SUGAR", categoryName: "Blood", chargeCategory: "Test" },
    { testName: "URINE SUGAR", shortName: "URINE SUGAR", testType: "URINE SUGAR", categoryName: "Urine", chargeCategory: "Lab" },
    { testName: "Glucose", shortName: "Glucose", testType: "Glucose", categoryName: "Assorted Meat", chargeCategory: "Test" },
    { testName: "CBC", shortName: "CBC", testType: "CBC", categoryName: "Blood", chargeCategory: "Lab" },
    { testName: "BLOOD SUGAR", shortName: "BLOOD SUGAR", testType: "BLOOD SUGAR", categoryName: "Blood", chargeCategory: "Test" },
    { testName: "URINE SUGAR", shortName: "URINE SUGAR", testType: "URINE SUGAR", categoryName: "Urine", chargeCategory: "Lab" },
    { testName: "Glucose", shortName: "Glucose", testType: "Glucose", categoryName: "Assorted Meat", chargeCategory: "Test" },
    { testName: "CBC", shortName: "CBC", testType: "CBC", categoryName: "Blood", chargeCategory: "Lab" },
    { testName: "CBC", shortName: "CBC", testType: "CBC", categoryName: "Blood", chargeCategory: "Lab" },
    { testName: "CBC", shortName: "CBC", testType: "CBC", categoryName: "Blood", chargeCategory: "Lab" },
  ]);

  return (
    <div className="App">
      <div className="container py-2 mx-auto md:py-8">
        <div className="custom-table-container">
          <table className="custom-table">
            <thead id="table-head">
              <tr>
                <th className="text-left">TEST NAME</th>
                <th>SHORT NAME</th>
                <th>TEST TYPE</th>
                <th>CATEGORY NAME</th>
                <th>CHARGE CATEGORY</th>
                <th>ACTION</th>
              </tr>
            </thead>
            <tbody>
              {radiologyTests.map((item, index) => {
                return (
                  <tr key={index} className="text-center" style={{ border: "2px solid black" }}>
                    <td data-cell="testName">
                      <div className="name-cell">
                        <div>{item.testName}</div>
                      </div>
                    </td>
                    <td data-cell="shortName">{item.shortName}</td>
                    <td data-cell="testType">{item.testType}</td>
                    <td data-cell="categoryName">{item.categoryName}</td>
                    <td data-cell="chargeCategory">{item.chargeCategory}</td>
                    <td data-cell="action">
                      <div className="actions">
                        <button className="edit-btn">
                          <img src={"/icons/edit.png"} alt="Edit" />
                        </button>
                        <button className="delete-btn">
                          <img src={"/icons/delete.png"} className="ms-3" alt="Delete" />
                        </button>
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default RadiologyTests;
