import { useState } from "react";
import BloodGroupModal from "../../../components/shared/BloodGroupModal";
import Table from "../../../components/shared/Table"; // Import your Table component

function BloodBank() {
  const [BloodBank, setLBloodBank] = useState([
    { blood_group: "A+", bags: "10" },
    { blood_group: "A-", bags: "2" },
    { blood_group: "AB+", bags: "16" },
    { blood_group: "AB-", bags: "1" },
    { blood_group: "B+", bags: "15" },
    { blood_group: "B-", bags: "4" },
    { blood_group: "O+", bags: "0" },
    { blood_group: "O-", bags: "14" },
    { blood_group: "Red Cross", bags: "2" },
    { blood_group: "Rh Blood Group", bags: "1" },
  ]);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  const columns = [
    { title: "BLOOD GROUP", dataKey: "blood_group" },
    { title: "REMAINED BAGS", dataKey: "bags" },
    { title: "ACTION", dataKey: "actions" },
  ];

  const data = BloodBank.map((item, index) => ({
    ...item,
    actions: (
      <div className="actions">
        <button onClick={openModal} className="edit-btn">
          <img src="/icons/edit.png" alt="Edit" />
        </button>
        <button className="delete-btn">
          <img src="/icons/delete.png" className="ms-2" alt="Delete" />
        </button>
      </div>
    ),
  }));

  return (
    <div className="App">
      <div className="container mx-auto md:py-3">
        <div className="custom-table-container">
          <Table columns={columns} data={data} />
        </div>
      </div>
      {/* Modal Component */}
      <BloodGroupModal isOpen={isModalOpen} onClose={closeModal} />
    </div>
  );
}

export default BloodBank;
